import * as React from "react";
import { setActivePage } from "../../private-actions";

type Props = {
  id: string;
};

export class ServiceRouteListener extends React.Component<Props> {
  componentWillReceiveProps(newProps: Props) {
    setActivePage({ type: "service", service: { id: newProps.id } });
  }

  render() {
    return this.props.children;
  }
}
