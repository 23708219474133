import * as React from "react";
import { HeightDiv } from "../../../../palette";
import { NavigationSearch } from "./navigation-search";
import navigationStateQuery from "./navigation-state.query.graphql";
import clearNavigationLevelsMutation from "../../../../palette/clear-navigation-levels.mutation.graphql";
import styled from "styled-components";
import { MicroscopeIcon } from "../../../../palette/microscope-icon";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, ContainerType } from "../../../../app-navigation";
import { PluginProps } from "../../../../app-plugins";
import {
  withDrawerContext,
  DrawerContextProps,
  Button,
  compose,
  graphql,
  ChildProps,
  MutationFunc
} from "@atlassiansox/microscopekit";

import {
  AtlassianNavigation,
  generateTheme,
  Search,
  Create
} from "@atlaskit/atlassian-navigation";
import { ThemeContextConsumer } from "@atlassiansox/microscopekit/dist/src/theme";
import { SettingsDropdown } from "./settings-dropdown";
import { HelpDropdown } from "./help-dropdown";
import { themed } from "@atlaskit/theme";

const {
  ThemeProvider,
  dark,
  light,
  LayoutManager,
  NavigationProvider
} = require("@atlaskit/navigation-next");

const AkTooltip = require("@atlaskit/tooltip").default;
const { TooltipPrimitive } = require("@atlaskit/tooltip");
const {
  AkGlobalItem,
  AkSearchDrawer,
  AkNavigationItem,
  AkCreateDrawer,
  AkContainerNavigation,
  AkContainerTitle,
  presetThemes
} = require("@atlaskit/navigation");
const { colors } = require("@atlaskit/theme");
const ArrowleftIcon = require("@atlaskit/icon/glyph/arrow-left").default;
const AkButton = require("@atlaskit/button").default;

const TooltipStyles = styled(TooltipPrimitive)`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  color: #333;
  max-height: 300px;
  max-width: 300px;
  padding: 8px 12px;
`;

const HeaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.span`
  margin-left: 10px;
  font-size: 1rem;
`;

export interface NavigationProps {
  container?: Container;
  content?: any;
  links: any;
  drawers: any;
  username: string;
  theme?: "dark" | "light";
  clearNavigationLevels?: MutationFunc;
}

export enum EntityType {
  service = "service",
  category = "category"
}

type Props = ChildProps<NavigationProps & PluginProps> &
  DrawerContextProps &
  RouteComponentProps<any>;

export class NavigationView extends React.Component<Props> {
  navigateToRoot = (e: any) => {
    if (e.ctrlKey || e.metaKey) {
      window.open("/", "_blank");
    } else {
      this.props.clearNavigationLevels!();
      this.props.history.push("/");
    }
  };

  onSearchDrawerOpen = () => {
    track("Navigation.Search: DrawerOpen", { category: "info" });
    this.props.updateOpenDrawer("search");
    // Fixing atlaskit not setting focus in search.
    if (document.querySelector(".pnavigation input")) {
      (document.querySelector(".pnavigation input") as any).focus();
    }
  };

  onCreateDrawerOpen = () => {
    track("Navigation.Search: OpenCreate", { category: "info" });
    this.props.updateOpenDrawer("create");
  };

  renderSearchDrawer = () => (
    <AkSearchDrawer
      backIcon={<ArrowleftIcon label="Back icon" size="medium" />}
      isOpen={this.props.openDrawer === "search"}
      key="search"
      onBackButton={() => {
        track("Navigation.Search: CloseSearch", { category: "info" });
        this.props.updateOpenDrawer(null);
      }}
      primaryIcon={
        <HeightDiv height={"37px"}>
          <MicroscopeIcon label="Microscope Icon" />
        </HeightDiv>
      }
    >
      <NavigationSearch />
    </AkSearchDrawer>
  );

  renderCreateDrawer = () => (
    <AkCreateDrawer
      backIcon={<ArrowleftIcon label="Back icon" size="medium" />}
      isOpen={this.props.openDrawer === "create"}
      key="create"
      onBackButton={() => {
        track("Navigation.Search: CloseCreate", { category: "info" });
        this.props.updateOpenDrawer(null);
      }}
      primaryIcon={
        <HeightDiv height={"37px"}>
          <MicroscopeIcon label="Microscope Icon" />
        </HeightDiv>
      }
    >
      <>
        <AkNavigationItem
          isCompact={true}
          text="Create a service"
          onClick={() => this.props.updateOpenDrawer("create-service")}
        />
        {[...this.props.plugins].map(
          plugin =>
            plugin &&
            plugin.drawers &&
            plugin.drawers.map(
              drawer =>
                drawer.locations &&
                drawer.locations.createDrawer && (
                  <AkNavigationItem
                    isCompact={true}
                    text={drawer.locations.createDrawer.text}
                    onClick={() =>
                      this.props.updateOpenDrawer(plugin.id + "-" + drawer.id)
                    }
                  />
                )
            )
        )}
      </>
    </AkCreateDrawer>
  );

  render() {
    let { container, links } = this.props;

    return (
      <ThemeContextConsumer>
        {({ mode }) => {
          const containerNavigation = container ? (
            <StyledNavigationContainer>
              <AkContainerNavigation
                theme={mode === "dark" ? presetThemes.dark : presetThemes.light}
                headerComponent={() => (
                  <AkTooltip content={container!.id} position="right">
                    <AkContainerTitle
                      icon={
                        <AkButton key={container!.name} appearance="link" />
                      }
                      text={container!.displayName || container!.name}
                      subText={
                        container!.type === ContainerType.Service
                          ? "Service"
                          : "Category"
                      }
                    />
                  </AkTooltip>
                )}
              >
                {links}
              </AkContainerNavigation>
            </StyledNavigationContainer>
          ) : null;
          return (
            <div>
              <ThemeProvider
                theme={(theme: any) => ({
                  ...theme,
                  context: "container",
                  mode: mode === "dark" ? dark : light
                })}
              >
                <NavigationProvider>
                  <LayoutManager
                    experimental_flyoutOnHover
                    experimental_alternateFlyoutBehaviour
                    experimental_horizontalGlobalNav
                    globalNavigation={() => (
                      <AtlassianNavigation
                        label="Microscope"
                        primaryItems={[]}
                        renderCreate={() => (
                          <Create
                            buttonTooltip="Create"
                            iconButtonTooltip="Create"
                            text="Create"
                            onClick={this.onCreateDrawerOpen}
                          />
                        )}
                        renderSearch={() => (
                          <Search
                            onClick={this.onSearchDrawerOpen}
                            placeholder="Search..."
                            tooltip="Search"
                            label="Search"
                          />
                        )}
                        renderSettings={SettingsDropdown}
                        renderHelp={HelpDropdown}
                        theme={
                          mode === "dark"
                            ? generateTheme({
                                highlightColor: colors.DN500,
                                backgroundColor: colors.DN10
                              })
                            : undefined
                        }
                        renderProductHome={() => (
                          <Button
                            appearance="subtle"
                            onClick={this.navigateToRoot}
                          >
                            <HeaderDiv>
                              <MicroscopeIcon label="Microscope" />
                              <Header>Microscope</Header>
                            </HeaderDiv>
                          </Button>
                        )}
                      />
                    )}
                    productNavigation={() =>
                      mode === "dark" ? containerNavigation : null
                    }
                    containerNavigation={
                      mode !== "dark" ? () => containerNavigation : null
                    }
                    showContextualNavigation={!!container}
                  >
                    {this.props.content}
                  </LayoutManager>
                </NavigationProvider>
              </ThemeProvider>
              {[
                this.renderSearchDrawer(),
                this.renderCreateDrawer(),
                ...this.props.drawers
              ]}
            </div>
          );
        }}
      </ThemeContextConsumer>
    );
  }
}

const StyledNavigationContainer = styled.div`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${themed({ light: colors.N50, dark: colors.DN80 })};
    border-radius: 10px;
  }

  overflow-y: hidden;
  &:hover {
    overflow-y: scroll;
  }
`;

export const Navigation = compose(
  graphql(navigationStateQuery),
  graphql(clearNavigationLevelsMutation, { name: "clearNavigationLevels" }),
  withRouter,
  withDrawerContext
)(NavigationView);
