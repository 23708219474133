import * as React from "react";
import { LeftAlignDiv } from "./left-align-div";
import { ClearDiv } from "./clear";
import styled from "styled-components";
import { isNullOrUndefined } from "util";
const { FieldTextStateless: AkFieldText } = require("@atlaskit/field-text");
const AkFieldTextArea = require("@atlaskit/field-text-area").default;
const AkSingleSelect = require("@atlaskit/single-select").default;

const FieldLabelDiv = styled.div`
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
  padding: 20px 0 4px;
`;
const FormErrorsDiv = styled.div`
  font-size: 0.9em;
  color: #ffab00;
  margin-bottom: 1rem;
`;

// Form field
const FieldDiv = styled.div`
  margin-bottom: 0rem;
  min-width: 10rem;
  label > div {
    margin-top: 0rem;
  }
  textarea {
    min-height: 6rem;
  }
`;
export interface FieldProps {
  className?: string;
  label?: string;
  required?: boolean;
  errors?: string[];
  error?: boolean | string | null;
  isDisabled?: boolean;
  onChange?: (event: any) => void;
  autoFocus?: boolean;

  // Needed only in special cases when non-AtlasKit form inputs are used.
  displayLabel?: boolean;
}
export class Field extends React.Component<FieldProps> {
  render() {
    const { children, className, label, displayLabel } = this.props;
    return (
      <FieldDiv className={"pfield " + (className || "")}>
        {displayLabel ? <FieldLabelDiv>{label}</FieldLabelDiv> : null}
        {children}
      </FieldDiv>
    );
  }
}

// Text field
export interface TextFieldProps extends FieldProps {
  value?: string;
  placeholder?: string;
  type?: "text" | "password";
}
export class TextField extends React.Component<TextFieldProps> {
  render() {
    let {
      required,
      value,
      placeholder,
      isDisabled,
      onChange,
      errors,
      error,
      type,
      label,
      autoFocus
    } = this.props;

    errors = errors || [];
    if (error && error !== true) {
      errors = errors.concat([error]);
    }
    let hasErrors = errors.length > 0;

    let fieldProps: FieldProps = this.props;
    return (
      <Field {...fieldProps}>
        <AkFieldText
          autoFocus={autoFocus}
          label={label}
          isLabelHidden={!label}
          invalidMessage={errors.join(", ")}
          required={required}
          shouldFitContainer
          disabled={isDisabled || false}
          isInvalid={hasErrors}
          // Setting label as a name in order to locate elements in test via CSS selectors.
          name={label}
          value={value}
          onChange={onChange}
          type={type || "text"}
          placeholder={placeholder}
        />
      </Field>
    );
  }
}

// Text area field
export interface TextAreaFieldProps extends FieldProps {
  value?: string;
  placeholder?: string;
}
export const TextAreaField: React.StatelessComponent<
  TextAreaFieldProps
> = props => {
  let {
    required,
    value,
    placeholder,
    isDisabled,
    onChange,
    errors,
    error,
    label
  } = props;

  errors = errors || [];
  if (error && error !== true) {
    errors = errors.concat([error]);
  }
  let hasErrors = errors.length > 0;

  let fieldProps: FieldProps = props;
  return (
    <Field {...fieldProps}>
      <AkFieldTextArea
        label={label}
        isLabelHidden={!label}
        invalidMessage={errors.join(", ")}
        required={required}
        shouldFitContainer
        disabled={isDisabled || false}
        isInvalid={hasErrors}
        // Setting label as a name in order to locate elements in test via CSS selectors.
        name={label}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Field>
  );
};

// Select field
export interface SelectFieldProps extends FieldProps {
  className?: string;
  value?: string;
  values: string[];
  placeholder?: string;
}

// Hack to make it possible to use select in headless browser.
const paletteHack = {
  // tslint:disable-next-line:no-object-literal-type-assertion
  selects: {} as { [key: string]: (value: string) => void }
};
(window as any)["_paletteHack"] = paletteHack;

export const SelectField: React.StatelessComponent<
  SelectFieldProps
> = props => {
  let {
    required,
    value,
    values,
    placeholder,
    isDisabled,
    onChange,
    errors,
    error,
    label
  } = props;
  let items = [{ items: values.map(v => ({ content: v, value: v })) }];
  let valueObj = items[0].items.find(({ value: v }) => v === value);
  errors = errors || [];
  if (error && error !== true) {
    errors = errors.concat([error]);
  }
  let hasErrors = errors.length > 0;
  let onSelected = ({ item: { value } }: { item: { value: string } }) => {
    if (onChange) {
      onChange({
        description:
          "AkSingleSelect uses non-standard data, converting it to standard `onChange(event)`",
        target: { value }
      });
    }
  };

  // Hack to make it possible to use select from headless browser.
  if (props.className) {
    paletteHack.selects[props.className] = (value: string) => {
      onSelected({ item: { value } });
    };
  }

  let fieldProps: FieldProps = props;
  return (
    <Field {...fieldProps}>
      <LeftAlignDiv>
        <AkSingleSelect
          label={label}
          isLabelHidden={!label}
          invalidMessage={errors.join(", ")}
          isRequired={required}
          shouldFitContainer
          isDisabled={isDisabled || false}
          isInvalid={hasErrors}
          triggerType="button"
          items={items}
          defaultSelected={valueObj}
          placeholder={placeholder}
          onSelected={onSelected}
        />
      </LeftAlignDiv>
    </Field>
  );
};

export interface ValueLabelSelectFieldProps extends FieldProps {
  className?: string;
  value?: string;
  values: string[];
  displayValues: string[];
  placeholder?: string;
}

export const ValueLabelSelectField: React.StatelessComponent<
  ValueLabelSelectFieldProps
> = props => {
  let {
    required,
    value,
    values,
    placeholder,
    isDisabled,
    onChange,
    errors,
    error,
    label,
    displayValues
  } = props;
  let items: { items: { content: string; value: string }[] }[] = [];
  items.push({ items: [] });
  for (let elt in values) {
    items[0].items.push({ content: displayValues[elt], value: values[elt] });
  }
  let valueObj = items[0].items.find(({ value: v }) => v === value);
  errors = errors || [];
  if (error && error !== true) {
    errors = errors.concat([error]);
  }
  let hasErrors = errors.length > 0;

  let onSelected = ({ item: { value } }: { item: { value: string } }) => {
    if (onChange) {
      onChange({
        description:
          "AkSingleSelect uses non-standard data, converting it to standard `onChange(event)`",
        target: { value }
      });
    }
  };

  // Hack to make it possible to use select from headless browser.
  if (props.className) {
    paletteHack.selects[props.className] = (value: string) => {
      onSelected({ item: { value } });
    };
  }

  let fieldProps: FieldProps = props;
  return (
    <Field {...fieldProps}>
      <LeftAlignDiv>
        <AkSingleSelect
          label={label}
          isLabelHidden={!label}
          invalidMessage={errors.join(", ")}
          isRequired={required}
          shouldFitContainer
          isDisabled={isDisabled || false}
          isInvalid={hasErrors}
          triggerType="button"
          items={items}
          defaultSelected={valueObj}
          placeholder={placeholder}
          onSelected={onSelected}
        />
      </LeftAlignDiv>
    </Field>
  );
};

// Form
const InnerForm = styled.div`
  ${(props: { inline?: boolean; inlineRightAlign: boolean }) =>
    props.inline
      ? `
      ${props.inlineRightAlign ? `float: right` : ``};
      > * {
        display: inline-block !important;
        margin-right: 10px;
      }
      > *:last-child {
        margin-right: 0;
      }
      > button {
        margin-top: -2px;
      }
    `
      : ``};
`;
export interface FormProps {
  error?: string | null;
  errors?: string[];
  inline?: boolean;
  inlineRightAlign?: boolean;
  onSubmit?: (event: any) => void;
}
export class Form extends React.Component<FormProps> {
  onSubmit = (event: any) => {
    const { onSubmit } = this.props;
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  render() {
    let { inline, children, error, errors, inlineRightAlign } = this.props;
    errors = errors || [];
    if (error) {
      errors = errors.concat([error]);
    }

    return (
      <form onSubmit={this.onSubmit}>
        <div className={`pform ${inline ? "pform-inline" : ""}`}>
          <InnerForm
            inline={inline}
            className="pform-inner"
            inlineRightAlign={
              isNullOrUndefined(inlineRightAlign) ? true : inlineRightAlign
            }
          >
            {errors.length > 0 ? (
              <FormErrorsDiv>{errors.join(", ")}</FormErrorsDiv>
            ) : null}
            {children}
          </InnerForm>
          <ClearDiv />
        </div>
      </form>
    );
  }
}
