import * as React from "react";
import moment from "moment";
import { Permission } from "../typings";
import config from "./private/config";
import { buildApolloClient } from "./private/lib/apollo";
import gql from "graphql-tag";
import { helloMicrosExtensionIdMap } from "./constants";

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export function formatDate(timestamp: number | string) {
  return (
    <span title={moment(timestamp).format()}>
      {moment(timestamp).fromNow()}
    </span>
  );
}

export function pluralize(count: number, singular: string, plural: string) {
  return count === 1 ? singular : plural;
}

// URL validation https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url
const urlPattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i" // fragment locator
);
export function isUrlValid(url: string) {
  return urlPattern.test(url);
}

export interface CustomError {
  cause: string;
  code: string;
  message: string;
  path: string;
  stacktrace: [string];
  context: [{ key: string; value: string }];
}

/**
 * Defaults to using graphQLErrors and returning them as an array. If graphQLErrors is empty, returns the errMsg as an
 * array.
 */
export function returnError(
  graphQLErrors: { cause: string }[] | undefined,
  message: string
): string[] {
  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors.map(({ cause }: { cause: string }) => cause);
  } else {
    return [message || "unknown error"];
  }
}

export function returnErrString(
  graphQLErrors: { cause: string }[] | undefined,
  message: string
): string {
  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors
      .map(({ cause }: { cause: string }) => cause)
      .join(", ");
  } else {
    return message || "unknown error";
  }
}

export function getErrorMessage(e: any): string {
  if (e) {
    if (e.graphQLErrors && e.graphQLErrors.length > 0) {
      return e.graphQLErrors
        .map(
          ({
            cause,
            message,
            extensions
          }: {
            cause: string;
            message: string;
            extensions: any;
          }) => extensions?.response?.body?.error || cause || message
        )
        .join(", ");
    } else {
      return e.message || e.cause || "unknown error";
    }
  } else {
    return "unknown error";
  }
}

export function isNotFoundError(_error: any): boolean {
  // TODO negotiate a way to differentiate 404 not found error with the server.
  return false;
}

const ERROR_FORBIDDEN_SC = "403: Forbidden";
const ERROR_FORBIDDEN_MICROS = "status=403 error";

export function isForbiddenError(errorMessage: string): boolean {
  return (
    errorMessage.includes(ERROR_FORBIDDEN_SC) ||
    errorMessage.includes(ERROR_FORBIDDEN_MICROS)
  );
}

export type AnyComponent =
  | typeof React.Component
  | React.StatelessComponent<any>
  | React.ComponentClass;

export function isSufficientPermission(
  permission: Permission,
  minimum: Permission
) {
  if (permission === Permission.Admin) {
    return true;
  } else if (minimum === Permission.Write && permission === Permission.Write) {
    return true;
  } else if (minimum === Permission.Read) {
    return true;
  } else {
    return false;
  }
}

export const setupChromelessMode = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.get("chromeless") === "1") {
    window.sessionStorage.setItem("chromeless", "1");
  }
};

export const getIsChromeless = () =>
  window.sessionStorage.getItem("chromeless") === "1";

export const patchMicroscopeLinksForCompass = () => {
  if (!getIsChromeless()) {
    return;
  }

  const script = `
    function interceptClickEvent(e) {
      const target = e.target;
      const closestAnchor = event.target.closest("a");
      if (!closestAnchor) {
        return;
      }

      const href = closestAnchor.getAttribute('href');
      if(href.startsWith('/')) {
        return;
      }

      e.preventDefault();
      window.parent.postMessage(JSON.stringify({ href, microscope: true }), '*');
    }

    window.open = function(url) {
      window.parent.postMessage(JSON.stringify({ href: url, microscope: true }), '*');
    };

    document.addEventListener('click', interceptClickEvent);
  `;

  const scriptElement = document.createElement("script");
  scriptElement.type = "text/javascript";
  scriptElement.appendChild(document.createTextNode(script));
  document.body.appendChild(scriptElement);
};

export const enableCompassMigration = async () =>
  fetch(`${config.apiUrl}/api/v1/migrate-to-compass/enable`, {
    credentials: "include",
    mode: "cors"
  })
    .then(r => r.status === 200)
    .catch(() => false);

export type CompassMigrationStatusType =
  | "opted-in"
  | "opted-out"
  | "none"
  | "error";
export const checkCompassMigrationStatus = async (): Promise<CompassMigrationStatusType> =>
  fetch(`${config.apiUrl}/api/v1/migrate-to-compass`, {
    credentials: "include",
    mode: "cors"
  })
    .then(r => r.json())
    .then(r => r.status)
    .catch(() => "error");

export const patchStylesForCompassMigrationBanner = () => {
  const headerSelector = document.querySelector<HTMLDivElement>(
    `div[id="app"] div[data-layout-container="true"] > div:nth-child(1)`
  );

  if (headerSelector) {
    headerSelector.style.top = "56px";
  }

  const contentSelector = document.querySelector<HTMLDivElement>(
    `div[data-testid="Content"]`
  );

  if (contentSelector) {
    contentSelector.style.marginTop = "112px";
  }

  const leftNavSelector = document.querySelector<HTMLDivElement>(
    `div[data-testid="Navigation"]`
  );
  if (leftNavSelector) {
    leftNavSelector.style.top = "112px";
  }
};

export const getCompassEndpoint = async () => {
  const url = new URL(window.location.href);
  if (!url.pathname.startsWith("/services")) {
    return `https://hello.atlassian.net/compass/`;
  }
  const helloBaseUrl = `https://hello.atlassian.net/compass/component/external-alias/service-central`;

  const apolloClient = buildApolloClient(`${config.apiUrl}/api/v2/graphql`);

  const [, , serviceId, microscopePluginPath] = url.pathname.split("/");
  const res = await apolloClient.query({
    query: gql`
      query getService($id: ID!) {
        service(id: $id) {
          metadata {
            serviceUUID
          }
        }
      }
    `,
    variables: { id: serviceId }
  });

  const uuid = res?.data?.service?.metadata?.serviceUUID;

  if (
    !microscopePluginPath ||
    !helloMicrosExtensionIdMap.has(microscopePluginPath)
  ) {
    return `${helloBaseUrl}/${uuid}`;
  }

  const microsAppExtensionId = helloMicrosExtensionIdMap.get(
    microscopePluginPath
  );

  let extensionPath = "";
  if (url.pathname.split("/").length > 4) {
    const remainingURL = url.pathname
      .split("/")
      .slice(4)
      .join("/");

    extensionPath = encodeURIComponent(
      `${microscopePluginPath}/${remainingURL}`
    );
  } else {
    extensionPath = encodeURIComponent(`${microscopePluginPath}`);
  }

  if (url.searchParams.toString() !== "") {
    const searchParams = url.searchParams.toString();
    extensionPath = encodeURIComponent(`${extensionPath}&${searchParams}`);
  }

  return `${helloBaseUrl}/${uuid}?extensionId=${microsAppExtensionId}&extensionPath=${extensionPath}`;
};
