import * as React from "react";
import styled, { StyledFunction } from "styled-components";

const MarginLeftSpanFunc: StyledFunction<
  { margin?: string } & React.HTMLProps<HTMLInputElement>
> = styled.span as StyledFunction<
  { margin?: string } & React.HTMLProps<HTMLInputElement>
>;
export const MarginLeftSpan = MarginLeftSpanFunc`
  ${props => (props.margin ? `margin-left: ${props.margin};` : ``)};
`;
MarginLeftSpan.displayName = "MarginLeftSpan";
