import React from "react";
import Spinner from "@atlaskit/spinner";
import styled, { css } from "styled-components";
import { colors, gridSize, themed } from "@atlaskit/theme";

const inputRightPadding = gridSize() * 2;

export const SearchBox = styled.div`
  position: sticky;
  top: 0;
  z-index: 1; /* required to keep the search box on top of icons in results when sticky */
  color: ${themed({ light: colors.N500, dark: colors.DN800 })};
  display: flex;
  height: 36px;
  z-index: 10;
`;

export const SearchFieldBaseOuter = styled.div`
  display: flex;
  flex: 1;
  margin-right: auto;
  padding-bottom: 2px;
  border-bottom: 2px solid ${colors.B200};
`;

export const SearchFieldBaseInner = styled.div`
  position: relative;
  align-items: center;
  padding-right: ${inputRightPadding}px; /* pad search text from FieldBase's isLoading spinner */
  display: flex;
  flex-grow: 1;
`;

export const SearchInner = styled.div`
  padding-right: ${gridSize() * 3}px;
`;

export const getPlaceholderStyle = (style: any) => css`
  &::-webkit-input-placeholder {
    ${style};
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    ${style} opacity: 1;
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    ${style};
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    ${style} opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    ${style};
  }
`;

export const getPlaceholderColor = css`
  color: ${colors.placeholderText};
`;

export const SearchInput = styled.input`
  background-color: transparent;
  color: ${themed({ light: colors.N500, dark: colors.DN800 })};
  border: 0;
  flex-grow: 1;
  font-size: 1.4em;
  outline: 0;
  // Safari adds 2px margin-left
  margin-left: 0;
  ${getPlaceholderStyle(getPlaceholderColor)};
`;

export const SearchInputTypeAhead = styled(SearchInput)`
  color: ${colors.N50};
  position: absolute;
  width: calc(100% - ${inputRightPadding}px);
  z-index: -1;
`;

export const SearchInputControlsContainer = styled.span`
  padding-left: ${gridSize() * 3}px;
`;
SearchInputControlsContainer.displayName = "SearchInputControlsContainer"; // required for testing

export const controlKeys = [
  "ArrowUp",
  "ArrowDown",
  "Enter",
  "Tab",
  "ArrowRight"
];

const SpinnerParent = styled.div`
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
`;

type Props = {
  /** The elements to render as options to search from. */
  children?: React.ReactNode;
  /** The elements to render to the right of the search input. */
  inputControls?: React.ReactNode;
  /** Set whether the loading state should be shown. */
  isLoading?: boolean;
  /** Function to be called when the search input loses focus. */
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  /** Function to be called when a input action occurs (native `oninput` event). */
  onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
  /** Function to be called when the user hits the escape key.  */
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  /** Placeholder text for search field. */
  placeholder?: string;
  /** Current value of search field. */
  value?: string;
  /** Autocomplete information */
  autocompleteText?: string;
};

export class QuickSearch extends React.PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    isLoading: false,
    onBlur: () => {},
    placeholder: "Search"
  };

  onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { onKeyDown } = this.props;
    if (!controlKeys.includes(event.key)) {
      return;
    }
    if (onKeyDown) {
      onKeyDown(event);
    }
    event.stopPropagation();
  };

  setInputRef = (ref: React.Ref<any>) => {
    this.inputRef = ref;
  };

  renderInputControls = () =>
    this.props.inputControls ? (
      <SearchInputControlsContainer>
        {this.props.inputControls}
      </SearchInputControlsContainer>
    ) : null;

  inputRef?: React.Ref<any>;

  render() {
    const {
      children,
      onBlur,
      onInput,
      placeholder,
      isLoading,
      value,
      autocompleteText: autocomplete
    } = this.props;

    return (
      <SearchInner>
        <SearchBox>
          <SearchFieldBaseOuter>
            <SearchFieldBaseInner>
              {autocomplete && (
                <SearchInputTypeAhead
                  spellCheck={false}
                  type="text"
                  value={`${autocomplete}`}
                  readOnly
                  tabIndex={-1}
                />
              )}
              <SearchInput
                autoFocus
                innerRef={this.setInputRef}
                onBlur={onBlur}
                onInput={onInput}
                placeholder={placeholder}
                spellCheck={false}
                type="text"
                value={value}
                onChange={() => {}} // Suppresses the console warning, we handle onChange by using onKeyDown instead.
                onKeyDown={this.onInputKeyDown}
              />
              {isLoading && (
                <SpinnerParent>
                  <Spinner size="small" />
                </SpinnerParent>
              )}
            </SearchFieldBaseInner>
          </SearchFieldBaseOuter>
          {this.renderInputControls()}
        </SearchBox>
        {children}
      </SearchInner>
    );
  }
}
