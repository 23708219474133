import styled, { StyledFunction } from "styled-components";
import * as React from "react";

const GrayTextFunc: StyledFunction<
  { bold?: boolean; small?: boolean } & React.HTMLProps<HTMLElement>
> = styled.span;
export const GrayText = GrayTextFunc`
  color: #aaa;
  ${props => (props.bold ? `font-weight: bold;` : ``)}
  ${props => (props.small ? `font-size: 0.9em;` : ``)}
`;
GrayText.displayName = "GrayText";
