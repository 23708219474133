import * as React from "react";
import { AnyComponent, getErrorMessage } from "../core/helpers";

export const withGraphqlErrorFlags = (Component: AnyComponent): any =>
  class WithGraphqlErrorFlags extends React.Component<any, any> {
    componentDidMount() {
      if (this.props.data.error && this.props.data.error.graphQLErrors) {
        this.props.data.error.graphQLErrors.forEach((error: any) => {
          this.props.showFlag({
            type: "error",
            title: "GraphQL Error",
            message: getErrorMessage(error)
          });
        });
      }
    }

    render() {
      return <Component {...this.props} />;
    }
  };
