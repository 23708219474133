import * as React from "react";
import { LinkProps, Spinner, HeightDiv } from "../../../../palette";
import * as routes from "../../../routes";
import { logger } from "../../..";
import {
  NavigationItem,
  compose,
  ChildProps,
  graphql
} from "@atlassiansox/microscopekit";
import { getErrorMessage } from "../../../helpers";
import {
  goToSearchPath,
  SearchType,
  goToAdvancedSearchPath
} from "../../../plugins/search/routes";
import { colors, themed } from "@atlaskit/theme";
import styled from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import clearNavigationLevelsMutation from "../../../../palette/clear-navigation-levels.mutation.graphql";
import searchQuery from "./navigation-search.query.graphql";
import { navigationSearchQuery } from "./__generated__/navigationSearchQuery";
import { QuickSearch } from "./quick-search";

const AkSearchIcon = require("@atlaskit/icon/glyph/search").default;
const AkAddonIcon = require("@atlaskit/icon/glyph/addon").default;
const { AkNavigationItem } = require("@atlaskit/navigation");

const ErrorMessage = styled.pre`
  margin-top: 2rem;
  padding: 5px 15px;
  border-radius: 3px;
  border: solid 1px #bf2600;
  font-family: Monaco, "Courier New", Courier, monospace;
  white-space: pre-wrap;
  word-break: break-all;
`;

const NotFound = styled.div`
  text-align: center;
  margin-right: 80px;
  margin-bottom: 50px;

  img {
    width: 100px;
  }
  .not-found-title {
    font-weight: 500;
    font-size: 20px;
  }
  .not-found-subtitle {
    margin-top: -4px;
  }
`;

const NavGroupName = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
`;

const LinkTag = styled.span`
  ${(props: { isTag?: boolean }) =>
    props.isTag
      ? `
    border-radius: 3px;
    padding: 1px 5px;
    height: 1rem;
    display: inline-block;
  `
      : ``};
`;
LinkTag.displayName = "LinkTag";

const SearchResultsStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: > column;
  flex: > 1;
  overflow: auto;
  height: 100%;
`;

const AdvSearchLabelStyled = styled.div`
  background-color: ${themed({ light: colors.N0, dark: colors.DN30 })};
  border-top: 1px solid ${themed({ light: colors.N0, dark: colors.N70 })};
  position: absolute;
  width: 495px;
  bottom: 0px;
`;

const groupTypes: SearchType[] = ["services", "tags", "categories"];
const groupsNames = ["Services", "Tags", "Categories"];

export interface SearchGroup {
  name: string;
  type: SearchType;
  items: LinkProps[];
}
export type SearchFn = (
  query: string,
  limit: number,
  offset: number,
  then: (groups: SearchGroup[]) => void,
  ctch: (e: any) => void
) => void;

export type SearchProps = ChildProps<
  {
    search: SearchFn;
  },
  navigationSearchQuery
>;

// TODO replac with connected state.
export interface SearchState {
  query: string;
  error: string | null;
  selected: number;
}
export class NavigationSearchView extends React.Component<
  SearchProps & RouteComponentProps<any>,
  SearchState
> {
  constructor(props: SearchProps & RouteComponentProps<any>) {
    super(props);
    this.state = { query: "", error: null, selected: -1 };
  }

  search = async (query: string, offset: number) => {
    let result = await this.props.data!.refetch({
      query: query.replace(/(\/)/g, "\\/"),
      cursor: Buffer.from(offset.toString()).toString("base64")
    });
    if (result && result.data) {
      const count =
        (result.data.categories.edges || []).length +
        (result.data.services.edges || []).length +
        (result.data.tags.edges || []).length;
      track("Navigation.Search: Found", {
        category: "info",
        element: "navigation-search",
        queryLength: query.length,
        count
      });
    } else if (result.errors) {
      const e = result.errors;
      logger.error(e);
      track("Navigation.Search: Error", {
        category: "error",
        element: "navigation-search",
        queryLength: query.length
      });
      const error = getErrorMessage(e);
      this.setState({ error, selected: -1 });
    }
  };

  onChange = (query: string) => {
    this.setState({ query, error: null, selected: -1 });
    if (query !== "") {
      this.search(query, 0);
    }
  };

  advancedSearch = (link: string) => (e: any) => {
    e.preventDefault();
    this.props.mutate!();
    track("Navigation.Search: AdvancedSearch", {
      category: "info",
      element: "navigation-search"
    });
    this.props.history.push(link);
  };

  advancedSearchLink = (type: SearchType): string =>
    goToSearchPath(type, this.state.query, 1);

  getEdges = (edges: any[]): any =>
    edges.reduce((acc: any, e: any) => {
      if (e.node && e.node.__typename) {
        acc[e.node.__typename] = acc[e.node.__typename]
          ? [...acc[e.node.__typename], e]
          : [e];
      }
      return acc;
    }, {});

  render() {
    const label = "Advanced search";
    const AdvSearchLabel = (
      <AdvSearchLabelStyled>
        <NavigationItem
          key="advanced-search"
          icon={<AkSearchIcon label={label} />}
          text={label}
          onClick={this.advancedSearch(
            goToAdvancedSearchPath(this.state.query)
          )}
        />
      </AdvSearchLabelStyled>
    );
    const { categories, services, tags } = this.props.data!;
    let total = 0;
    const groups: SearchGroup[] = [];
    if (services && services.edges && services.edges.length > 0) {
      const servicesEdges = this.getEdges(services.edges);
      if (servicesEdges.Service && servicesEdges.Service.length > 0) {
        groups.push({
          name: "Services",
          type: "services",
          items: servicesEdges.Service.map((edge: any) => ({
            title: edge && edge.node ? edge.node.id : "",
            icon: <AkAddonIcon label="Service" />,
            to: routes.servicePath(
              encodeURIComponent(edge && edge.node ? edge.node.id : "")
            )
          }))
        });
        total += servicesEdges.Service.length;
      }
    }
    if (tags && tags.edges && tags.edges.length > 0) {
      const tagsEdges = this.getEdges(tags.edges);
      if (tagsEdges.Tag && tagsEdges.Tag.length > 0) {
        groups.push({
          name: "Tags",
          type: "tags",
          items: tagsEdges.Tag.map(
            ({ node: { name } }: { node: { name: string } }) => ({
              isTag: true,
              title: name,
              to: goToAdvancedSearchPath(`tags:${name}`)
            })
          )
        });
        total += tagsEdges.Tag.length;
      }
    }
    if (categories && categories.edges && categories.edges.length > 0) {
      const categoriesEdges = this.getEdges(categories.edges);
      if (categoriesEdges.Category && categoriesEdges.Category.length > 0) {
        groups.push({
          name: "Categories",
          type: "categories",
          items: categoriesEdges.Category.map(
            ({
              node: { name, id, displayName }
            }: {
              node: { name: string; id: string; displayName: string };
            }) => ({
              title: displayName || name,
              to: routes.categoryPath(encodeURIComponent(id))
            })
          )
        });
        total += categoriesEdges.Category.length;
      }
    }

    let params = {
      value: this.state.query,
      onInput: (event: any) => {
        this.onChange(event.target.value);
      },
      onKeyDown: (event: any) => {
        event.preventDefault();

        const foundSomething = groups.length > 0;
        // Selecting search result on Up and Down keys
        const { selected } = this.state;
        if (event.key === "ArrowDown") {
          this.setState({ selected: selected + 1 < total ? selected + 1 : 0 });
        } else if (event.key === "ArrowUp") {
          this.setState({
            selected: selected - 1 >= 0 ? selected - 1 : total - 1
          });
        }

        // Going to Search page on Enter
        if (event.key === "Enter" && selected >= 0) {
          const links = foundSomething
            ? this.buildLinks(groups)
            : this.buildEmptyLinks();
          this.props.mutate!();
          this.props.history.push(links[selected]);
        }
      }
    };

    if (this.props.data!.loading) {
      return (
        <QuickSearch {...params}>
          <HeightDiv height={"50vh"}>
            <Spinner />
          </HeightDiv>
          {AdvSearchLabel}
        </QuickSearch>
      );
    } else if (this.state.error !== null) {
      return (
        <QuickSearch {...params}>
          <ErrorMessage>
            Something went wrong, we are looking into it.
          </ErrorMessage>
          {AdvSearchLabel}
        </QuickSearch>
      );
    } else if (!this.state.query || groups.length === 0) {
      const items: JSX.Element[] = [],
        links = this.buildEmptyLinks();
      for (let i = 0; i < groups.length; i++) {
        const label = `View all ${groupsNames[i]}`;
        items.push(
          <AkNavigationItem
            key={"group-more-" + groups[i]}
            href="#"
            icon={<AkSearchIcon label={label} />}
            onClick={this.advancedSearch(links[i])}
            text={label}
          />
        );
      }
      return (
        <QuickSearch {...params}>
          {this.state.query && groups.length === 0 && (
            <NotFound id="notfound">
              <img src="/images/search-big.svg" alt="search" />
              <p className="not-found-title">No matching search results</p>
              <p className="not-found-subtitle">
                Try again using more general search terms
              </p>
            </NotFound>
          )}
          {items}
          {AdvSearchLabel}
        </QuickSearch>
      );
    } else {
      const items: JSX.Element[] = [],
        links = this.buildLinks(groups);
      let counter = 0;
      for (const group of groups) {
        if (group.items.length > 0) {
          items.push(
            <NavGroupName key={"group-name-" + group.name}>
              {group.name}
            </NavGroupName>
          );
          for (const link of group.items) {
            const path = links[counter];
            items.push(
              <NavigationItem
                href={path}
                icon={link.icon}
                key={link.title}
                text={link.title}
                onClick={() => {
                  const reciprocalRank = 1 / (1 + group.items.indexOf(link));
                  track("Navigation.Search: Click", {
                    category: "info",
                    element: `navigation-search-${group.name}`,
                    label: "reciprocal rank",
                    value: reciprocalRank
                  });
                }}
              />
            );
            counter += 1;
          }
          const label = `View all matching ${group.name}`;
          items.push(
            <NavigationItem
              key={"group-more-" + group.type}
              icon={<AkSearchIcon label={label} />}
              onClick={this.advancedSearch(
                goToAdvancedSearchPath(
                  `type: ${group.name.toLowerCase()} AND ${this.state.query}`
                )
              )}
              text={label}
            />
          );
          counter += 1;
          items.push(<br key={"spacing-" + group.type} />);
        }
      }
      return (
        <QuickSearch {...params}>
          <SearchResultsStyled>{items}</SearchResultsStyled>
          {AdvSearchLabel}
        </QuickSearch>
      );
    }
  }

  buildLinks(groups: SearchGroup[]): string[] {
    const links: string[] = [];
    for (const group of groups) {
      if (group.items.length > 0) {
        for (const link of group.items) {
          links.push(link.to);
        }
        links.push(this.advancedSearchLink(group.type));
      }
    }
    return links;
  }

  buildEmptyLinks(): string[] {
    const links: string[] = [];
    for (const type of groupTypes) {
      links.push(this.advancedSearchLink(type));
    }
    return links;
  }
}

export const NavigationSearch = compose(
  graphql(searchQuery, {
    options: () => ({
      errorPolicy: "all",
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      context: {
        debounceKey: "NavigationSearchV2"
      },
      variables: {
        query: ""
      }
    })
  }),
  graphql(clearNavigationLevelsMutation),
  withRouter
)(NavigationSearchView);
