import * as React from "react";
import { Modal, SelectField, TextField } from "../../../palette";
import serviceAdminsQuery from "../models/service-admins.query.graphql";
import updateServiceMutation from "../models/update-service.mutation.graphql";
import { logger } from "../../../core";
import {
  withShowFlag,
  FlagProps,
  compose,
  graphql,
  ChildProps,
  MutationFunc
} from "@atlassiansox/microscopekit";
import { getErrorMessage } from "../../../core/helpers";
import config from "../../../core/private/config";
import {
  updateService,
  updateServiceVariables
} from "../models/__generated__/updateService";
import styled from "styled-components";
import { Group, User } from "../../../typings";

const SSAMLink = styled.a`
  float: right;
`;

type Props = ChildProps<
  {
    service: {
      id: string;
      metadata?: {
        owner: {
          id: string;
        };
        admins: (User | Group)[];
      };
    };
    onClose: () => void;
    callback?: () => void;
    updateService: MutationFunc<updateServiceVariables>;
  } & FlagProps,
  updateService
>;

const ssamOptions = [
  "Automatically Create New SSAM Container",
  "Use an Existing SSAM Container"
];
export class UpdateSSAMContainerModalView extends React.Component<Props> {
  state = {
    option: ssamOptions[0],
    waiting: false,
    ssamContainerName: ""
  };

  setSSAM = async () => {
    try {
      this.setState({ waiting: true });
      const service = this.props.service;
      await this.props.updateService({
        variables: {
          input: {
            id: service.id,
            metadata: {
              newSSAMContainer:
                this.state.option === ssamOptions[0]
                  ? {
                      displayName: service.id,
                      shortName: service.id
                        .trim()
                        .replace(/\s+/g, "-")
                        .toLowerCase(),
                      owner: service.metadata!.owner.id,
                      members: {
                        users: service
                          .metadata!.admins.filter(
                            a => (a as any).__typename === "User"
                          )
                          .map(a => (a as User).username)
                      }
                    }
                  : undefined,
              ssamContainerName:
                this.state.option === ssamOptions[1]
                  ? this.state.ssamContainerName
                  : undefined
            }
          }
        }
      });
      track("Overview.UpdateSSAMContainerModal: Success", {
        category: "info"
      });
      this.props.showFlag({
        type: "success",
        title: "Success",
        message: "SSAM Container set."
      });
      this.props.onClose();
      if (this.props.callback) {
        this.props.callback();
      }
    } catch (error) {
      track("Overview.UpdateSSAMContainerModal: UpdateFailed", {
        category: "error"
      });
      logger.error(error);
      this.props.showFlag({
        type: "error",
        title: "Failed to set SSAM Container",
        message:
          getErrorMessage(error) +
          (error.extensions && error.extensions.code === "AUTO_SSAM_CONFLICT")
            ? ' Use the "Use Existing SSAM Container" option instead.'
            : ""
      });
    } finally {
      this.setState({ waiting: false });
    }
  };

  render() {
    return (
      <Modal
        heading="Update SSAM Container"
        actions={[
          {
            text: "Update",
            onClick: this.setSSAM,
            isDisabled: this.state.waiting
          },
          {
            text: "Close",
            onClick: this.props.onClose,
            isDisabled: this.state.waiting
          }
        ]}
        onClose={this.props.onClose}
      >
        <SelectField
          label="Update SSAM Container Options"
          value={this.state.option}
          isDisabled={this.state.waiting}
          values={ssamOptions}
          onChange={e => this.setState({ option: e.target.value })}
        />
        <br />
        {this.state.option === ssamOptions[0] ? (
          <>
            Automatically creating an SSAM container will retain the current
            owner / team member privileges listed currently on the overview
            page.
          </>
        ) : (
          <>
            Using a pre-existing SSAM container will transfer owner and team
            member privileges of the service to the owner of the container and
            the people within the "admins" access level of the container
            respectively.
            <p />
            <strong>
              Please make sure this is what you want before updating the
              service.
            </strong>
            <br />
            <TextField
              label="Existing SSAM Container Name"
              onChange={e =>
                this.setState({ ssamContainerName: e.target.value })
              }
              value={this.state.ssamContainerName}
              isDisabled={this.state.waiting}
            />
            <SSAMLink
              href={
                config.ssamUrl +
                "/access/containers/" +
                this.state.ssamContainerName
              }
              target="_blank"
            >
              View container on SSAM
            </SSAMLink>
          </>
        )}
      </Modal>
    );
  }
}

export const UpdateSSAMContainerModal = compose(
  graphql(serviceAdminsQuery, {
    options: (props: Props) => ({
      errorPolicy: "all",
      variables: { id: props.service.id },
      skip: props.service.metadata
    })
  }),
  graphql(updateServiceMutation, {
    name: "updateService"
  }),
  withShowFlag
)(UpdateSSAMContainerModalView);
