import styled from "styled-components";

export const FlexDiv = styled.div`
  display: flex;
`;
FlexDiv.displayName = "FlexDiv";

export const SmallFlexDiv = styled(FlexDiv)`
  width: 70%;
`;
SmallFlexDiv.displayName = "SmallFlexDiv";
