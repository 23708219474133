import * as React from "react";
import { setActivePage } from "../../private/private-actions";
import { SearchPage } from "./pages/search-page";

export class SearchRoute extends React.Component {
  componentDidMount() {
    setActivePage({ type: "other" });
  }

  render() {
    return <SearchPage />;
  }
}
