const storedTheme = localStorage.getItem("theme");

export const themeDefaults = {
  theme: storedTheme
    ? JSON.parse(storedTheme)
    : {
        __typename: "Theme",
        mode: "light"
      }
};
