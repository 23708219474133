import privateConfig from "./private/config";
import { getGlobals } from "./private/globals";

export { logger } from "./private/lib/logger";

// Publicly available config
export const config = {
  environment: privateConfig.environment
};

export const getCurrentUser = () => {
  const user = getGlobals().state.currentUser;
  // This method could be used only in plugins, when the user is always logged in. For the stuff in core, to handle
  // cases when user could be not authenticated the `getGlobals().state.currentUser` should be used.
  if (!user) {
    throw new Error(
      "core/getCurrentUser could be used only in plugins, when user is always logged in"
    );
  }
  return user;
};
