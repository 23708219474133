import * as React from "react";
import { logger } from "../../../private/lib/logger";
import {
  BoldSpan,
  Button,
  Form,
  SelectField,
  TextField
} from "../../../../palette";
import { getCurrentUser } from "../../../index";
import { TextArea } from "../../../../palette/editable/editable-text";
import {
  graphql,
  Link,
  MutationFunc,
  compose,
  withShowFlag,
  FlagProps
} from "@atlassiansox/microscopekit";
import {
  createService,
  createService_createServiceV2,
  createServiceVariables
} from "../models/__generated__/createService";
import styled from "styled-components";
import createServiceMutation from "../models/create-service.mutation.graphql";
import { getErrorMessage } from "../../../helpers";
const AkSpinner = require("@atlaskit/spinner").default;
const AkTooltip = require("@atlaskit/tooltip").default;
const { Grid, GridColumn } = require("@atlaskit/page");

const serviceTiersLabels = [0, 1, 2, 3].map(n => `Tier ${n}`);
const createSSAMOptions = [
  "Automatically create a new SSAM Container",
  "Specify an Existing SSAM Container"
];
const IndentedDiv = styled.div`
  margin-left: 2em;
`;

type CreateServiceFormProps = FlagProps & {
  createService: MutationFunc<createService, createServiceVariables>;
  onCreateSuccess: (result: createService_createServiceV2) => void;
};

const HelpTextDiv = styled.div`
  margin-top: 2em;
`;
const SubHelpTextDiv = styled.div`
  margin-top: 0.5em;
`;

export class CreateServiceFormView extends React.Component<
  CreateServiceFormProps
> {
  state = {
    name: "",
    owner: getCurrentUser().username,
    platform: "micros",
    specifyPlatform: "",
    serviceTier: 3,
    businessUnit: "",
    servingURL: "",
    description: "",
    createInProgress: false,
    ssamOption: createSSAMOptions[0],
    ssamContainerName: ""
  };

  private createService = async () => {
    this.setState({ createInProgress: true });
    try {
      const {
        name,
        description,
        owner,
        businessUnit,
        servingURL,
        serviceTier
      } = this.state;
      const trimmedName = name.trim();
      const platform =
        this.state.platform === "other"
          ? this.state.specifyPlatform
          : this.state.platform;

      const result = await this.props.createService({
        variables: {
          input: {
            name: trimmedName,
            owner,
            serviceTier,
            platform,
            description,
            servingURL,
            businessUnit,
            ssamContainerName:
              this.state.ssamOption === createSSAMOptions[1]
                ? this.state.ssamContainerName
                : undefined,
            newSSAMContainer:
              this.state.ssamOption === createSSAMOptions[0]
                ? {
                    owner,
                    displayName: trimmedName,
                    shortName: trimmedName.replace(/\s+/g, "-").toLowerCase(),
                    members: {}
                  }
                : undefined
          }
        }
      });
      if (result?.data) {
        track("CreateServiceDrawer: ServiceCreated", { category: "info" });
        this.props.onCreateSuccess(result.data.createServiceV2);
      }
    } catch (error) {
      track("CreateServiceDrawer: CreateFailed", { category: "error" });
      logger.error(error);
      this.props.showFlag({
        type: "error",
        title: "Service creation failed.",
        message: getErrorMessage(error)
      });
    } finally {
      this.setState({ createInProgress: false });
    }
  };

  render() {
    const platforms = ["micros", "other"];
    return (
      <>
        <Grid>
          <GridColumn medium={8}>
            <Form onSubmit={this.createService}>
              <AkTooltip
                content={
                  <span>
                    The name that will identify your service. Micros service
                    names may only contain alphanumeric characters and dashes.
                    We recommend following the same convention even when setting
                    platform to <i>other</i>
                  </span>
                }
              >
                <TextField
                  required
                  label="Service Name"
                  onChange={e => this.setState({ name: e.target.value })}
                  value={this.state.name}
                  isDisabled={this.state.createInProgress}
                />
              </AkTooltip>
              <br />
              <AkTooltip
                content={
                  <span>The StaffID of the user who will own this service</span>
                }
              >
                <TextField
                  label="Service Owner"
                  value={this.state.owner}
                  isDisabled={true}
                />
              </AkTooltip>
              <br />
            </Form>
          </GridColumn>
        </Grid>
        <Grid>
          <GridColumn medium={8}>
            <Form>
              <SelectField
                label="Platform"
                value={this.state.platform}
                isDisabled={this.state.createInProgress}
                values={platforms}
                onChange={e => this.setState({ platform: e.target.value })}
              />
              {this.state.platform === "other" ? (
                <IndentedDiv>
                  <TextField
                    label="Specify Platform"
                    onChange={e =>
                      this.setState({ specifyPlatform: e.target.value })
                    }
                    value={this.state.specifyPlatform}
                    isDisabled={this.state.createInProgress}
                  />
                </IndentedDiv>
              ) : null}
            </Form>
          </GridColumn>
          <GridColumn medium={4}>
            <HelpTextDiv>
              This action will create a new service record in Service Central
              but will not create a new Micros service if the platform is not
              set to 'micros'.
            </HelpTextDiv>
          </GridColumn>
        </Grid>
        <Grid>
          <GridColumn medium={8}>
            <Form>
              {this.state.platform === "other" && (
                <>
                  <SelectField
                    label="SSAM Container"
                    required
                    value={this.state.ssamOption}
                    isDisabled={this.state.createInProgress}
                    values={createSSAMOptions}
                    onChange={e =>
                      this.setState({ ssamOption: e.target.value })
                    }
                  />
                  {this.state.ssamOption === createSSAMOptions[1] && (
                    <IndentedDiv>
                      <TextField
                        label="Enter the name of an existing SSAM Container"
                        required
                        onChange={e =>
                          this.setState({
                            ssamContainerName: e.target.value
                          })
                        }
                        value={this.state.ssamContainerName}
                        isDisabled={this.state.createInProgress}
                      />
                    </IndentedDiv>
                  )}
                </>
              )}
              <SelectField
                label="Service Tier"
                value={`Tier ${this.state.serviceTier}`}
                isDisabled={this.state.createInProgress}
                values={serviceTiersLabels}
                onChange={e =>
                  this.setState({
                    serviceTier: serviceTiersLabels.indexOf(e.target.value)
                  })
                }
              />
              <br />
              <AkTooltip
                content={<span>The URL where your service can be reached</span>}
              >
                <TextField
                  label="Serving URL"
                  onChange={e => this.setState({ servingURL: e.target.value })}
                  value={this.state.servingURL}
                  isDisabled={this.state.createInProgress}
                />
              </AkTooltip>
              <br />
              <AkTooltip
                content={
                  <span>
                    The organization who will own this service e.g.{" "}
                    <i>Workplace Technology</i>
                  </span>
                }
              >
                <TextField
                  label="Business Unit"
                  onChange={e =>
                    this.setState({ businessUnit: e.target.value })
                  }
                  value={this.state.businessUnit}
                  isDisabled={this.state.createInProgress}
                />
              </AkTooltip>
              <br />
              <AkTooltip
                content={
                  <span>A brief description of what this service will do</span>
                }
              >
                <TextArea
                  label="Description"
                  onChange={text => this.setState({ description: text })}
                  text={this.state.description}
                  disabled={this.state.createInProgress}
                />
              </AkTooltip>
              <br />
              <Button
                appearance="primary"
                onClick={this.createService}
                waiting={this.state.createInProgress}
                iconBefore={
                  this.state.createInProgress ? (
                    <AkSpinner size="small" isCompleting={false} />
                  ) : (
                    undefined
                  )
                }
              >
                Create
              </Button>
            </Form>
          </GridColumn>
          {this.state.platform === "other" && (
            <GridColumn medium={4}>
              <HelpTextDiv>
                If you're not creating a Micros service, you will have to
                specify an SSAM container name for the service to use. (
                <Link to="http://go/whatisSSAM">go/whatisSSAM</Link>)
              </HelpTextDiv>
              {this.state.ssamOption === createSSAMOptions[1] && (
                <SubHelpTextDiv>
                  The team members for the service need to be in an SSAM access
                  level with the shortname "admins".
                </SubHelpTextDiv>
              )}
            </GridColumn>
          )}
        </Grid>
      </>
    );
  }
}

export const CreateServiceForm = compose(
  graphql(createServiceMutation, { name: "createService" }),
  withShowFlag
)(CreateServiceFormView);
