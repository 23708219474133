import * as React from "react";
import { PageLayout } from "../../../palette/layout/page-layout";
import { getCurrentUser } from "../../../core";
import { GrayText } from "../../../palette";

export function noMetadataMessage(title: string) {
  return (
    <PageLayout title={title}>
      <GrayText bold>Service not present in Service Central</GrayText>
    </PageLayout>
  );
}

export function isCreator(category: { creator: string }) {
  const user = getCurrentUser().username;
  if (user === category.creator) {
    return true;
  }
  // TODO: PSRE-897: change back to false
  return true;
}
