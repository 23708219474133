export const helloMicrosExtensionIdMap = new Map<string, string>([
  ["dependencies", "dependencies"],
  [
    "compliance",
    "b%3AYXJpOmNsb3VkOmVjb3N5c3RlbTo6ZXh0ZW5zaW9uLzIzZDBiMGEyLTU4OTctNDYxOC1iYjU4LTg5MjQ0NmU1MWMyZi9jYjcxNzQ4Yy0yNGY1LTQ1ZDEtYjVkYS01NTg3NDM2ZDU1NDcvc3RhdGljL2RfX2NvbXBsaWFuY2U%3D"
  ],
  [
    "data-classifications",
    "b%3AYXJpOmNsb3VkOmVjb3N5c3RlbTo6ZXh0ZW5zaW9uLzIzZDBiMGEyLTU4OTctNDYxOC1iYjU4LTg5MjQ0NmU1MWMyZi9jYjcxNzQ4Yy0yNGY1LTQ1ZDEtYjVkYS01NTg3NDM2ZDU1NDcvc3RhdGljL2dfX2RhdGEtY2xhc3NpZmljYXRpb25z"
  ],
  [
    "resilience",
    "b%3AYXJpOmNsb3VkOmVjb3N5c3RlbTo6ZXh0ZW5zaW9uLzIzZDBiMGEyLTU4OTctNDYxOC1iYjU4LTg5MjQ0NmU1MWMyZi9jYjcxNzQ4Yy0yNGY1LTQ1ZDEtYjVkYS01NTg3NDM2ZDU1NDcvc3RhdGljL2hfX3Jlc2lsaWVuY2U%3D"
  ],
  [
    "deployments",
    "b%3AYXJpOmNsb3VkOmVjb3N5c3RlbTo6ZXh0ZW5zaW9uLzIzZDBiMGEyLTU4OTctNDYxOC1iYjU4LTg5MjQ0NmU1MWMyZi9jYjcxNzQ4Yy0yNGY1LTQ1ZDEtYjVkYS01NTg3NDM2ZDU1NDcvc3RhdGljL2pfX2RlcGxveW1lbnRz"
  ],
  [
    "releases",
    "b%3AYXJpOmNsb3VkOmVjb3N5c3RlbTo6ZXh0ZW5zaW9uLzIzZDBiMGEyLTU4OTctNDYxOC1iYjU4LTg5MjQ0NmU1MWMyZi9jYjcxNzQ4Yy0yNGY1LTQ1ZDEtYjVkYS01NTg3NDM2ZDU1NDcvc3RhdGljL2xfX3JlbGVhc2Vz"
  ]
]);
