import React from "react";
import { CreateServiceForm } from "./core/plugins/create-service/pages/create-service-form";
import { compose, FlagProps, MutationFunc } from "@atlassiansox/microscopekit";

import {
  createServiceVariables,
  createService,
  createService_createServiceV2
} from "./core/plugins/create-service/models/__generated__/createService";

import { RouteComponentProps, withRouter } from "react-router-dom";
import { getIsChromeless } from "./core/helpers";

type Props = RouteComponentProps &
  FlagProps & {
    createService: MutationFunc<createService, createServiceVariables>;
  };
export const CreateServiceView: React.FC<Props> = props => {
  const goToService = (res: createService_createServiceV2) => {
    props.history.push(`/services/${res.id ? encodeURIComponent(res.id) : ""}`);
  };

  const onCreateSuccess = (res: createService_createServiceV2) => {
    if (!getIsChromeless()) {
      return goToService(res);
    }

    window.parent.postMessage(
      JSON.stringify({ service: res, microscopeServiceCreate: true }),
      "*"
    );
  };

  return (
    <div style={{ paddingTop: 60 }}>
      <CreateServiceForm onCreateSuccess={onCreateSuccess} />
    </div>
  );
};

export const CreateService = compose(withRouter)(CreateServiceView);
