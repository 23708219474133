import * as React from "react";
import * as Palette from "../../../../palette";
import styled from "styled-components";

const fullDocsLink =
  "https://www.elastic.co/guide/en/elasticsearch/reference/6.3/query-dsl-query-string-query.html#query-string-syntax";

const HelpMarkup = styled.div`
  line-height: 1.9rem;

  code {
    padding: 3px 9px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: #eee;
    font-family: Monaco, "Courier New", Courier, monospace;
    border-radius: 3px;
    white-space: nowrap;
  }
`;

export interface SearchHelpProps {
  close: () => void;
}

export class SearchHelp extends React.Component<SearchHelpProps> {
  render() {
    return (
      <Palette.Modal
        heading="Search Help"
        actions={[{ text: "Close", onClick: this.props.close }]}
        onClose={this.props.close}
      >
        <HelpMarkup>
          <p>
            All searches are <b>case-sensitive</b>
          </p>
          <p>
            Tag search <code>tags:mytag</code>
          </p>
          <p>
            Attribute search <code>owner:jraynor</code>
          </p>
          <p>
            Fuzzy search <code>owner:*john*</code>
          </p>
          <p>
            Combine queries <code>team:identity AND platform:micros</code> use{" "}
            <code>AND, OR, NOT and ()</code>
          </p>
          <p>
            Checkout the{" "}
            <a href={fullDocsLink} target="_blank" rel="noopener noreferrer">
              full doc
            </a>{" "}
            for list of all available elasticsearch query options.
          </p>
        </HelpMarkup>
      </Palette.Modal>
    );
  }
}
