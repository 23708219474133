import { User } from "./lib/auth";
import { getGlobals } from "./globals";
import { Listener, buildConnect } from "./lib/connect";
import { BaseService } from "../models/interfaces";

// State
export interface State {
  readonly currentUser: User | null;
  readonly activePage: ActivePage;
}

export type ActivePage = ServicePage | OtherPage | null;
export interface ServicePage {
  type: "service";
  service: BaseService;
}
// Sometimes it's not important what page it is, so calling all such pages as 'other'
export interface OtherPage {
  type: "other";
}

export function buildInitialState(currentUser: User | null): State {
  return {
    currentUser,
    activePage: null
  };
}

// Connect
export const connect = buildConnect(getState, {}, subscribe);

export function getState() {
  return getGlobals().state;
}

export function setState(state: Partial<State>) {
  (getGlobals() as any).state = { ...getGlobals().state, ...state };
  for (const listener of getGlobals().stateListeners) {
    listener();
  }
}

export function subscribe(listener: Listener): () => void {
  getGlobals().stateListeners.push(listener);
  return () =>
    ((getGlobals() as any).stateListeners = getGlobals().stateListeners.filter(
      l => l !== listener
    ));
}
