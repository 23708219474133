// The only way to mutate app state is via actions, these are list of private actions
// available only inside of the core.
import { ActivePage, setState } from "./private-state";
import { User } from "./lib/auth";

export function setCurrentUser(currentUser: User | null) {
  setState({ currentUser });
}

export function setActivePage(activePage: ActivePage) {
  setState({ activePage });
}
