import * as React from "react";
import styled, { StyledFunction } from "styled-components";

const HeightDivFunc: StyledFunction<
  { height?: string } & React.HTMLProps<HTMLElement>
> = styled.div;
export const HeightDiv = HeightDivFunc`
  ${props => (props.height ? `height: ${props.height};` : ``)};
`;
HeightDiv.displayName = "HeightDiv";
