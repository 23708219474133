export const operatorOptions = [
  {
    label: "AND",
    value: "AND"
  },
  {
    label: "OR",
    value: "OR"
  }
];

export const modifierOptions = [
  {
    label: "Exact",
    value: ""
  },
  {
    label: "Fuzzy",
    value: "~"
  }
];

const serviceOptions = [
  {
    label: "UUID",
    value: "uuid:"
  },
  {
    label: "Service Name",
    value: "(type:services AND name:"
  },
  {
    label: "Description",
    value: "description:"
  },
  {
    label: "Compliance Policy ID",
    value: "compliancePolicyID:"
  },
  {
    label: "Owner (User ID)",
    value: "owner:"
  },
  {
    label: "Serving URL",
    value: "servingURL:"
  },
  {
    label: "Business Unit",
    value: "businessUnit:"
  },
  {
    label: "Date Created (yyyy-mm-dd)",
    value: "createdOn:"
  },
  {
    label: "Date Modified (yyyy-mm-dd)",
    value: "modifiedOn:"
  },
  {
    label: "Modified By",
    value: "modifiedBy:"
  },
  {
    label: "Team",
    value: "team:"
  },
  {
    label: "Hipchat Room Name",
    value: "hipchatRoomName:"
  },
  {
    label: "Service Tier (0-3)",
    value: "serviceTier:"
  },
  {
    label: "SSAM Container Name",
    value: "ssamContainerName:"
  },
  {
    label: "Location",
    value: "location:"
  },
  {
    label: "Logging ID",
    value: "loggingID:"
  },
  {
    label: "Pager Duty Service ID",
    value: "pagerdutyServiceID:"
  },
  {
    label: "Public Facing (true/false)",
    value: "publicFacing:"
  },
  {
    label: "Third-Party Provided (true/false)",
    value: "thirdPartyProvided:"
  },
  {
    label: "Stateless (true/false)",
    value: "stateless:"
  },
  {
    label: "Zero Downtime Upgrades (true/false)",
    value: "zeroDowntimeUpgrades:"
  },
  {
    label: "Tags",
    value: "tags:"
  },
  {
    label: "Categories",
    value: "categories:"
  },
  {
    label: "DNS Names",
    value: "dnsNames:"
  },
  {
    label: "Microscope Panels",
    value: "microscopePanels:"
  },
  {
    label: "Platform",
    value: "platform:"
  }
];

const categoryOptions = [
  {
    label: "ID",
    value: "(type:categories AND id:"
  },
  {
    label: "Path Name",
    value: "(type:categories AND name:"
  },
  {
    label: "Display Name",
    value: "displayName:"
  }
];

const tagOptions = [
  {
    label: "ID",
    value: "(type:tags AND id:"
  },
  {
    label: "Tag Name",
    value: "(type:tags AND name:"
  }
];

const typeOptions = [
  {
    label: "Type (services, categories, tags)",
    value: "type:"
  }
];

export const groupedOptions = [
  {
    label: "Service",
    options: serviceOptions
  },
  {
    label: "Tag",
    options: tagOptions
  },
  {
    label: "Category",
    options: categoryOptions
  },
  {
    label: "Type",
    options: typeOptions
  }
];

/**
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html
 * https://www.regular-expressions.info/characters.html#:~:text=In%20the%20regex%20flavors%20discussed,%2C%20the%20closing%20parenthesis%20)%2C%20the
 * Excluding -, *, and ? since user manually enters wildcard queries
 */
export const reservedChars = [
  "\\+",
  "=",
  "&&",
  "\\|\\|",
  "<",
  ">",
  "!",
  "\\(",
  "\\)",
  "\\{",
  "}",
  "\\[",
  "]",
  "\\^",
  '"',
  "~",
  ":",
  "\\\\",
  "/"
];
