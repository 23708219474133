import * as React from "react";
import mapImage from "./map.svg";
const EmptyState = require("@atlaskit/empty-state").default;

export class NotFoundPage extends React.Component {
  render() {
    return (
      <EmptyState
        header="Not Found"
        description="We can't find the page you're looking for"
        imageUrl={mapImage}
        maxImageWidth={800}
        maxImageHeight={800}
      />
    );
  }
}
