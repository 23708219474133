import * as React from "react";
import { NavigationItem } from "../../../palette/navigation/navigation-item";
import OverviewIcon from "@atlaskit/icon/glyph/overview";

export class ServiceCentralCategoryLinks extends React.Component<{
  id: string;
}> {
  render() {
    return (
      <>
        <NavigationItem
          icon={<OverviewIcon label="Overview" />}
          text="Overview"
          href={`/categories/${encodeURIComponent(this.props.id)}`}
        />
      </>
    );
  }
}
