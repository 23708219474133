import React, { useEffect, useState } from "react";
import { withShowFlag, Flag, compose } from "@atlassiansox/microscopekit";
import styled from "styled-components";
import {
  checkCompassMigrationStatus,
  CompassMigrationStatusType,
  enableCompassMigration,
  getCompassEndpoint,
  patchStylesForCompassMigrationBanner
} from "./core/helpers";
import { useLocation } from "react-router-dom";

type Props = { showFlag: (flag: Flag) => void };

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background-color: #42526e;
  width: auto;
  z-index: 201;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  a {
    font-weight: 700;
    color: #ffffff;
  }

  a:hover {
    color: #ffffff;
  }
`;

const BLOG_LINK = `https://hello.atlassian.net/wiki/spaces/ServiceMatrix/blog/2022/11/17/2212709994/Compass+is+ready+for+Atlassians+on+Hello`;

export const CompassBannerView: React.FC<Props> = props => {
  const [compassMigrationStatus, setCompassMigrationStatus] = useState<
    CompassMigrationStatusType
  >();
  const [loading, setIsLoading] = useState<boolean>(true);
  const [compassEndpoint, setCompassEndpoint] = useState<string>();
  const { pathname } = useLocation();
  const migrateUser = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const response = await enableCompassMigration();
    if (!response) {
      return props.showFlag({
        type: "error",
        title: "Failed to opt-in to Compass",
        message:
          "Sorry something went wrong when opting you in. Please try again"
      });
    }
    if (compassEndpoint) {
      window.location.href = compassEndpoint;
    }
  };

  useEffect(() => {
    Promise.all([checkCompassMigrationStatus(), getCompassEndpoint()]).then(
      r => {
        setCompassMigrationStatus(r[0] as CompassMigrationStatusType);
        setCompassEndpoint(r[1]);
        setIsLoading(false);
      }
    );
  }, [pathname]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (compassMigrationStatus !== "opted-in") {
      patchStylesForCompassMigrationBanner();
    }
  }, [loading, compassMigrationStatus]);

  if (loading) {
    return null;
  }

  const isServicePage = compassEndpoint?.includes("/compass/component/");

  return (
    <Wrapper id="compass-banner">
      Hey Friend! We are in the midst of transitioning you from Microscope to
      Compass.
      {isServicePage && (
        <a target="_blank" href={compassEndpoint}>
          {" "}
          You can click here to view this service in Compass and try it out.
        </a>
      )}{" "}
      If you would like to opt-in to using Compass all the time{" "}
      <a onClick={migrateUser} href="#">
        CLICK HERE
      </a>
      . Want to know what’s going on?{" "}
      <a target="_blank" href={BLOG_LINK}>
        Click here to read more about this transition
      </a>
    </Wrapper>
  );
};

export const CompassBanner = compose(withShowFlag)(CompassBannerView);
