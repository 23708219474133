import * as React from "react";
import SectionMessage from "@atlaskit/section-message";
import { logger } from "../../../core";
import {
  getErrorMessage,
  getIsChromeless,
  isSufficientPermission,
  isForbiddenError
} from "../../../core/helpers";
import serviceDocumentationQuery from "../models/service-documentation.query.graphql";
import createServiceDocumentationMutation from "../models/create-service-documentation.mutation.graphql";
import deleteServiceDocumentationMutation from "../models/delete-service-documentation.mutation.graphql";
import { Permission } from "../../../typings";
import styled from "styled-components";
import { serviceDocumentation } from "../models/__generated__/serviceDocumentation";
import {
  createServiceDocumentation,
  createServiceDocumentationVariables
} from "../models/__generated__/createServiceDocumentation";
import {
  deleteServiceDocumentation,
  deleteServiceDocumentationVariables
} from "../models/__generated__/deleteServiceDocumentation";
import {
  Button,
  ButtonModal,
  FlagProps,
  FlexDiv,
  Link,
  PageLayout,
  Table,
  TextField,
  withGraphqlErrorFlags,
  withLoadingSpinner,
  withServiceNotFoundHandler,
  withShowFlag,
  compose,
  ChildProps,
  graphql,
  MutationFunc,
  Card
} from "@atlassiansox/microscopekit";
import { Tc2Error } from "../../../core/components/tc2";
import { compassMicroscopePanel } from "./constants";
const AkLinkIcon = require("@atlaskit/icon/glyph/editor/link").default;
const AkSpinner = require("@atlaskit/spinner").default;
const { ButtonGroup } = require("@atlaskit/button");
const { ErrorMessage } = require("@atlaskit/form");

const DocLinkIcon = styled(AkLinkIcon)`
  position: relative;
  top: 7px;
  margin-right: 0.5rem;
`;
const DeleteButtonDiv = styled(FlexDiv)`
  float: right;
`;
const VerticalMiddleSpan = styled.div`
  vertical-align: middle;
  display: inline-block;
`;

type ServiceDocumentationProps = ChildProps<
  {
    createServiceDocumentation: MutationFunc<
      createServiceDocumentation,
      createServiceDocumentationVariables
    >;
    deleteServiceDocumentation: MutationFunc<
      deleteServiceDocumentation,
      deleteServiceDocumentationVariables
    >;
  } & FlagProps,
  serviceDocumentation
>;

interface State {
  deleteInProgress: { id: string; deleting: boolean }[];
}

// Sort the documents alphabetically
function sortDocs(a: { name: string }, b: { name: string }) {
  return a.name < b.name ? -1 : b.name < a.name ? 1 : 0;
}

export class ServiceDocumentationPageView extends React.Component<
  ServiceDocumentationProps,
  State
> {
  constructor(props: ServiceDocumentationProps) {
    super(props);

    let deleteTracker: { id: string; deleting: boolean }[] = [];

    if (
      this.props.data &&
      this.props.data.service &&
      this.props.data.service.documentation
    ) {
      deleteTracker = this.updateDeleteTracker(
        this.props.data.service.documentation
      );
    }

    this.state = {
      deleteInProgress: deleteTracker
    };
  }

  updateDeleteTracker = (
    docs: { id: string }[]
  ): { id: string; deleting: boolean }[] => {
    let deleteTracker: { id: string; deleting: boolean }[] = [];
    docs.forEach(doc => {
      deleteTracker.push({
        id: doc.id,
        deleting: this.isDeleting(doc.id)
      });
    });
    return deleteTracker;
  };

  private isDeleting(id: string) {
    if (this.state && this.state.deleteInProgress) {
      const doc = this.state.deleteInProgress!.find(doc => doc.id === id);
      return doc ? doc.deleting : false;
    }
    return false;
  }

  componentWillReceiveProps(newProps: ServiceDocumentationProps) {
    if (
      newProps.data &&
      newProps.data.service &&
      newProps.data.service.documentation
    ) {
      this.setState({
        deleteInProgress: this.updateDeleteTracker(
          newProps.data.service.documentation
        )
      });
    }
  }
  componentDidMount() {
    document.title = this.props.data!.service!.id + " | Documentation";
  }

  componentWillUnmount() {
    document.title = "Microscope";
  }

  private isValidUrl(urlString: string): boolean {
    let url;
    try {
      url = new URL(urlString);
    } catch {
      return false;
    }
    return true;
  }
  private async createDoc(doc: { title: string; url: string }) {
    const { id } = this.props.data!.service!;
    try {
      if (!this.isValidUrl(doc.url)) {
        throw new Error("Invalid URL Provided");
      }
      await this.props.createServiceDocumentation({
        variables: {
          input: {
            service: id,
            name: doc.title,
            url: doc.url
          }
        }
      });
      track("ServiceDocumentation: CreateSuccess", { category: "info" });
      this.props.showFlag({
        message: "Created documentation",
        title: "Created documentation",
        type: "success"
      });
    } catch (e) {
      track("ServiceDocumentation: CreateFailed", { category: "error" });
      logger.error(e);
      const errMessage = getErrorMessage(e);
      const errComponent = isForbiddenError(errMessage) ? (
        <Tc2Error serviceId={id} />
      ) : (
        <p>{errMessage}</p>
      );

      this.props.showFlag({
        type: "error",
        title: "Failed to create documentation",
        message: errComponent,
        disableAutoDismiss: true
      });
    }
  }

  private async deleteDoc(doc: { id: string }) {
    const { id } = this.props.data!.service!;
    try {
      this.setState({
        deleteInProgress: this.state.deleteInProgress.map(
          ({ id, deleting }) => ({
            id,
            deleting: doc.id === id || deleting
          })
        )
      });

      await this.props.deleteServiceDocumentation({
        variables: {
          input: {
            service: id,
            document: doc.id
          }
        }
      });
      track("ServiceDocumentation: DeleteSuccess", { category: "info" });
      this.props.showFlag({
        message: "Deleted documentation",
        title: "Deleted documentation",
        type: "success"
      });
    } catch (e) {
      track("ServiceDocumentation: DeleteFailed", { category: "error" });
      logger.error(e);
      const errMessage = getErrorMessage(e);
      const errComponent = isForbiddenError(errMessage) ? (
        <Tc2Error serviceId={id} />
      ) : (
        <p>{errMessage}</p>
      );

      this.props.showFlag({
        type: "error",
        title: "Failed to delete documentation",
        message: errComponent,
        disableAutoDismiss: true
      });
    }
  }

  render() {
    const { documentation, currentUserPermission } = this.props.data!.service!;

    let actions: JSX.Element = <span />;
    if (isSufficientPermission(currentUserPermission, Permission.Write)) {
      actions = (
        <ButtonGroup>
          <ButtonModal
            buttonText="Add Document"
            heading="Add Documentation"
            autoFocus={false}
            isDisabled={compassMicroscopePanel.isDisabled}
            actions={{
              submit: {
                text: "Add",
                onSubmit: this.createDoc.bind(this)
              },
              close: {
                text: "Cancel"
              }
            }}
          >
            <TextField
              name="title"
              label="Title"
              isRequired
              message={({ error }) =>
                error && <ErrorMessage>{error}</ErrorMessage>
              }
            />
            <TextField
              name="url"
              label="URL"
              isRequired
              message={({ error }) =>
                error && <ErrorMessage>{error}</ErrorMessage>
              }
            />
          </ButtonModal>
        </ButtonGroup>
      );
    }

    documentation.sort(sortDocs);
    return (
      <PageLayout title="Documentation" actions={actions}>
        {getIsChromeless() && (
          <>
            <SectionMessage>
              <p>
                Managing component documentation is moving to Compass. Learn
                about it{" "}
                <a
                  href="https://hello.atlassian.net/wiki/spaces/ServiceMatrix/blog/2024/11/05/4413218493/Announcing+Microscope+Documentation+Panel+Deprecation"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </SectionMessage>
            <Card>
              <p>
                If you are trying to add a link to your Compass Component,
                you've come to the right place! When you add documents here,
                they'll be synced with Compass and will show up in the Component
                Overview page.
              </p>
              <p>
                If you are trying to add a repository link, make sure the title
                is <strong>source code</strong>
              </p>
              <p>
                If you want the on-call information on the Overview page to
                show, add a link to your Opsgenie <strong>schedule</strong> link
                (the title of the link can be anything).
              </p>
              <p>
                If you are trying to add a Slack link, head over to the{" "}
                <strong>Service Overview</strong> page.
              </p>
            </Card>
          </>
        )}
        <Table
          format={["big", "small"]}
          cells={documentation.map(doc => {
            const tracker = this.state.deleteInProgress.find(
              ({ id }) => doc.id === id
            );
            const deleting = tracker ? tracker.deleting : false;

            return [
              <span key={doc.id + " link"}>
                <Link to={doc.url}>
                  <VerticalMiddleSpan>
                    <DocLinkIcon label="Link" />
                  </VerticalMiddleSpan>
                  {doc.name}
                </Link>
              </span>,
              <DeleteButtonDiv key={doc.id}>
                {isSufficientPermission(
                  currentUserPermission,
                  Permission.Write
                ) ? (
                  <Button
                    iconBefore={
                      deleting ? (
                        <AkSpinner size="small" isCompleting={false} />
                      ) : null
                    }
                    disabled={compassMicroscopePanel.isDisabled || deleting}
                    onClick={() => this.deleteDoc(doc)}
                  >
                    Delete
                  </Button>
                ) : (
                  <span />
                )}
              </DeleteButtonDiv>
            ];
          })}
          emptyMessage="There's no documentation"
        />
      </PageLayout>
    );
  }
}

export const ServiceDocumentationPage = compose(
  graphql(serviceDocumentationQuery, {
    options: () => ({
      errorPolicy: "all"
    })
  }),
  graphql(createServiceDocumentationMutation, {
    name: "createServiceDocumentation"
  }),
  graphql(deleteServiceDocumentationMutation, {
    name: "deleteServiceDocumentation"
  }),
  withLoadingSpinner,
  withShowFlag,
  withGraphqlErrorFlags,
  withServiceNotFoundHandler
)(ServiceDocumentationPageView);
