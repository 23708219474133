import * as React from "react";
import { SearchType } from "../routes";
import { PageLayout } from "../../../../palette/layout/page-layout";
import { DrawerContextConsumer, compose } from "@atlassiansox/microscopekit";
import * as Palette from "../../../../palette";
import { SearchV2 } from "../components/search-v2";
import { withRouter, RouteComponentProps } from "react-router-dom";
const { parse } = require("query-string");
const { ButtonGroup } = require("@atlaskit/button");

export const SearchPage = compose(withRouter)(
  class extends React.Component<RouteComponentProps<any>> {
    render() {
      const { query, page } = parse(this.props.location.search) as any;
      const { type: typeAsString } = this.props.match.params;

      const actions = (
        <DrawerContextConsumer>
          {drawerContext => (
            <ButtonGroup>
              {typeAsString === "services" && (
                <Palette.Button
                  key="Bulk Edit"
                  onClick={() =>
                    drawerContext.updateOpenDrawer("bulk-edit-drawer")
                  }
                  disabled={!query}
                >
                  Bulk Edit
                </Palette.Button>
              )}
            </ButtonGroup>
          )}
        </DrawerContextConsumer>
      );

      return (
        <PageLayout title={`Searching for ${typeAsString}`} actions={actions}>
          <SearchV2
            query={query || ""}
            page={page ? parseInt(page, 10) : 1}
            type={typeAsString as SearchType}
          />
        </PageLayout>
      );
    }
  }
);
