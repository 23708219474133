import * as React from "react";
import { Droppable, DroppableProvided } from "react-beautiful-dnd";
import styled from "styled-components";

const ColumnDiv = styled.div`
  flex: 0.333;
  flex-direction: column;
  min-width: 0;
`;
const PaddedDiv = styled.div`
  padding-bottom: 200px;
`;

interface Props {
  id: string;
}
export class Column extends React.Component<Props> {
  render() {
    return (
      <ColumnDiv>
        <Droppable droppableId={this.props.id}>
          {(provided: DroppableProvided) => (
            <PaddedDiv innerRef={provided.innerRef}>
              {this.props.children}
              {provided.placeholder}
            </PaddedDiv>
          )}
        </Droppable>
      </ColumnDiv>
    );
  }
}
