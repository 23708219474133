import * as React from "react";
import { servicePath, userUrl, categoryPath } from "../../../routes";
import styled from "styled-components";
import { ServiceForAdvancedSearch, TagForSearch } from "../models/interfaces";
import { Category } from "../../../../typings";
import {
  DynamicTable,
  Link,
  Icon,
  ChildProps
} from "@atlassiansox/microscopekit";
import { advancedSearch_search_edges } from "./__generated__/advancedSearch";
import { colors, themed } from "@atlaskit/theme";

const AkAddonIcon = require("@atlaskit/icon/glyph/addon").default;
const AkPersonIcon = require("@atlaskit/icon/glyph/person").default;
const AkChildIssuesIcon = require("@atlaskit/icon/glyph/child-issues").default;
const AkLabelIcon = require("@atlaskit/icon/glyph/label").default;
const AkToolTip = require("@atlaskit/tooltip").default;

const WaitingShadow = styled.div`
  ${(props: { isWaiting: boolean }) =>
    props.isWaiting ? "opacity: 0.3;" : ""};
`;

const DynamicTableStyled = styled.div`
  padding: 35px 0px 10px 0px;
`;

const SearchResultName = styled.span`
  background-color: #0052cc;
  color: #fff;
  border-radius: 3px;
  padding: 1px 5px;
  margin-left: 33px;
  margin-top: -15px;
`;

const ServiceName = styled.span`
  font-size: 14px;
`;

const ServiceOwner = styled.span`
  font-size: 12px;
  margin-left: -10px;
  vertical-align: top;
`;

const ServiceTag = styled.span`
  margin-left: 33px;
`;

const ServiceDescription = styled.div`
  margin-left: 33px;
`;

const Wrapper = styled.div`
  margin-left: 33px;
  margin-top: -25px;
`;

const CategoryStyled = styled.div`
  margin-top: -15px;
`;

const RowStyled = styled.div`
  padding: 10px 0px 10px 0px;
`;

const IconStyled = styled.span`
  color: ${themed({ light: colors.B400, dark: colors.B200 })};
`;

const dataTypes = ["Service", "Tag", "Category"];

type SearchResultsProps = ChildProps<{
  results: (advancedSearch_search_edges | null)[] | null; // ref:__generated__ file
  isLoading: boolean;
}>;

export class SearchResultsDisplay extends React.Component<SearchResultsProps> {
  constructor(props: SearchResultsProps) {
    super(props);
  }
  render() {
    return (
      <WaitingShadow isWaiting={this.props.isLoading}>
        <DynamicTableStyled>
          <DynamicTable
            rows={this.props.results!.map((result, index) => ({
              key: `search-results-table-${index}`,
              cells: this.renderResultRow(result!.node, index)
            }))}
          />
        </DynamicTableStyled>
      </WaitingShadow>
    );
  }

  renderResultRow(node: any, index: number) {
    if (node.__typename === dataTypes[0]) {
      return this.renderServiceRow(node, index);
    } else if (node.__typename === dataTypes[1]) {
      return this.renderTagRow(node, index);
    } else if (node.__typename === dataTypes[2]) {
      return this.renderCategoryRow(node, index);
    } else {
      return [];
    }
  }

  renderServiceRow({ id, metadata }: ServiceForAdvancedSearch, index: number) {
    const { tags, owner, description } = metadata
      ? metadata
      : {
          tags: [],
          owner: { id: "" },
          description: ""
        };

    const nameEl = (
      <AkToolTip content={`${dataTypes[0]} Name`} position="right">
        <ServiceName>
          <span key={id}>
            <Link
              to={servicePath(encodeURIComponent(id))}
              onClick={() => {
                const reciprocalRank = 1 / (index + 1);
                track("AdvancedSearchPage.Result: Click", {
                  category: "info",
                  element: "SearchService",
                  label: "reciprocal rank",
                  value: reciprocalRank
                });
              }}
            >
              <span className="search-result-name">{id}</span>
            </Link>
          </span>
        </ServiceName>
      </AkToolTip>
    );

    const ownerEl = (
      <AkToolTip content={`${dataTypes[0]} Owner`} position="right">
        <Link to={userUrl(owner.id)}>
          <Icon>
            <AkPersonIcon size="small" label="User" />
          </Icon>
          <ServiceOwner>{owner.id}</ServiceOwner>
        </Link>
      </AkToolTip>
    );

    const descriptionEl = (
      <AkToolTip content={`${dataTypes[0]} Description`} position="left">
        <ServiceDescription>{description}</ServiceDescription>
      </AkToolTip>
    );

    const toDisplay = (
      <RowStyled>
        <IconStyled>
          <Icon>
            <AkAddonIcon size="large" label="Service" />
          </Icon>
        </IconStyled>
        <Wrapper>
          {nameEl}
          {ownerEl}
        </Wrapper>
        {/* conditionals so that each block has consistent spacing */}
        {description ? descriptionEl : null}
      </RowStyled>
    );

    return [{ key: id, content: toDisplay }];
  }

  renderTagRow({ name }: TagForSearch, index: number) {
    const nameEl = (
      <AkToolTip content={`${dataTypes[1]} Name`} position="right">
        <span>
          <Link
            to={`/search?query=${encodeURIComponent(
              `type:services AND tags:${name}`
            )}`}
            className="search-tag-name"
            onClick={() => {
              window.scrollTo(0, 0);
              const reciprocalRank = 1 / (index + 1);
              track("AdvancedSearchPage.Result: Click", {
                category: "info",
                element: "SearchTag",
                label: "reciprocal rank",
                value: reciprocalRank
              });
            }}
          >
            <SearchResultName className="search-result-name">
              {name}
            </SearchResultName>
          </Link>
        </span>
      </AkToolTip>
    );

    const toDisplay = (
      <RowStyled>
        <IconStyled>
          <Icon>
            <AkLabelIcon size="large" label="Tag" />
          </Icon>
        </IconStyled>
        <CategoryStyled>{nameEl}</CategoryStyled>
      </RowStyled>
    );

    return [{ key: name, content: toDisplay }];
  }

  renderCategoryRow({ id, name, displayName }: Category, index: number) {
    const nameEl = (
      <AkToolTip content={`${dataTypes[2]} Name`} position="right">
        <span key={id}>
          <Link
            to={categoryPath(encodeURIComponent(id))}
            onClick={() => {
              const reciprocalRank = 1 / (index + 1);
              track("AdvancedSearchPage.Result: Click", {
                category: "info",
                element: "SearchCategory",
                label: "reciprocal rank",
                value: reciprocalRank
              });
            }}
          >
            <SearchResultName className="search-result-name">
              {displayName || name}
            </SearchResultName>
          </Link>
        </span>
      </AkToolTip>
    );

    const toDisplay = (
      <RowStyled>
        <IconStyled>
          <Icon>
            <AkChildIssuesIcon size="large" label="Category" />
          </Icon>
        </IconStyled>
        <CategoryStyled>{nameEl}</CategoryStyled>
      </RowStyled>
    );

    return [{ key: id, content: toDisplay }];
  }
}
