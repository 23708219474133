import * as React from "react";
import { Grid, GridColumn, Button } from "../../../palette";
import { UpdateSSAMContainerModal } from "./update-ssam-modal";
import { Group, Permission, User } from "../../../typings";
import { isSufficientPermission } from "../../../core/helpers";
const AkBanner = require("@atlaskit/banner").default;

type Props = {
  service: {
    id: string;
    currentUserPermission: Permission;
    metadata?: {
      owner: {
        id: string;
      };
      admins: (User | Group)[];
    };
  };
  callback?: () => void;
};

export class SSAMAnnouncement extends React.Component<Props> {
  state = {
    updateSSAMModalOpen: false
  };

  render() {
    return (
      <>
        <AkBanner isOpen appearance="announcement">
          <Grid>
            <GridColumn
              medium={
                isSufficientPermission(
                  this.props.service.currentUserPermission,
                  Permission.Admin
                )
                  ? 9
                  : 12
              }
            >
              This service doesn't have an SSAM Container associated with it
              yet! You will be unable to edit this service until its SSAM
              Container has been set!
              {isSufficientPermission(
                this.props.service.currentUserPermission,
                Permission.Admin
              )
                ? ""
                : " Ask the owner of the service to set the SSAM Container via Microscope."}
            </GridColumn>
            {isSufficientPermission(
              this.props.service.currentUserPermission,
              Permission.Admin
            ) && (
              <GridColumn medium={3}>
                <Button
                  appearance="primary"
                  key="SSAMUpdate"
                  onClick={() => this.setState({ updateSSAMModalOpen: true })}
                >
                  Update SSAM Container
                </Button>
              </GridColumn>
            )}
          </Grid>
        </AkBanner>
        {this.state.updateSSAMModalOpen && (
          <UpdateSSAMContainerModal
            service={this.props.service}
            onClose={() => this.setState({ updateSSAMModalOpen: false })}
            callback={this.props.callback}
          />
        )}
      </>
    );
  }
}
