import * as React from "react";
import { NavigationItem } from "../../../palette/navigation/navigation-item";
import EditorNumberListIcon from "@atlaskit/icon/glyph/editor/number-list";

export class ComplianceNavigationLinks extends React.Component<{ id: string }> {
  render() {
    return (
      <>
        <NavigationItem
          icon={<EditorNumberListIcon label="Compliance" />}
          text="Compliance"
          href={`/services/${encodeURIComponent(this.props.id)}/compliance`}
        />
      </>
    );
  }
}
