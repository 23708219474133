import * as React from "react";
import {
  UserSearchField,
  FlagProps,
  withShowFlag
} from "@atlassiansox/microscopekit";
import * as Palette from "../../../palette";
import * as Text from "../../../palette/editable/editable-text";
import { RadioGroup } from "../../../palette/radio-group";
import { checkValidName } from "./helpers";
import styled from "styled-components";
import { CustomError } from "../../../core/helpers";
import {
  compliancePolicy2,
  compliancePolicy2_questions
} from "../models/__generated__/compliancePolicy2";
import { complianceQuestion } from "../models/__generated__/complianceQuestion";
import { Omit } from "@atlaskit/type-helpers";
const AkSpinner = require("@atlaskit/spinner").default;

type CompliancePolicyFormProps = FlagProps & {
  title: string;
  questions: complianceQuestion[];
  closeModal: () => void;
  services?: string[]; // Which services this policy will be applied to or does apply to
  callback: (
    owner: string,
    name: string,
    answers: { questionID: string; answer: string }[]
  ) => Promise<compliancePolicy2>;

  // Fields for updating an old policy
  policy?: compliancePolicy2;
};

interface State {
  name: string;
  answers: { questionID: string; answer: string }[];
  nameErr?: string; // What error should be shown next to the policy name input
  answerErrs: (string | undefined)[]; // What errors should be shown beside the questions / answers
  owner: string;
  ownerErr?: string;
  makingQuery?: boolean;
}

const nameHeader = "Policy Name";
const ownerHeader = "Policy Owner";
const servicesHeader = "Affected Services";
const noAnswer = "Question needs an answer";

const TextMarginBottom = styled(Text.EditableText)`
  margin-bottom: 1em;
`;
TextMarginBottom.displayName = "Text";
const PaddedDiv = styled.div`
  padding: 0px 10px;
`;
const AffectedServicesSpan = styled.span`
  margin-top: 16px;
`;

export class CompliancePolicyFormView extends React.Component<
  CompliancePolicyFormProps,
  State
> {
  constructor(props: CompliancePolicyFormProps) {
    super(props);
    const policy = this.props.policy;
    if (policy) {
      this.state = {
        makingQuery: false,
        name: policy.name,
        owner: policy.owner2.id,
        answers: policy.questions.map(q =>
          q.answer
            ? {
                questionID: this.props.questions.find(
                  question => question.question === q.question
                )!.id,
                answer: q.answer.answer
              }
            : {
                questionID: this.props.questions.find(
                  question => question.question === q.question
                )!.id,
                answer: ""
              }
        ),
        answerErrs: Array(policy.questions.length).fill(undefined)
      };
    } else {
      // if no policy
      this.state = {
        makingQuery: false,
        name: "",
        owner: "",
        answers: this.props.questions.map(q => ({
          questionID: q.id,
          answer: ""
        })),
        answerErrs: Array(this.props.questions.length).fill(undefined)
      };
    }
  }

  updateAnswer = (index: number, id: string, answer: string) => {
    let newAnswers = this.state.answers.map(a => ({
      questionID: a.questionID,
      answer: a.questionID == id ? answer : a.answer
    }));
    // Get rid of the error associated with this question now that it has been updated
    let newErrs = this.state.answerErrs.slice();
    newErrs[index] = undefined;
    this.setState({ answers: newAnswers, answerErrs: newErrs });
  };

  updateName = (newName: string) => {
    this.setState({ name: newName, nameErr: checkValidName(newName) });
  };

  updateOwner = (newOwner: string) => {
    this.setState({ owner: newOwner, ownerErr: undefined });
  };

  // Function to call to check the form for errors before making a call to the backend
  checkForm = () => {
    let nameErr;
    let answerErrs = this.props.questions
      ? Array(this.props.questions.length).fill(undefined)
      : Array(this.props.policy!.questions.length).fill(undefined);
    // Check a name was provided
    nameErr = checkValidName(this.state.name);
    if (nameErr && nameErr !== "") {
      track("Compliance.CompliancePolicyForm: FormError", {
        category: "info",
        field: "name",
        error: nameErr
      });
    }
    // Check answers were provided
    this.state.answers.forEach((a, index) => {
      if (a.answer === "") {
        answerErrs[index] = noAnswer;
      }
    });

    if (answerErrs.find(a => a !== undefined)) {
      track("Compliance.CompliancePolicyForm: FormError", {
        category: "info",
        field: "answer",
        error: noAnswer
      });
    }
    return { nameErr, answerErrs };
  };

  // Updates the errors in the state and returns true if there are still errors
  hasErrors = () => {
    let { nameErr, answerErrs } = this.checkForm();
    this.setState({ nameErr, answerErrs });
    return nameErr || answerErrs.filter(a => a).length > 0;
  };

  finalize = () => {
    // Check the form for errors and set the state accordingly

    if (this.hasErrors()) {
      return;
    }

    this.setState({ makingQuery: true });
    this.props
      .callback(this.state.owner, this.state.name, this.state.answers)
      .then(() => {
        this.setState({ makingQuery: false });
        this.props.closeModal();
      })
      .catch(error => {
        if (error.graphQLErrors) {
          error.graphQLErrors.forEach((err: CustomError) => {
            if (
              err.message ===
              "Compliance with name " + this.state.name + " already exists"
            ) {
              this.setState({ nameErr: err.message });
            }
          });
        }
        this.setState({ makingQuery: false });
      });
  };

  render() {
    return (
      <Palette.Modal
        heading={this.props.title}
        scrollBehavior={"outside"}
        actions={[
          {
            text: this.props.policy ? "Save" : "Create",
            onClick: this.finalize,
            isDisabled: this.state.makingQuery,
            iconBefore: this.state.makingQuery ? (
              <AkSpinner size="small" isCompleting={false} />
            ) : (
              undefined
            )
          },
          {
            text: "Cancel",
            onClick: this.props.closeModal,
            isDisabled: this.state.makingQuery
          }
        ]}
        onClose={() => {
          this.props.closeModal();
        }}
      >
        {this.renderNameInput()}
        {this.props.policy ? this.renderOwnerInput() : null}
        {this.renderAppliedServices()}
        {this.props.questions
          ? this.renderQuestions(
              this.props.questions.map(q => ({
                ...q,
                answer: { id: q.id }
              })) as any
            )
          : this.renderQuestions(this.props.policy!.questions)}
      </Palette.Modal>
    );
  }

  renderNameInput = () => (
    <TextMarginBottom
      value={this.state.name}
      editMode={true}
      editValue={this.state.name}
      onChange={this.updateName}
      label={nameHeader}
      required={true}
      invalidMessage={this.state.nameErr}
    />
  );

  renderOwnerInput = () => (
    <UserSearchField
      defaultUsers={[{ username: this.state.owner }]}
      onChange={(users: any) => {
        const [user] = users;
        this.updateOwner(user);
      }}
      label={ownerHeader}
      required={true}
      displayUserInfo={(user: any) =>
        `${user.firstName || ""} ${user.lastName || ""} ${
          user.firstName || user.lastName ? "(" : ""
        }${user.username}${user.firstName || user.lastName ? ")" : ""}`
      }
    />
  );

  renderAppliedServices = () => {
    let services =
      this.props.services && this.props.services.length !== 0
        ? this.props.services.join(", ")
        : "None.";

    return (
      <AffectedServicesSpan>
        <Palette.Header className="affected-services-header">
          {servicesHeader}
        </Palette.Header>
        <PaddedDiv className="affected-services">
          {!this.props.services ? (
            <AkSpinner size="small" isCompleting={false} />
          ) : (
            services
          )}
        </PaddedDiv>
      </AffectedServicesSpan>
    );
  };

  renderQuestions = (questions: compliancePolicy2_questions[]) =>
    questions.map((q, index) => {
      const answer = this.state.answers.find(
        a => a.questionID === q!.answer!.id
      );
      return (
        <div key={index} className={"question" + q.answer!.id}>
          <RadioGroup
            invalidMessage={this.state.answerErrs[index]}
            isRequired={true}
            label={q.question}
            items={[
              {
                label: "Yes",
                value: "true",
                isSelected: answer && answer.answer === "true"
              },
              {
                label: "No",
                value: "false",
                isSelected: answer && answer.answer === "false"
              }
            ]}
            onChange={this.updateAnswer.bind(this, index, q.answer!.id)}
          />
        </div>
      );
    });
}
export const CompliancePolicyForm: React.ComponentType<Omit<
  CompliancePolicyFormProps,
  "showFlag"
>> = withShowFlag(CompliancePolicyFormView);
