import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  withDrawerContext,
  DrawerContextProps,
  compose
} from "@atlassiansox/microscopekit";
import { getIsChromeless } from "../../../helpers";

type Props = DrawerContextProps & RouteComponentProps<any>;

class RouteChangeListenerView extends React.Component<Props> {
  componentWillReceiveProps(newProps: Props) {
    if (this.props.location.pathname !== newProps.location.pathname) {
      this.props.updateOpenDrawer(null);
    }

    this.sendRouteInfoToCompass(newProps.location);
  }

  sendRouteInfoToCompass(location: Props["location"]) {
    if (!getIsChromeless()) {
      return;
    }

    window.parent?.postMessage(
      JSON.stringify({
        path: location.pathname,
        search: location.search,
        microscopeNavigation: true
      }),
      "*"
    );
  }

  render() {
    return this.props.children;
  }
}

export const RouteChangeListener = compose(
  withRouter,
  withDrawerContext
)(RouteChangeListenerView);
