import * as React from "react";
import { VerticalCenterDiv } from "./vertical-center-div";
import { InlineBlockDiv } from "./inline-block-div";
import styled from "styled-components";

const AkRadioGroup = require("@atlaskit/field-radio-group").default;
const AkTooltip = require("@atlaskit/tooltip").default;
const { TooltipPrimitive: AkTooltipPrimitive } = require("@atlaskit/tooltip");
const WarningIcon = require("@atlaskit/icon/glyph/warning").default;

export interface Options {
  label: string | JSX.Element;
  value: any;
  isSelected?: boolean;
}

export interface Props {
  className?: string;
  label?: string;
  items: Options[];
  onChange: (value: any) => void;
  isRequired?: boolean;
  invalidMessage?: string;
}

const InlineDialog = styled(AkTooltipPrimitive)`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: content-box; /* do not set this to border-box or it will break the overflow handling */
  color: #333;
  max-height: 300px;
  max-width: 300px;
  padding: 24px;
`;

const WarningDiv = styled.div`
  display: flex;
  border: solid 2px #ffab00;
  border-radius: 5px;
  padding: 5px;
`;

const IconDiv = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const AtlasKitFixRequiredAsterisk = styled.span`
  color: #ff5630;
`;

export class RadioGroup extends React.Component<Props, {}> {
  render() {
    const warningColor = "#ffab00";
    let {
      className,
      label,
      items,
      onChange,
      isRequired,
      invalidMessage
    } = this.props;
    let warningIcon = (
      <IconDiv key="icon-div">
        <VerticalCenterDiv>
          <WarningIcon
            primaryColor={warningColor}
            secondaryColor={"white"}
            size="medium"
            label="warning"
          />
        </VerticalCenterDiv>
      </IconDiv>
    );

    // Seems like `isRequired` property is not working properly on AtlasKit RadioGroup component.
    // Fixing it and setting `isRequired` attribute to false so when it will be fixed later in AtlasKit it won't break our
    // form by displaying two red asterisks.
    // Eventually this fix should be removed.
    const form = [
      <InlineBlockDiv key="radio-buttons">
        <AkRadioGroup
          label={
            <span>
              {label}{" "}
              {isRequired ? (
                <AtlasKitFixRequiredAsterisk>*</AtlasKitFixRequiredAsterisk>
              ) : (
                ""
              )}
            </span>
          }
          items={items}
          onRadioChange={(event: any) => {
            onChange(event.target.value);
          }}
          isRequired={false}
        />
      </InlineBlockDiv>,
      invalidMessage ? warningIcon : null
    ];

    return (
      <AkTooltip
        component={InlineDialog}
        content={invalidMessage}
        position={"right"}
      >
        {invalidMessage ? (
          <WarningDiv className={className}>{form}</WarningDiv>
        ) : (
          <div className={className}>{form}</div>
        )}
      </AkTooltip>
    );
  }
}
