import * as React from "react";
import { Home } from "./core/private/components/home";
import { HistoryWidget } from "./core/plugins/history-widget";
import { TeamServicesWidget } from "./plugins/team-services";
import { PluginProps } from "./app-plugins";

export class AppHome extends React.Component<PluginProps> {
  render() {
    const { plugins, devPlugin } = this.props;
    const pluginWidgets = {
      "history-widget": HistoryWidget,
      "team-services-widget": TeamServicesWidget
    } as any;

    [...plugins, devPlugin].map(plugin =>
      (plugin && plugin.widgets ? plugin.widgets : []).map(config => {
        pluginWidgets[plugin!.id + "-" + config.id] = config.component;
      })
    );

    return <Home widgets={pluginWidgets} />;
  }
}
