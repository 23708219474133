import * as React from "react";
import { compose, graphql, ChildProps } from "@atlassiansox/microscopekit";
import { ToggleStateless } from "@atlaskit/toggle";
import { PopupMenuGroup, Section, ButtonItem } from "@atlaskit/menu";
import Popup from "@atlaskit/popup";
import { Settings } from "@atlaskit/atlassian-navigation";

import updateThemeMutation from "./update-theme.mutation.graphql";
import themeQuery from "../../../../theme.query.graphql";
import { theme } from "../../../../__generated__/theme";
import styled from "styled-components";

const Container = styled.div`
  margin: 16px;
`;

export class SettingsDropdownContentsView extends React.Component<
  ChildProps<{}, theme>
> {
  render() {
    const checked = this.props?.data?.theme?.mode === "dark";
    return (
      <Container>
        <PopupMenuGroup>
          <Section>
            <h5>
              Dark Mode
              <ToggleStateless
                isChecked={checked}
                onChange={() =>
                  this.props.mutate?.({
                    variables: {
                      input: {
                        mode: checked ? "light" : "dark",
                        __typename: "UpdateThemeInput"
                      }
                    }
                  })
                }
              />
            </h5>
            <div>Switch between light and dark themes for Microscope.</div>
          </Section>
        </PopupMenuGroup>
      </Container>
    );
  }
}

const SettingsDropdownContents = compose(
  graphql(updateThemeMutation),
  graphql(themeQuery)
)(SettingsDropdownContentsView);

export class SettingsDropdown extends React.Component<{}, { isOpen: boolean }> {
  state = {
    isOpen: false
  };

  render() {
    const { isOpen } = this.state;
    return (
      <Popup
        content={() => <SettingsDropdownContents />}
        isOpen={isOpen}
        placement="bottom-start"
        onClose={() => this.setState({ isOpen: false })}
        trigger={triggerProps => (
          <Settings
            isSelected={isOpen}
            onClick={() => this.setState({ isOpen: !isOpen })}
            tooltip="Settings"
            {...triggerProps}
          />
        )}
      />
    );
  }
}
