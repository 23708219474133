// Config should not depend on any stuff other than `/private/lib`.
import assert from "assert";

const clientGitHash = process.env.REACT_APP_GIT_HASH;
const logBatchInterval = 60 * 1000;
const sshProxyUrls = new Map([
  ["pdev-apse2", "micros-iam-manager.platdev.atl-paas.net"],
  ["dev-west2", "micros-iam-manager.dev.atl-paas.net"],
  ["ddev", "micros-iam-manager.dev.atl-paas.net"],
  ["adev", "micros-iam-manager.dev.atl-paas.net"],
  ["adev-west2", "micros-iam-manager.dev.atl-paas.net"],
  ["pdev", "micros-iam-manager.dev.atl-paas.net"]
]);
const microsReferenceUrl =
  process.env.MICROS_REFERENCE_URL ||
  "https://hello.atlassian.net/wiki/spaces/MICROS/pages/167213400/Support";

interface Config {
  environment: string;
  logToConsole: boolean;
  logLevel: "debug" | "info" | "warn" | "error";
  logBatchInterval: number;
  useZipkin: boolean;
  apiUrl: string;
  ssamUrl: string;
  clientGitHash: string | undefined;
  sshProxyUrls: Map<string, string>;
  microsReferenceUrl: string;
}

const configs: { [key: string]: Config } = {};

configs["prod-west2"] = {
  environment: "prod-west2",
  logToConsole: true,
  logLevel: "error",
  useZipkin: true,
  apiUrl: "https://manager-service.us-west-2.prod.atl-paas.net",
  ssamUrl: "https://ssam.us-east-1.prod.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["stg-apse"] = {
  environment: "stg-apse",
  apiUrl: "https://manager-service.ap-southeast-1.staging.atl-paas.net",
  logToConsole: true,
  logLevel: "warn",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["stg-west2"] = {
  environment: "stg-west2",
  apiUrl: "https://manager-service.us-west-2.staging.atl-paas.net",
  logToConsole: true,
  logLevel: "warn",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["adev-west2"] = {
  environment: "adev-west2",
  apiUrl: "https://manager-service--app.us-west-2.dev.atl-paas.net",
  logToConsole: true,
  logLevel: "info",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["dev-west2"] = {
  environment: "dev-west2",
  apiUrl: "https://manager-service.us-west-2.dev.atl-paas.net",
  logToConsole: true,
  logLevel: "info",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["adev"] = {
  environment: "adev",
  apiUrl: "https://manager-service--app.ap-southeast-2.dev.atl-paas.net",
  logToConsole: true,
  logLevel: "info",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["ddev"] = {
  environment: "ddev",
  apiUrl: "https://manager-service.ap-southeast-2.dev.atl-paas.net",
  logToConsole: true,
  logLevel: "info",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["pdev"] = {
  environment: "pdev",
  apiUrl: "https://manager-service--platform.ap-southeast-2.dev.atl-paas.net",
  logToConsole: true,
  logLevel: "info",
  useZipkin: true,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["local"] = {
  environment: "local",
  apiUrl: "http://localhost:8080", // so the devServer can inject slauth headers prior to proxying requests
  logToConsole: true,
  logLevel: "info",
  useZipkin: false,
  ssamUrl: "https://ssam.ap-southeast-2.dev.apd-paas.net",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

configs["test"] = {
  environment: "test",
  apiUrl: "",
  logToConsole: true,
  logLevel: "info",
  useZipkin: false,
  ssamUrl: "",
  clientGitHash,
  logBatchInterval,
  sshProxyUrls,
  microsReferenceUrl
};

let environment = process.env.REACT_APP_ENV || "local";
assert(environment in configs, `no config for environment ${environment}`);
export const config = configs[environment];
export default config;
