import { withClientState } from "apollo-link-state";
import { InMemoryCache } from "apollo-cache-inmemory";
import { reduce, merge, isArray, mergeWith } from "lodash";
import { dashboardDefaults, dashboardResolvers } from "./dashboard";
import { navigationDefaults, navigationResolvers } from "./navigation";
import { themeDefaults, themeResolvers } from "./theme";

function mergeWithConcat(objValue: any, srcValue: any) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

export function createLocalLink(cache: InMemoryCache) {
  return withClientState({
    cache,
    defaults: reduce(
      [dashboardDefaults, navigationDefaults, themeDefaults],
      (left, right) => mergeWith(right, left, mergeWithConcat)
    ),
    resolvers: reduce(
      [dashboardResolvers, navigationResolvers, themeResolvers],
      merge
    )
  });
}
