/**
 * Palette of UI elements.
 *
 * Wrappers around AtlasKit components with simpler API and TypeScript typing.
 * Feel free to use it or AtlasKit directly or mix two.
 */
import * as React from "react";
import { graphql, compose, MutationFunc } from "@atlassiansox/microscopekit";
import { Link as RLink } from "react-router-dom";
import { logger } from "../core";
import styled, { StyledFunction, ThemeProvider } from "styled-components";
import { NoWrapWhiteSpace } from "./nowrap-white-space";
import { GrayText } from "./gray-text";
import { ClearDiv } from "./clear";
import { AnyComponent } from "../core/helpers";
import clearNavigationLevelsMutation from "./clear-navigation-levels.mutation.graphql";

const { Grid: AkGrid, GridColumn: AkGridColumn } = require("@atlaskit/page");
const { StatelessTabs: AkTabsStateless } = require("@atlaskit/tabs");
const AkTabs = require("@atlaskit/tabs").default;
const {
  default: AkDropdownMenu,
  DropdownItemGroup: AkDropdownItemGroup,
  DropdownItem: AkDropdownItem
} = require("@atlaskit/dropdown-menu");
const AkButton = require("@atlaskit/button").default;
const { ButtonGroup: AkButtonGroup } = require("@atlaskit/button");
const AkSpinner = require("@atlaskit/spinner").default;
const AkModalDialog = require("@atlaskit/modal-dialog").default;
const AkBadge = require("@atlaskit/badge").default;

export * from "./form";

const ErrorDiv = styled.div`
  background-color: #bf2600;
  color: white;
  margin-top: 8rem;
  width: 25rem;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 2rem 4rem;
`;
const TripleIconDiv = styled.div`
  float: left;
  margin-right: 0.5rem;
`;
const TripleDetailsDiv = styled.div`
  float: left;
  > * {
    display: block;
  }
`;

export function confirm(message: string) {
  return ((window as any).confirmStub || (window as any).confirm)(message);
}

// Grid
export interface GridProps {
  spacing?: string;
  layout?: string;
}

export const Grid: React.StatelessComponent<GridProps> = ({
  children,
  spacing,
  layout
}) => (
  <ThemeProvider theme={{}}>
    <AkGrid spacing={spacing} layout={layout}>
      {children}
    </AkGrid>
  </ThemeProvider>
);

export interface GridColumnProps {
  medium: number;
}

export const GridColumn: React.StatelessComponent<GridColumnProps> = ({
  children,
  medium
}) => <AkGridColumn medium={medium}>{children}</AkGridColumn>;

// Modal
export interface ModalProps {
  className?: string;
  header?: AnyComponent;
  heading?: JSX.Element | string;
  footer?: AnyComponent;
  actions?: ModalAction[];
  onClose?: () => void;
  width?: string;
  scrollBehavior?: string;
}

export interface ModalAction {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  iconBefore?: JSX.Element;
}

export class Modal extends React.Component<ModalProps> {
  render() {
    const {
      header,
      footer,
      heading,
      actions,
      onClose,
      children,
      width,
      scrollBehavior
    } = this.props;

    return (
      <AkModalDialog
        heading={heading}
        actions={actions}
        header={header}
        footer={footer}
        isOpen
        onClose={onClose}
        onDialogDismissed={onClose}
        width={width}
        scrollBehavior={scrollBehavior}
      >
        {children}
      </AkModalDialog>
    );
  }
}

// Triple - title, subtitle, icon
export interface TripleProps {
  link?: string;
  icon: JSX.Element;
  title: string;
  subtitle: string;
}

export const Triple: React.StatelessComponent<TripleProps> = ({
  title,
  subtitle,
  icon,
  link
}) => {
  if (link) {
    return (
      <div className="ptriple">
        <TripleIconDiv>
          <Link to={link}>{icon}</Link>
        </TripleIconDiv>
        <TripleDetailsDiv className="ptriple-details">
          <Link className="ptriple-main" to={link}>
            {title}
          </Link>
          <GrayText>{subtitle}</GrayText>
        </TripleDetailsDiv>
        <ClearDiv />
      </div>
    );
  } else {
    return (
      <div className="ptriple">
        <TripleIconDiv>{icon}</TripleIconDiv>
        <TripleDetailsDiv className="ptriple-details">
          <span className="ptriple-main">{title}</span>
          <GrayText>{subtitle}</GrayText>
        </TripleDetailsDiv>
        <ClearDiv />
      </div>
    );
  }
};

// Loading wrapper, handles loading for GraphQL components
// TODO rename to LoadAndCatchErrors. And add render-time error interception, currently it would catch only
// date fetching errors.
export function Loading<P extends { data: D }, D>(
  Wrapped: React.ComponentClass<P> | React.StatelessComponent<P>,
  onData?: (data: D) => void,
  onError?: (error: any) => void
): React.StatelessComponent<P> {
  return ((props: any) => {
    let data = props.data;
    if (data.loading) {
      return <Spinner />;
    }
    if (data.error != null) {
      if (onError) {
        // Render supposed to be a pure function and should not have any side effect or update any state.
        // But because of how Apollo GraphQL implemented there seems to be no other way to get state out of it.
        process.nextTick(() => onError(data.error));
      }

      track("error loading page", {
        category: "error",
        url: "" + window.location
      });
      logger.error(data.error);
      return (
        <ErrorDiv>{data.error.message || "Unknown server error"}</ErrorDiv>
      );
    }

    if (onData) {
      // Render supposed to be a pure function and should not have any side effect or update any state.
      // But because of how Apollo GraphQL implemented there seems to be no other way to get state out of it.
      process.nextTick(() => onData(data));
    }

    let newProps: any = { ...props };
    delete newProps.data;
    let AnyWrapped = Wrapped as any;
    return <AnyWrapped {...newProps} data={data} />;
  }) as React.StatelessComponent<P>;
}

// Link
export interface LinkProps {
  className?: string;
  isTag?: boolean;
  title?: string;
  icon?: JSX.Element;
  to: string;
  isSelected?: () => boolean;
  target?: string;
  onClick?: (e: any) => void;
  clearNavigationLevels?: MutationFunc;
}

export class LinkView extends React.Component<LinkProps> {
  onClick = (event: any) => {
    this.props.clearNavigationLevels!();
    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    const { title, children, to, target, className } = this.props;
    if (target) {
      return (
        <a
          href={to}
          target={target}
          onClick={this.onClick}
          className={className}
        >
          {title || children}
        </a>
      );
    }
    return (
      <RLink className={className} to={to} onClick={this.onClick}>
        {title || children}
      </RLink>
    );
  }
}

export const Link = compose(
  graphql(clearNavigationLevelsMutation, {
    name: "clearNavigationLevels"
  })
)(LinkView);
Link.displayName = "Link";

const SpinnerSpan = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
`;
const InnerSpinnerSpan = styled.span`
  position: relative;
  top: -10px;
  left: -50%;
`;
export interface SpinnerProps {
  className?: string;
  size?: string;
}

// Spinner
export class Spinner extends React.Component<SpinnerProps> {
  render() {
    const { className, size } = this.props;
    return (
      <SpinnerSpan className="pspinner">
        <InnerSpinnerSpan className="pspinner-inner">
          <AkSpinner
            className={className}
            size={size ? size : "small"}
            isCompleting={false}
          />
        </InnerSpinnerSpan>
      </SpinnerSpan>
    );
  }
}

// Button
const ButtonInner = styled.span`
  position: relative;
`;
export interface ButtonProps {
  className?: string;
  onClick?: (event: any) => void;
  appearance?:
    | "default"
    | "danger"
    | "link"
    | "primary"
    | "subtle"
    | "subtle-link"
    | "warning"
    | "help";
  shouldFitContainer?: boolean;
  disabled?: boolean;
  waiting?: boolean;
  type?: "submit";
  spacing?: "compact" | "default" | "none";
  iconBefore?: any;
}

export class Button extends React.Component<ButtonProps> {
  render() {
    const {
      onClick,
      appearance,
      disabled,
      waiting,
      children,
      type,
      spacing,
      className,
      iconBefore,
      shouldFitContainer
    } = this.props;

    return (
      <AkButtonGroup>
        <AkButton
          spacing={spacing}
          appearance={waiting ? null : appearance}
          onClick={onClick}
          isDisabled={disabled || waiting}
          type={type}
          className={className}
          iconBefore={iconBefore}
          shouldFitContainer={shouldFitContainer}
        >
          <ButtonInner>{children}</ButtonInner>
        </AkButton>
      </AkButtonGroup>
    );
  }
}

export interface AkButtonProps {
  appearance?: string;
  children?: JSX.Element;
  className?: string;
  component?: JSX.Element;
  href?: string;
  iconAfter?: JSX.Element;
  iconBefore?: JSX.Element;
  id?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: (event: any) => void;
  spacing?: "compact" | "default" | "none";
  target?: string;
  type?: "button" | "submit";
  shouldFitContainer?: boolean;
}

// DropDown
export interface DropDownProps {
  values: string[];
  value?: string;
  onSelect?: (item: string) => void;
  shouldFlip?: boolean;
  position?: string;
  triggerButtonProps?: AkButtonProps;
  trigger?: JSX.Element | string;
}

export const Dropdown: React.StatelessComponent<DropDownProps> = ({
  children,
  values,
  value,
  onSelect,
  shouldFlip,
  position,
  triggerButtonProps,
  trigger
}) => {
  let akitems = [
    { items: values.filter(v => v !== value).map(v => ({ content: v })) }
  ];
  const onItemActivated = (item: any) => {
    if (onSelect) {
      onSelect(item["item"]["content"]);
    }
  };

  return (
    <AkDropdownMenu
      onItemActivated={onItemActivated}
      triggerType="button"
      shouldFlip={shouldFlip}
      position={position}
      triggerButtonProps={triggerButtonProps}
      trigger={trigger}
      items={values.length ? akitems : undefined}
    >
      {children || value || ""}
    </AkDropdownMenu>
  );
};

export const DropdownItemGroup = AkDropdownItemGroup;

export interface DropdownItemProps {
  onClick(): void;
}

export const DropdownItem: React.StatelessComponent<DropdownItemProps> = ({
  children,
  onClick
}) => <AkDropdownItem onClick={onClick}>{children}</AkDropdownItem>;

// Property - name / value property.
const PropertiesDiv = styled.div`
  h4 {
    margin-bottom: 0.5em;
  }
  tbody {
    border-bottom: 0;
  }
`;
const PropertiesValue = styled.span`
  display: inline-block;
  overflow: hidden;
`;
const PropertyKey = styled.td`
  width: 1%;
  white-space: nowrap;
  color: #6c798f;
  font-size: 0.9em;
  vertical-align: top;
  padding-top: 5px;
`;
const PropertyDiv = styled.div`
  margin-bottom: 1em;
  : last-child {
    margin-bottom: 0;
  }
`;
const PropertyName = styled.div`
  color: #6c798f;
  font-size: 0.9em;
  white-space: nowrap;
`;
const PropertyGrid = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PrimaryProps = styled.div`
  width: 60%;
`;
const SecondaryProps = styled.div`
  width: 40%;
`;
const PropertyValue = styled.div`
  white-space: nowrap;
`;
export interface PropertyProps {
  name?: string;
  value: string | number | JSX.Element;
}

export const Property: React.StatelessComponent<PropertyProps> = ({
  name,
  value
}) => (
  <PropertyDiv className="pproperty">
    {name ? (
      <PropertyName className="pproperty-name">{name}:</PropertyName>
    ) : null}
    <PropertyValue className="pproperty-value">{value}</PropertyValue>
  </PropertyDiv>
);

// Properties display bunch of name / value properties in one (primary) or two (primary and secondary) columns.
export type SimplifiedPropertyProps = [string, string | JSX.Element | number];

export interface PropertiesProps {
  title?: string;
  secondaryTitle?: string;
  properties?: (PropertyProps | SimplifiedPropertyProps)[];
  secondaryProperties?: (PropertyProps | SimplifiedPropertyProps)[];
  className?: string;
}

export const Properties: React.StatelessComponent<PropertiesProps> = ({
  title,
  secondaryTitle,
  properties,
  secondaryProperties,
  className
}) => {
  const normalize = (
    v: PropertyProps | SimplifiedPropertyProps
  ): PropertyProps => (v instanceof Array ? { name: v[0], value: v[1] } : v);

  const table = (
    properties: (PropertyProps | SimplifiedPropertyProps)[],
    className: string,
    title: string,
    showTitle: boolean
  ) => (
    <table className={className}>
      <tbody>
        {showTitle ? (
          <tr>
            <td colSpan={2}>
              <h4>{title}</h4>
            </td>
          </tr>
        ) : null}
        {properties.map((props, i) => {
          let { name, value } = normalize(props);
          if (name) {
            return (
              <tr key={i}>
                <PropertyKey className="pproperties-key">{name}:</PropertyKey>
                <NoWrapWhiteSpace>
                  <td className="pproperties-value">
                    <PropertiesValue>{value}</PropertiesValue>
                  </td>
                </NoWrapWhiteSpace>
              </tr>
            );
          } else {
            return (
              <tr key={i}>
                <NoWrapWhiteSpace>
                  <td colSpan={2} className="pproperties-value">
                    <PropertiesValue>{value}</PropertiesValue>
                  </td>
                </NoWrapWhiteSpace>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );

  let hasSecondary = (secondaryProperties || []).length > 0;
  return (
    <PropertiesDiv className={"pproperties " + (className || "")}>
      {title && !secondaryTitle ? <h4>{title}</h4> : null}
      <PropertyGrid className="pproperties-grid">
        <PrimaryProps className={hasSecondary ? "pproperties-primary" : ""}>
          {table(
            properties || [],
            "",
            title || "",
            !!(title && secondaryTitle)
          )}
        </PrimaryProps>
        {hasSecondary ? (
          <SecondaryProps className="pproperties-secondary">
            {table(
              secondaryProperties || [],
              "right",
              secondaryTitle || "",
              !!(title && secondaryTitle)
            )}
            <ClearDiv />
          </SecondaryProps>
        ) : null}
      </PropertyGrid>
    </PropertiesDiv>
  );
};

// Table
const TableDiv = styled.div`
  h4 {
    margin-bottom: 0.5em;
  }
  tr {
    border-bottom: 1px solid #e6e8ec;
  }
  tr:last-child {
    border-bottom: 0px;
  }
  td {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  th,
  td {
    white-space: nowrap;
  }
  tbody {
    border-bottom: 0;
  }
`;

const TableCellFunc: StyledFunction<{
  cellType?: TableFormat[];
} & React.HTMLProps<HTMLInputElement>> = styled.td as StyledFunction<
  { cellType?: TableFormat[] } & React.HTMLProps<HTMLInputElement>
>;
const TableCell = TableCellFunc`
  ${props =>
    props.cellType && props.cellType.includes("small") ? "width: 1px;" : ""};
  ${props =>
    props.cellType && props.cellType.includes("none")
      ? `
  &&{
    width: 0px;
    padding: 0;
  }`
      : ""}
  ${props =>
    props.cellType && props.cellType.includes("big") ? "width: 99%;" : ""};
`;
const CenterHeadsFunc: StyledFunction<{
  center?: boolean;
  cellType?: TableFormat[];
} & React.HTMLProps<HTMLInputElement>> = styled.th as StyledFunction<
  { center?: boolean; cellType?: TableFormat[] } & React.HTMLProps<
    HTMLInputElement
  >
>;
export const CenterHeads = CenterHeadsFunc`
  ${props =>
    props.cellType && props.cellType.includes("center")
      ? `text-align: center;`
      : ``};
  ${props =>
    props.cellType && props.cellType.includes("small") ? "width: 1px;" : ""};
  ${props =>
    props.cellType && props.cellType.includes("none")
      ? `
  &&{
    width: 0px;
    padding: 0;
  }`
      : ""}
`;
CenterHeads.displayName = "CenterHeads";

export type TableFormat =
  | "left"
  | "right"
  | "center"
  | "big"
  | "small"
  | "none"
  | "top"
  | "default";

export interface TableProps {
  title?: string;
  head?: (JSX.Element | string)[];
  format?: (TableFormat | TableFormat[])[];
  cells: (JSX.Element | string | null)[][];
  emptyMessage?: string;
  className?: string;
}

export class Table extends React.Component<TableProps> {
  render() {
    const { title, head, format, cells, emptyMessage, className } = this.props;
    function formats(i: number) {
      let formatI: TableFormat | TableFormat[] = (format || [])[i] || "left";
      return formatI instanceof Array ? formatI : [formatI];
    }

    function formatsToCss(i: number) {
      return formats(i)
        .map(v => `ptable-${v}`)
        .join(" ");
    }

    return (
      <TableDiv className={"ptable " + className}>
        {title ? <h4>{title}</h4> : null}
        {cells.length > 0 ? (
          <table>
            {head ? (
              <thead>
                <tr>
                  {head.map((text, j) => (
                    <CenterHeads
                      cellType={formats(j)}
                      key={`header ${j}`}
                      className={formatsToCss(j)}
                    >
                      {text}
                    </CenterHeads>
                  ))}
                </tr>
              </thead>
            ) : null}
            <tbody>
              {cells.map((row, i) => (
                <tr key={`row ${i}`}>
                  {row.map((cell, j) => (
                    <TableCell
                      cellType={formats(j)}
                      key={`cell ${i}:${j}`}
                      className={formatsToCss(j)}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {cells.length === 0 ? (
          <div>
            <br />
            <GrayText bold>{emptyMessage}</GrayText>
          </div>
        ) : null}
      </TableDiv>
    );
  }
}

// Controls
const ControlButtons = styled.div`
  ${(props: { right?: boolean }) => (props.right ? `float: right;` : ``)};
  > *:first-child {
    margin-left: 0;
  }
  > * {
    margin-left: 0.5em;
  }
`;
export interface ControlsProps {
  controls?: JSX.Element[];
  secondaryControls?: JSX.Element[];
}

export const Controls: React.StatelessComponent<ControlsProps> = ({
  controls,
  secondaryControls
}) => (
  <div className="pcontrols">
    <ControlButtons right>
      {(secondaryControls || []).map((control, i) => (
        <span key={i}>{control}</span>
      ))}
    </ControlButtons>
    <ControlButtons>
      {(controls || []).map((control, i) => (
        <span key={i}>{control}</span>
      ))}
    </ControlButtons>
    <ClearDiv />
  </div>
);

// Display error
export function showError(error: any) {
  logger.error(error);
  alert((error || {}).message || "Unknown error");
}

// Tabs
const TabDiv = styled.div`
  width: 100%;
`;

export interface TabItem {
  label: JSX.Element | string;
  content: JSX.Element;
  defaultSelected?: boolean;
  isSelected?: boolean;
  key?: string;
  onSelect?: (label: string, index: number) => void;
}
export interface TabsProps {
  tabs: TabItem[];
  onSelect?: (label: string, index: number) => void;
}
export const Tabs: React.StatelessComponent<TabsProps> = ({
  tabs,
  onSelect
}) => {
  let useStatelessTabs = false;

  const tabProps: TabItem[] = tabs.map(
    ({ label, content, defaultSelected, isSelected }, index) => {
      let tab: TabItem = {
        label,
        content,
        defaultSelected,
        isSelected
      };

      if (typeof isSelected === "boolean") {
        useStatelessTabs = true;
      }

      if (typeof label === "string") {
        tab.label = (
          <span className="ptab" data-label={label}>
            {label}
          </span>
        );
        tab.content = (
          <TabDiv className="ptab-inner" data-label={label}>
            {content}
          </TabDiv>
        );
        tab.key = label;
        tab.onSelect = () => {
          if (onSelect) {
            onSelect(label, index);
          }
        };
      }

      return tab;
    }
  );

  if (useStatelessTabs) {
    return (
      <AkTabsStateless
        tabs={tabProps}
        onSelect={
          onSelect
            ? (index: number) => onSelect(tabProps[index].key + "", index)
            : undefined
        }
        onKeyboardNav={() => {}}
      />
    );
  }

  return (
    <AkTabs
      tabs={tabProps}
      onSelect={
        onSelect
          ? (index: number) => onSelect(tabProps[index].key + "", index)
          : undefined
      }
      onKeyboardNav={() => {}}
    />
  );
};

// Badge
export interface BadgeProps {
  value?: number; // Deprecated remove it
  max?: number;
  appearance?:
    | "default"
    | "primary"
    | "primaryInverted"
    | "important"
    | "added"
    | "removed";
}

export const Badge: React.StatelessComponent<BadgeProps> = ({
  value,
  max,
  appearance,
  children
}) => (
  <AkBadge
    value={children || value}
    max={max}
    appearance={appearance ? appearance : "default"}
  />
);
Badge.displayName = "Badge";

const IconOuterWrapper = styled.span`
  position: relative;
  height: 1rem;
  width: 1.3rem;
  display: inline-block;
`;

const IconInnerWrapper = styled.span`
  position: absolute;
  top: -1px;
  right: -2px;
  margin-right: 5px;
  display: block;
  width: 24px;
  height: 24px;
`;

// Because AtlasKit icons are broken and don't align itself properly
export const Icon: React.StatelessComponent<{}> = ({ children }) => (
  <IconOuterWrapper>
    <IconInnerWrapper>{children}</IconInnerWrapper>
  </IconOuterWrapper>
);

const MessageDivFunc: StyledFunction<{
  appearance: "error" | "success";
} & React.HTMLProps<HTMLInputElement>> = styled.div as StyledFunction<
  { appearance: "error" | "success" } & React.HTMLProps<HTMLInputElement>
>;
const MessageDiv = MessageDivFunc`
  padding: 3px 3px;
  border-radius: 3px;
  margin-bottom: 2rem;
  display: inline-block;
  background-color: ${props =>
    props.appearance === "error" ? `#DE350B` : `#00875A`};
`;
const MessageBody = styled.div`
  padding: 0.5rem 1rem;
  background-color: #fff;
`;
const MessageHeader = styled.h4`
  padding: 0.5rem 1rem;
  color: #fff;
`;
export interface MessageProps {
  appearance: "error" | "success";
  title: string;
}

export const Message: React.StatelessComponent<MessageProps> = ({
  title,
  appearance,
  children
}) => (
  <MessageDiv appearance={appearance}>
    <MessageHeader>{title}</MessageHeader>
    <MessageBody>{children}</MessageBody>
  </MessageDiv>
);
Message.displayName = "Message";

export { DynamicTable } from "./dynamic-table";

// Styled Components
export { BoldSpan } from "./bold-span";
export { ClearDiv } from "./clear";
export { Code } from "./code";
export { ColoredParagraph } from "./colored-paragraph";
export { FlexDiv, SmallFlexDiv } from "./flex-div";
export { FloatRightDiv } from "./float-right-div";
export { GrayText } from "./gray-text";
export { H1 } from "./h1";
export { Header } from "./header";
export { HeightDiv } from "./height-div";
export { HiddenSpan } from "./hidden-span";
export { InlineBlockDiv } from "./inline-block-div";
export { LeftAlignDiv } from "./left-align-div";
export { MarginLeftSpan } from "./margin-left-span";
export { NoWrapWhiteSpace } from "./nowrap-white-space";
export { PageLayout } from "./layout/page-layout";
export { RightDiv } from "./right-div";
export { Separator } from "./separator";
export { StandardDiv } from "./standard-div";
export { TextAlignCenterDiv } from "./text-align-center-div";
export { VerticalCenterDiv } from "./vertical-center-div";
export { VerticalTopDiv } from "./vertical-top-div";
export { EditableText } from "./editable/editable-text";
export { withLoadingSpinner } from "./with-loading-spinner";
export { withGraphqlErrorFlags } from "./with-graphql-error-flags";

export * from "./editable";
export * from "./layout";
