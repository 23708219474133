import * as React from "react";
import { PageLayout } from "../../../../palette/layout/page-layout";

import { withRouter, RouteComponentProps } from "react-router-dom";

import { createService_createServiceV2 } from "../models/__generated__/createService";
import {
  withDrawerContext,
  DrawerContextProps,
  CustomDrawer,
  FlagProps,
  compose,
  ChildProps,
  MutationFunc,
  withShowFlag
} from "@atlassiansox/microscopekit";
import { CreateServiceForm } from "./create-service-form";

type Props = RouteComponentProps<any> &
  ChildProps<FlagProps & DrawerContextProps>;

export class CreateServiceDrawerView extends React.Component<Props> {
  onCreateSuccess = (result: createService_createServiceV2) => {
    this.props.showFlag({
      type: "success",
      title: "Success",
      message: "Service successfully created."
    });
    this.props.history.push(
      `/services/${result.id ? encodeURIComponent(result.id) : ""}`
    );
    this.props.updateOpenDrawer(null);
  };

  render() {
    return (
      <CustomDrawer id="create-service">
        <PageLayout title="Create a service">
          <CreateServiceForm onCreateSuccess={this.onCreateSuccess} />
        </PageLayout>
      </CustomDrawer>
    );
  }
}

export const CreateServiceDrawer = compose(
  withDrawerContext,
  withShowFlag,
  withRouter
)(CreateServiceDrawerView);
