import React, { useState, useEffect, useMemo } from "react";
import {
  Route,
  Switch,
  Redirect,
  RouteComponentProps,
  withRouter
} from "react-router-dom";
import { SearchRoute } from "./core/plugins/search";
import {
  ServiceDocumentationPage,
  ServiceDependenciesPage
} from "./plugins/service-central";
import { AppNavigation } from "./app-navigation";
import { ServiceRouteListener } from "./core/private/components/navigation/service-route-listener";
import { ServicePolicy } from "./plugins/compliance";
import { AppHome } from "./app-home";

import { NotFoundPage } from "./core/private/components/not-found/not-found-page";
import { PluginProps } from "./app-plugins";
import { AdvancedSearch } from "./core/plugins/search/pages/advanced-search-page";
import { compose, Spinner } from "@atlassiansox/microscopekit";
import styled from "styled-components";
import {
  checkCompassMigrationStatus,
  CompassMigrationStatusType,
  enableCompassMigration,
  getCompassEndpoint,
  getIsChromeless
} from "./core/helpers";
import { CreateService } from "./create-service";
import { getCurrentUser } from "./core";

(Switch as any).displayName = "Switch";
(Route as any).displayName = "Route";

const ChromelessWrapper = styled.div`
  h1:first-of-type {
    visibility: ${() => (getIsChromeless() ? "hidden" : "visible")};
  }
`;

const PluginWrapper: React.FC = ({ children }) =>
  getIsChromeless() ? (
    <ChromelessWrapper>{children}</ChromelessWrapper>
  ) : (
    <>{children}</>
  );

export class ServiceRoutes extends React.Component<
  RouteComponentProps<any> & PluginProps
> {
  render() {
    const {
      match: {
        params: { id: encodedId },
        path
      },
      devPlugin,
      plugins
    } = this.props;

    const id = decodeURIComponent(encodedId);
    return (
      <ServiceRouteListener id={id}>
        <Switch>
          {/* Service plugin routes go here */}
          {devPlugin &&
            devPlugin.serviceContainer &&
            devPlugin.serviceContainer.routes.map(route => (
              <Route
                exact
                path={`${path}/${route.path}`}
                render={() => (
                  <PluginWrapper>
                    <route.component id={id} />
                  </PluginWrapper>
                )}
              />
            ))}

          {plugins.map(
            plugin =>
              plugin &&
              plugin.serviceContainer &&
              plugin.serviceContainer.routes.map(route => (
                <Route
                  exact
                  path={`${path}/${route.path}`}
                  render={() => (
                    <PluginWrapper>
                      <route.component id={id} />
                    </PluginWrapper>
                  )}
                />
              ))
          )}

          <Route
            exact
            path={`${path}/documentation`}
            render={() => (
              <PluginWrapper>
                <ServiceDocumentationPage id={id} />
              </PluginWrapper>
            )}
          />
          <Route
            exact
            path={`${path}/dependencies`}
            render={() => (
              <PluginWrapper>
                <ServiceDependenciesPage id={id} />
              </PluginWrapper>
            )}
          />
          <Route
            exact
            path={`${path}/compliance`}
            render={() => (
              <PluginWrapper>
                <ServicePolicy id={id} />
              </PluginWrapper>
            )}
          />

          {/* 404 route */}
          <Route path={`${path}/`} component={NotFoundPage} />
        </Switch>
      </ServiceRouteListener>
    );
  }
}

export class CategoryRoutes extends React.PureComponent<
  RouteComponentProps<any> & PluginProps
> {
  render() {
    const {
      match: {
        params: { id: encodedId },
        path
      },
      devPlugin,
      plugins
    } = this.props;
    const id = decodeURIComponent(encodedId);
    return (
      <Switch>
        {/* Category plugin routes go here */}
        {devPlugin &&
          devPlugin.categoryContainer &&
          devPlugin.categoryContainer.routes.map(route => (
            <Route
              exact
              path={`${path}/${route.path}`}
              render={() => <route.component id={id} />}
            />
          ))}

        {plugins.map(
          plugin =>
            plugin &&
            plugin.categoryContainer &&
            plugin.categoryContainer.routes.map(route => (
              <Route
                exact
                path={`${path}/${route.path}`}
                render={() => <route.component id={id} />}
              />
            ))
        )}

        {/* 404 route */}
        <Route path={`${path}/`} component={NotFoundPage} />
      </Switch>
    );
  }
}

// The poor dev's FF solution.
const disableAutoOptInForUsers = [
  "pshkleinik",
  "resilience-team-bot",
  "nhiggins",
  "dc-polli-bot",
  "micros-sc-bot",
  "psre-pollinator-bot",
  "cledezma",
  "khuang"
];

type AppRoutesViewProps = PluginProps & RouteComponentProps;

const AppRoutesView: React.FC<AppRoutesViewProps> = props => {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMigrationStatus, setLoadingMigrationStatus] = useState<boolean>(
    true
  );
  const [compassMigrationStatus, setCompassMigrationStatus] = useState<
    CompassMigrationStatusType
  >();

  const currentUser = useMemo(() => getCurrentUser(), []);
  const autoOptInEnabled = !disableAutoOptInForUsers.includes(
    currentUser.username
  );

  useEffect(() => {
    checkCompassMigrationStatus().then(s => {
      setCompassMigrationStatus(s);
      setLoadingMigrationStatus(false);
    });
  }, []);

  const tryToGoToCompass = async () => {
    if (process.env.NODE_ENV === "development") {
      return setLoading(false);
    }

    if (getIsChromeless()) {
      return setLoading(false);
    }

    if (
      ["/search", "/categories", "/category"].some(route =>
        props.location.pathname.startsWith(route)
      )
    ) {
      return setLoading(false);
    }

    // todo point ot advanced search page
    if (props.location.pathname.startsWith("/search")) {
      return;
    }

    const endpoint = await getCompassEndpoint();

    // todo handle microscope panels differently?
    if (
      props.location.pathname.startsWith("/") ||
      props.location.pathname.startsWith("/services/")
    ) {
      window.location.href = endpoint;
      return;
    }
  };

  useEffect(() => {
    if (loadingMigrationStatus) {
      return;
    }

    if (!autoOptInEnabled) {
      return setLoading(false);
    }

    if (compassMigrationStatus === "opted-out") {
      return setLoading(false);
    }

    if (compassMigrationStatus === "opted-in") {
      tryToGoToCompass();
      return;
    }

    enableCompassMigration()
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        window.location.reload();
      });
  }, [loadingMigrationStatus, autoOptInEnabled]);

  const { plugins, devPlugin } = props;
  if (loading) {
    return <Spinner />;
  }

  return (
    <AppNavigation
      plugins={plugins}
      devPlugin={devPlugin}
      content={
        <Switch>
          <Route
            path="/services/:id"
            render={props => (
              <ServiceRoutes
                {...props}
                plugins={plugins}
                devPlugin={devPlugin}
              />
            )}
          />
          <Route
            path="/category/:id"
            render={props => (
              <CategoryRoutes
                {...props}
                plugins={plugins}
                devPlugin={devPlugin}
              />
            )}
          />

          <Route
            path="/categories/:id"
            render={props => (
              <CategoryRoutes
                {...props}
                plugins={plugins}
                devPlugin={devPlugin}
              />
            )}
          />

          <Route
            render={() => (
              <Switch>
                {/* Top level context routes go here */}
                <Route
                  exact
                  path="/"
                  render={() => (
                    <AppHome plugins={plugins} devPlugin={devPlugin} />
                  )}
                />
                <Route exact path="/search" component={AdvancedSearch} />
                <Route exact path="/create" component={CreateService} />
                <Route exact path="/search/:type" component={SearchRoute} />
                <Redirect exact from="/services" to="/" />

                {/* 404 route */}
                <Route path="/" component={NotFoundPage} />
              </Switch>
            )}
          />
        </Switch>
      }
    />
  );
};

export const AppRoutes = compose(withRouter)(AppRoutesView);
