import * as React from "react";
import { Navigation } from "./core/private/components/navigation/navigation";
import {
  ServiceCentralCategoryLinks,
  ServiceCentralNavigationLinks
} from "./plugins/service-central";

import { CreateServiceDrawer } from "./core/plugins/create-service/pages/create-service-drawer";
import { ComplianceNavigationLinks } from "./plugins/compliance/components/compliance-navigation-links";
import {
  CustomDrawer,
  PluginConfig,
  PlatformRestrictedNavGroup,
  compose,
  ChildProps
} from "@atlassiansox/microscopekit";
import { PluginProps } from "./app-plugins";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getIsChromeless } from "./core/helpers";
import { CompassBanner } from "./compass-banner";

const { AkNavigationItemGroup } = require("@atlaskit/navigation");

export enum ContainerType {
  Service,
  Category
}

export interface Container {
  id: string;
  name: string;
  displayName: string | null;
  type: ContainerType;
}

type Props = ChildProps<{
  container?: Container;
  content?: any;
}> &
  PluginProps;

export class AppNavigationView extends React.Component<
  Props & RouteComponentProps<any>
> {
  state: any = {
    links: []
  };

  getServiceLinks(id: string, devPlugin?: PluginConfig) {
    const pluginNavigationLinks = this.props.plugins
      .map(plugin =>
        plugin.serviceContainer
          ? plugin.serviceContainer.navigation.map(navigation => ({
              [`${plugin.id}-${navigation.id}`]: navigation.component
            }))
          : []
      )
      .reduce((a, b) => a.concat(b), [])
      .reduce((a, b) => ({ ...a, ...b }), {});

    const ServiceCentralPluginNavigationLinks =
      pluginNavigationLinks["service-central-service-central"];
    const ReleaseTrackingPluginNavigationLinks =
      pluginNavigationLinks["release-tracking-releases-navs"];
    const MicrosPluginNavigationLinks =
      pluginNavigationLinks["micros-plugin-service-navs"];
    const DataClassificationsPluginNavigationLinks =
      pluginNavigationLinks["dataclassifications-service-navs"];
    const ResiliencePluginNavigationLinks =
      pluginNavigationLinks["resilience-service-navs"];

    return [
      // Service context navigation links go here
      <AkNavigationItemGroup key="service" title="Service">
        {ServiceCentralPluginNavigationLinks && (
          <ServiceCentralPluginNavigationLinks id={id} />
        )}
        <ServiceCentralNavigationLinks id={id} />
        <ComplianceNavigationLinks id={id} />
        {DataClassificationsPluginNavigationLinks && (
          <DataClassificationsPluginNavigationLinks id={id} />
        )}
        {ResiliencePluginNavigationLinks && (
          <ResiliencePluginNavigationLinks id={id} />
        )}
      </AkNavigationItemGroup>,
      <PlatformRestrictedNavGroup
        key="micros"
        id={id}
        title="Micros"
        platform="micros"
        numberOfSkeletonLinks={3}
      >
        {MicrosPluginNavigationLinks && <MicrosPluginNavigationLinks id={id} />}
        {ReleaseTrackingPluginNavigationLinks && (
          <ReleaseTrackingPluginNavigationLinks id={id} />
        )}
      </PlatformRestrictedNavGroup>,

      devPlugin && devPlugin.serviceContainer && (
        <AkNavigationItemGroup key="development" title="Development">
          {devPlugin.serviceContainer.navigation.map(navigation => (
            <navigation.component key={navigation.id} id={id} />
          ))}
        </AkNavigationItemGroup>
      )
    ];
  }

  getCategoryLinks(id: string, devPlugin?: PluginConfig) {
    const pluginNavigationLinks = this.props.plugins
      .map(plugin =>
        plugin.categoryContainer
          ? plugin.categoryContainer.navigation.map(navigation => ({
              [`${plugin.id}-${navigation.id}`]: navigation.component
            }))
          : []
      )
      .reduce((a, b) => a.concat(b), [])
      .reduce((a, b) => ({ ...a, ...b }), {});

    return [
      <AkNavigationItemGroup key="category" title="Category">
        <ServiceCentralCategoryLinks id={id} />
      </AkNavigationItemGroup>,
      devPlugin && devPlugin.categoryContainer && (
        <AkNavigationItemGroup key="development" title="Development">
          {devPlugin.categoryContainer.navigation.map(navigation => (
            <navigation.component key={navigation.id} id={id} />
          ))}
        </AkNavigationItemGroup>
      )
    ].filter(e => e);
  }

  getCustomDrawers() {
    return [
      // Custom drawers go here
      <CreateServiceDrawer key="create-service" />,
      ...[...this.props.plugins, this.props.devPlugin].map(plugin =>
        (plugin && plugin.drawers ? plugin.drawers : []).map(config => (
          <CustomDrawer
            key={plugin!.id + "-" + config.id}
            id={plugin!.id + "-" + config.id}
            width={config.width}
          >
            <config.component />
          </CustomDrawer>
        ))
      )
    ];
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.container) {
      this.setState({ links: [] });
    }
  }

  getContainerLinks(container: Container, devPlugin?: PluginConfig) {
    if (container.type === ContainerType.Service) {
      return this.getServiceLinks(container.id, devPlugin);
    } else {
      return this.getCategoryLinks(container.id, devPlugin);
    }
  }

  render() {
    const { content, plugins, devPlugin } = this.props;
    const pathParams = this.props.location?.pathname.split("/");

    const createContainer = (pathParams: string[]) => {
      const containerType = pathParams[1];
      const containerId = pathParams[2];
      return ["services"].includes(containerType)
        ? {
            id: decodeURIComponent(containerId),
            name: decodeURIComponent(containerId),
            type: ContainerType.Service,
            displayName: null
          }
        : undefined;
    };

    const container = pathParams && createContainer(pathParams);
    const isChromeless = getIsChromeless();
    return isChromeless ? (
      content
    ) : (
      <>
        <CompassBanner />
        <Navigation
          plugins={[...plugins, devPlugin]}
          drawers={this.getCustomDrawers()}
          container={container}
          content={content}
          links={container ? this.getContainerLinks(container, devPlugin) : []}
        />
      </>
    );
  }
}

// Have to manually annotate type because `compose` is not type safe
export const AppNavigation: React.ComponentType<Props> = compose(withRouter)(
  AppNavigationView
);
