import styled from "styled-components";

export const Code = styled.code`
  ${(props: { color?: string }) =>
    props.color ? `color: ${props.color};` : ``};
  padding: 2px 8px;
  background-color: #eee;
  border-radius: 5px;
`;
Code.displayName = "Code";
