import * as React from "react";
import { GrayText } from "../gray-text";
import { Link } from "../index";

const AkTag = require("@atlaskit/tag").default;
const AkTagGroup = require("@atlaskit/tag-group").default;
const { CreatableSelect } = require("@atlaskit/select");

// Tags
export interface TagProps {
  label: string;
  value: string;
  isSelected: boolean;
  link?: string;
}

export interface Props {
  color?: string;
  title?: string;
  prefix?: string;
  tags: TagProps[];
  align?: "left" | "right";
  editMode?: boolean;
  onChange?: (selectedTags: TagProps[]) => void; // Relevant only if editMode is set to true
  onDelete?: (tag: TagProps) => void; // Can be used without editMode. Used if tags can be deleted but not added
  disabled?: boolean;
  emptyMessage?: string;
}

let nextTagsId = 0;
interface TagsState {
  id: number;
}

export class EditableTags extends React.Component<Props, TagsState> {
  constructor(props: Props) {
    super(props);
    // TODO remove id autogeneration, ask for id being provided as a parameter of `Tags`.
    // If two Tag components with same key created in different Tags Components - all Tags with the same keys will
    // be removed when just one is removed. Creating global unique keys for Tag components prevents that.
    this.state = {
      id: nextTagsId++
    };
  }

  onNewItemCreated = (item: string) => {
    let newItems = this.props.tags.slice();
    newItems.push({ label: item, value: item, isSelected: true });
    if (this.props.onChange) {
      this.props.onChange(newItems);
    }
  };

  onSelectedChange = (selectedItems: TagProps[]) => {
    if (this.props.onChange) {
      this.props.onChange(selectedItems);
    }
  };

  renderEditMode = (items: TagProps[], disabled?: boolean) => (
    <CreatableSelect
      options={items}
      value={items.filter(item => item.isSelected)}
      onChange={this.onSelectedChange}
      onCreateOption={this.onNewItemCreated}
      isMulti
      isDisabled={disabled}
    />
  );

  renderViewMode = (
    tags: TagProps[],
    color?: string,
    onDelete?: (tag: TagProps) => void,
    emptyMessage?: string
  ) => {
    if (tags && tags.length > 0) {
      return (
        <AkTagGroup>
          {tags.map(tag => {
            const akTag = (
              <AkTag
                key={"" + this.state.id + tag.value}
                text={tag.value}
                color={color}
                removeButtonText={onDelete ? "Delete" : ""}
                onBeforeRemoveAction={(_event: any) => {
                  if (onDelete) {
                    onDelete(tag);
                  }
                }}
              />
            );
            if (tag.link) {
              return <Link to={tag.link}>{akTag}</Link>;
            }
            return akTag;
          })}
        </AkTagGroup>
      );
    } else {
      return (
        <GrayText bold className="empty-tags">
          {emptyMessage ? emptyMessage : "No tags."}
        </GrayText>
      );
    }
  };

  render() {
    let {
      color,
      tags,
      align,
      onDelete,
      editMode,
      disabled,
      emptyMessage
    } = this.props;

    const items = this.props.tags;
    return (
      <div className="ptags">
        <h4>{this.props.title}</h4>
        <div className={align}>
          {editMode
            ? this.renderEditMode(items, disabled)
            : this.renderViewMode(tags, color, onDelete, emptyMessage)}
        </div>
      </div>
    );
  }
}
