import * as React from "react";
import * as Table from "../../../palette/dynamic-table";
import * as Palette from "../../../palette";
import * as EditableText from "../../../palette/editable/editable-text";
import servicePolicyQuery from "../models/service-policy.graphql";
import SectionMessage from "@atlaskit/section-message";
import findCompliancePoliciesQuery from "../models/find-compliance-policies.query.graphql";
import findServicesWithPolicyQuery from "../models/find-services-with-policy.query.graphql";
import updateServicePolicyQuery from "../models/update-service-policy.mutation.graphql";
import { PageLayout } from "../../../palette/layout/page-layout";
import { noMetadataMessage } from "../../service-central/components/helpers";
import { getCurrentUser, logger } from "../../../core";
import {
  getErrorMessage,
  isSufficientPermission,
  AnyComponent,
  isForbiddenError
} from "../../../core/helpers";
import moment from "moment";
import { CreateCompliancePolicyModal } from "./create-compliance-policy";
import { UpdateCompliancePolicyModal } from "./update-compliance-policy";
import { withMountTracking } from "../../../core/components/mount-tracker";
import {
  FlagProps,
  withShowFlag,
  ChildProps,
  compose,
  MutationFunc,
  withApollo,
  graphql
} from "@atlassiansox/microscopekit";
import styled from "styled-components";
import { withLoadingSpinner } from "../../../palette/with-loading-spinner";
import { withServiceNotFoundHandler } from "../../../palette/with-service-not-found-handler";
import { withGraphqlErrorFlags } from "../../../palette/with-graphql-error-flags";
import { Permission } from "../../../typings";
import ApolloClient from "apollo-client/ApolloClient";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloQueryResult } from "apollo-client";
import { servicePolicy } from "../models/__generated__/servicePolicy";
import {
  findServicesWithCompliancePolicy,
  findServicesWithCompliancePolicy_search_edges_node_Service
} from "../models/__generated__/findServicesWithCompliancePolicy";
import { updateServicePolicyVariables } from "../models/__generated__/updateServicePolicy";
import {
  findCompliancePolicies,
  findCompliancePolicies_search_edges_node_CompliancePolicy2
} from "../models/__generated__/findCompliancePolicies";
import { SSAMAnnouncement } from "../../service-central/components/ssam-announcement";
import {
  compliancePolicy2,
  compliancePolicy2_questions,
  compliancePolicy2_questions_answer
} from "../models/__generated__/compliancePolicy2";
import { node } from "prop-types";
import { updateService } from "../../service-central/models/__generated__/updateService";
import { Tc2Error } from "../../../core/components/tc2";
const AkSpinner = require("@atlaskit/spinner").default;
const AkBanner = require("@atlaskit/banner").default;
const { ButtonGroup } = require("@atlaskit/button");

type ServicePolicyProps = ChildProps<
  {
    updateServicePolicy: MutationFunc<
      updateService,
      updateServicePolicyVariables
    >;
  } & FlagProps & { client: ApolloClient<InMemoryCache> },
  servicePolicy
>;

export interface State {
  makingQuery?: boolean;
  queryingPolicies?: boolean;
  filteredPolicies: compliancePolicy2[]; // Policies to display in the dropdown for autocomplete
  policies: compliancePolicy2[]; // Cache of all policies we've pulled before

  editMode: boolean;
  editingName: string; // What text should be displayed in the input box
  policyToDisplay: string; // The name of the policy that should be displayed

  showCreatePolicy?: boolean; // Show the "create a new policy page" instead
  showUpdatePolicy?: boolean; // Show the "update a new policy page" instead

  relatedServices?: string[];
}

const policyPreview = "POLICY PREVIEW";
const createPolicyText = "Create New Policy";

const EditPolicyNameContainer = styled.div`
  width: 70%;
`;
const Instructions = styled.p`
  margin-bottom: 16px;
`;
const PolicyPreviewHeader = styled.h3`
  && {
    margin-top: 16px;
  }
  margin: 16px 0px;
  padding: 4px;
  background: #eee;
  text-align: center;
  font-size: 0.85em;
  border-bottom: 2px solid #dfe1e6;
`;
const Separator = styled.div`
  height: 0.5em;
`;

const FadedPreviewTableWrapper = styled.div`
  ${(props: { faded: boolean }) => (props.faded ? `opacity: 0.5;` : "")};
`;
FadedPreviewTableWrapper.displayName = "FadedPreviewTableWrapper";

export class ServicePolicyView extends React.Component<
  ServicePolicyProps,
  State
> {
  constructor(props: ServicePolicyProps) {
    super(props);
    if (this.props.data!.service) {
      const policy = this.props.data!.service!.compliancePolicy;
      if (policy) {
        this.state = {
          filteredPolicies: [policy],
          policies: [policy],
          editMode: false,
          editingName: policy.name,
          policyToDisplay: policy.name,
          relatedServices: []
        };
      } else {
        this.state = {
          filteredPolicies: [],
          policies: [],
          editMode: false,
          editingName: "",
          policyToDisplay: "",
          relatedServices: []
        };
      }
    }
  }

  componentDidMount() {
    document.title = this.props.data!.service!.id + " | Compliance";
  }

  componentWillUnmount() {
    document.title = "Microscope";
  }

  toggleEditMode = () => {
    const newMode = !this.state.editMode;
    track(
      newMode
        ? "Compliance.ServicePolicy: EditStarted"
        : "Compliance.ServicePolicy: EditCancelled ",
      { category: "info", editMode: newMode }
    );
    if (newMode) {
      this.setState({ queryingPolicies: true });
      this.fetchPolicies(this.state.editingName);
    } else {
      const policy = this.props.data!.service!.compliancePolicy!;
      let name = policy ? policy.name : "";
      this.setState({
        editingName: name,
        policyToDisplay: name,
        filteredPolicies: policy ? [policy] : []
      });
    }

    this.setState({
      editMode: newMode
    });
  };

  toggleCreatePolicyModal = () => {
    const newMode = !this.state.showCreatePolicy;
    track(
      newMode
        ? "Compliance.ServicePolicy: CreateOpened"
        : "Compliance.ServicePolicy: CreateClosed",
      { category: "info" }
    );
    this.setState({ showCreatePolicy: newMode });
  };

  toggleEditPolicyModal = async () => {
    const service = this.props.data!.service;
    if (!service) {
      logger.error(
        "Called Compliance.ServicePolicy.toggleEditPolicyModal without a service"
      );
      return;
    }

    if (this.state.showUpdatePolicy) {
      track("Compliance.ServicePolicy: UpdateClosed", { category: "info" });
      this.setState({ showUpdatePolicy: false });
    } else {
      track("Compliance.ServicePolicy: UpdateOpened", { category: "info" });
      const policy = this.state.policies.find(
        p => p.name === this.state.policyToDisplay
      );
      // Call the backend for a list of services that use this policy so we can tell users what services will be affected
      if (policy) {
        logger.info("fetching services that use policy: " + policy.id);

        this.setState({ relatedServices: undefined, showUpdatePolicy: true });
        this.props.client
          .query({
            query: findServicesWithPolicyQuery,
            fetchPolicy: "network-only",
            errorPolicy: "all",
            context: {
              debounceKey: "FindServicesWithPolicy"
            },
            variables: {
              query: "compliancePolicyId:" + policy.id
            }
          })
          .then(
            (result: ApolloQueryResult<findServicesWithCompliancePolicy>) => {
              let services = result.data.search.edges
                ? result.data.search.edges
                    .filter(e => e!.node)
                    .map(
                      e =>
                        e!
                          .node as findServicesWithCompliancePolicy_search_edges_node_Service
                    )
                : [];
              this.setState({ relatedServices: services.map(s => s.id) });
            }
          )
          .catch(err => {
            logger.error(err.toString());
            this.props.showFlag({
              type: "error",
              title: "Error",
              message: err.toString()
            });
          });
      } else {
        const err =
          'Called "makeUpdatePolicyModal" for a policy that is not in the state';
        logger.error(err);
        throw new Error(err);
      }
    }
  };

  // Make call to backend to update which policy is associated with this service
  updatePolicy = async () => {
    const service = this.props.data!.service;
    if (!service) {
      logger.error(
        "Called Compliance.ServicePolicy.updatePolicy without a service"
      );
      return;
    }
    const metadata = service.metadata;
    if (!metadata) {
      logger.error(
        "Called Compliance.ServicePolicy.updatePolicy without service metadata"
      );
      return;
    }
    const policy = service.compliancePolicy;

    // If there is no compliance policy for this service yet, or
    // if the currently associated policy is different from the one we want to change it to, update it
    if (
      (metadata && !policy) ||
      (metadata && policy && this.state.editingName !== policy.name)
    ) {
      let newPolicy = this.state.policies.find(
        p => p.name === this.state.editingName
      );
      if (!newPolicy) {
        logger.error(
          "Could not find a policy with name: " +
            this.state.editingName +
            " in the local cache to update to"
        );
        throw new Error(
          "Could not find a policy with name: " +
            this.state.editingName +
            " in the cache"
        );
      }

      track("Compliance.ServicePolicy: PolicySelected", { category: "info" });
      this.updateServicePolicy(service.id, newPolicy);
    } else {
      track("Compliance.ServicePolicy: SamePolicySelected", {
        category: "info"
      });
      // If its the same policy, just exit editMode
      this.setState({ editMode: false, makingQuery: false });
    }
  };

  updateServicePolicy = async (
    serviceId: string,
    newPolicy: compliancePolicy2
  ) => {
    try {
      // Make the call to update this service's policy
      this.setState({ makingQuery: true });
      await this.props.updateServicePolicy({
        variables: {
          input: {
            id: serviceId,
            metadata: {
              compliancePolicyId: newPolicy.id
            }
          }
        }
      });
      this.props.showFlag({
        type: "success",
        title: "Success",
        message: "Policy successfully updated."
      });
      let newPolicies = this.state.policies.slice();
      newPolicies.push(newPolicy);
      this.setState({
        editMode: false,
        makingQuery: false,
        policyToDisplay: newPolicy.name,
        policies: newPolicies
      });
    } catch (e) {
      const errMsg = getErrorMessage(e);
      const errComponent = isForbiddenError(errMsg) ? (
        <Tc2Error serviceId={serviceId} />
      ) : (
        <p>{errMsg}</p>
      );

      track("Compliance.ServicePolicy: UpdateServiceFailed", {
        category: "error",
        errMsg
      });
      this.props.showFlag({
        type: "error",
        title: "Failed to update service policy",
        message: errComponent,
        disableAutoDismiss: true
      });
    }
  };

  updatePolicyFields = (newPolicy: compliancePolicy2) => {
    let newPolicies = this.state.policies
      .slice()
      .filter(p => p.id !== newPolicy.id);
    newPolicies.push(newPolicy);
    this.setState({ policies: newPolicies });
  };

  // When an option in the dropdown has been selected and we need to render a preview of a policy
  updatePolicyPreview = (newPolicy?: string) => {
    let newName = newPolicy ? newPolicy : this.state.editingName;
    track("Compliance.ServicePolicy: DropdownSelected", { category: "info" });
    let policy = this.state.policies.find(p => p.name == newName);
    if (policy) {
      this.setState({ policyToDisplay: newName, editingName: newName });
    }
  };

  // Called when the text in the dropdown changes
  onFilterPolicy = (filter: string) => {
    track("Compliance.ServicePolicy: FilterChanged", { category: "info" });
    // Make a call to get a list of policies (filtered by name) for the dropdown / autocomplete feature
    if (filter) {
      this.setState({
        filteredPolicies: [],
        queryingPolicies: true,
        editingName: filter
      });
      this.fetchPolicies(filter);
    }
  };

  fetchPolicies = (filter: string) => {
    this.props.client
      .query({
        query: findCompliancePoliciesQuery,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        context: {
          debounceKey: "FindCompliancePolicies"
        },
        variables: {
          partialId: filter || ""
        }
      })
      .then((result: ApolloQueryResult<findCompliancePolicies>) => {
        const edges = result.data.search.edges
          ? result.data.search.edges.filter(e => e !== null && e.node !== null)
          : [];
        const filteredPolicies = edges.map(
          edge =>
            edge!
              .node! as findCompliancePolicies_search_edges_node_CompliancePolicy2
        );
        let newPolicies = this.state.policies.slice();
        // Add the newly pulled policies to our list of all policies
        filteredPolicies.forEach((policy: compliancePolicy2) => {
          if (!newPolicies.find(p => p.id == policy.id)) {
            newPolicies.push(policy);
          }
        });
        this.setState({
          policies: newPolicies,
          filteredPolicies,
          queryingPolicies: false
        });
      })
      .catch(err => {
        logger.error(err.toString());
        this.props.showFlag({
          type: "error",
          title: "Failed to fetch compliance policies",
          message: err.toString()
        });
      });
  };

  makeCreatePolicyModal = () => {
    const service = this.props.data!.service;
    if (service) {
      return (
        <CreateCompliancePolicyModal
          service={service.id}
          closeModal={this.toggleCreatePolicyModal}
          updateServicePolicy={this.updateServicePolicy}
        />
      );
    }
    logger.error(
      "Called Compliance.ServicePolicy.makeCreatePolicyModal without a service"
    );
    throw new Error(
      'Called "makeCreatePolicyModal" when there is no service to update'
    );
  };

  makeUpdatePolicyModal = () => {
    const shownPolicy = this.state.policies.find(
      p => p.name === this.state.policyToDisplay
    );
    if (shownPolicy) {
      return (
        <UpdateCompliancePolicyModal
          policy={shownPolicy}
          services={this.state.relatedServices}
          closeModal={this.toggleEditPolicyModal}
          updatePolicy={this.updatePolicyFields}
        />
      );
    } else {
      const err = "Could not find the policy being shown in the state";
      logger.error(err);
      throw new Error(err);
    }
  };

  render() {
    const service = this.props.data!.service;
    // If service not found
    if (!service) {
      return noMetadataMessage("Compliance Policy");
    }
    const metadata = service.metadata;

    let isServiceOwner = getCurrentUser().username == metadata.owner.id;

    let policies = this.state.policies;
    let policy = service.compliancePolicy;
    const editMode = this.state.editMode || (isServiceOwner && !policy);

    // Figure out what buttons should be displayed, if any
    const cancelButton = (
      <Palette.Button
        key="Cancel"
        className="cancel-policy"
        disabled={this.state.makingQuery}
        onClick={this.toggleEditMode}
      >
        Cancel
      </Palette.Button>
    );
    const editButton = (
      <Palette.Button
        key="Edit"
        className="edit-service-policy"
        disabled={this.state.makingQuery}
        onClick={this.toggleEditMode}
      >
        Edit
      </Palette.Button>
    );
    let editPolicyButton = <span key="edit-policy" />;
    let buttons = null;
    let actions: JSX.Element = <span />;

    // Figure out which policy to display: (the currently applied policy, or a preview of a different one?)
    let policyToRender: compliancePolicy2 | null | undefined = policy;
    if (editMode) {
      policyToRender = policies.find(
        p => p.name === this.state.policyToDisplay
      );
    }

    let isPolicyOwner =
      policyToRender &&
      (getCurrentUser().username == policyToRender.owner2.id ||
        isSufficientPermission(
          policyToRender.currentUserPermission,
          Permission.Admin
        ));
    if (isPolicyOwner && policyToRender) {
      editPolicyButton = (
        <Palette.Button
          key="Edit Policy"
          className="edit-policy"
          disabled={this.state.makingQuery}
          onClick={this.toggleEditPolicyModal}
        >
          {'Edit Policy "' + policyToRender.name + '"'}
        </Palette.Button>
      );
      actions = <ButtonGroup>{editPolicyButton}</ButtonGroup>;
    }

    if (isServiceOwner) {
      actions = policy ? (
        editMode ? (
          <ButtonGroup>
            {cancelButton}
            {editPolicyButton}
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            {editButton}
            {editPolicyButton}
          </ButtonGroup>
        )
      ) : (
        <span />
      );
      buttons = this.renderButtons(editMode, policy);
    }

    const noSSAMSet =
      !service.metadata?.ssamContainer?.id &&
      isSufficientPermission(service.currentUserPermission, Permission.Write);
    if (noSSAMSet) {
      actions = <span />;
      buttons = <span />;
    }

    let instructions = editMode
      ? `Select an existing policy for this service from the dropdown below, or create a` +
        ` new policy with the "` +
        createPolicyText +
        `" button.`
      : "";

    // Instructions for the case where there is no policy for this service
    if (!policy) {
      instructions = isServiceOwner
        ? "This service does not have a compliance policy. Please add or create a policy."
        : `This service does not have a compliance policy. The service owner, ${metadata.owner.id}, can create one by visiting this page.`;

      // If the user isn't the owner, just display a message saying this service has no policy
      if (!isServiceOwner) {
        return (
          <PageLayout title="Compliance Policy">
            <Palette.GrayText bold>{instructions}</Palette.GrayText>
          </PageLayout>
        );
      }
    }

    // Render when there is a policy
    return (
      <PageLayout title="Compliance Policy" actions={actions}>
        {noSSAMSet && (
          <SSAMAnnouncement
            service={{
              id: service.id,
              currentUserPermission: service.currentUserPermission
            }}
          />
        )}
        {policyToRender &&
        getCurrentUser().username !== policyToRender.owner2.id &&
        !isSufficientPermission(
          policyToRender.currentUserPermission,
          Permission.Admin
        ) ? (
          <AkBanner isOpen appearance="announcement">
            This policy is owned by {policyToRender.owner2.id}. If you need to
            update the policy, you should contact the owner or the owner's
            manager. If the owner and their manager are unavailable, please
            raise a ticket at go/micros-helpdesk to transfer ownership.
          </AkBanner>
        ) : null}
        <span key="create modal">
          {this.state.showCreatePolicy ? this.makeCreatePolicyModal() : null}
        </span>
        <span key="update modal">
          {this.state.showUpdatePolicy ? this.makeUpdatePolicyModal() : null}
        </span>
        <SectionMessage appearance="info">
          <p>
            If your service is a{" "}
            <a
              href="https://go.atlassian.com/micros-tc2-compliance"
              target="_blank"
              rel="noopener noreferrer"
            >
              TC2-enrolled Micros service
            </a>{" "}
            and has a compliance policy, actions modifying the service may
            require just-in-time access via the "Micros Just-In-Time" access
            level in{" "}
            <a
              href={`https://ssam.us-east-1.prod.apd-paas.net/containers/micros-sv--${service.id}/#jit`}
              target="_blank"
              rel="noopener noreferrer"
            >
              your service's SSAM container
            </a>
            .
          </p>
        </SectionMessage>
        <Instructions>{instructions}</Instructions>
        <Palette.SmallFlexDiv>
          {this.renderPolicyName(
            editMode,
            policy ? policy.name : "",
            this.state.filteredPolicies,
            this.state.makingQuery
          )}
          <Palette.Separator key="separator" />
          <div className="buttons-div">{buttons}</div>
        </Palette.SmallFlexDiv>
        {policyToRender ? (
          <Palette.GrayText small>
            Owner: {policyToRender.owner2.id}
          </Palette.GrayText>
        ) : null}
        <Separator />
        {this.renderPolicyTable(editMode, policyToRender)}
      </PageLayout>
    );
  }

  // Figure out what buttons should be displayed, if any
  renderButtons = (editMode: boolean, hasPolicy?: compliancePolicy2 | null) => {
    const selectButton = (
      <Palette.Button
        appearance="primary"
        key="select"
        className="select-policy"
        disabled={
          this.state.policyToDisplay !== this.state.editingName ||
          this.state.makingQuery
        }
        iconBefore={
          this.state.makingQuery ? (
            <AkSpinner size="small" isCompleting={false} />
          ) : (
            undefined
          )
        }
        onClick={this.updatePolicy}
      >
        Select
      </Palette.Button>
    );
    const createPolicyButton = (
      <Palette.Button
        appearance="subtle"
        key="new-policy"
        className="new-policy"
        disabled={this.state.makingQuery}
        onClick={this.toggleCreatePolicyModal}
      >
        {createPolicyText}
      </Palette.Button>
    );
    const bothButtons = (
      <Palette.FlexDiv>
        {selectButton}
        <Palette.Separator key="separator" />
        {createPolicyButton}
      </Palette.FlexDiv>
    );

    // if this service has no policy and we have chosen a policy to (possibly) update to
    if (
      !hasPolicy &&
      this.state.policyToDisplay &&
      this.state.policyToDisplay !== ""
    ) {
      return bothButtons;
    } else if (hasPolicy && editMode) {
      // service has a policy and we want to change it
      return bothButtons;
    } else if (!hasPolicy && editMode) {
      // if this service has no policy and we have not chosen one that we want to update to yet
      return createPolicyButton;
    }
    // not in editMode
    return null;
  };

  // Render the title / editable dropdown
  renderPolicyName = (
    editMode: boolean,
    policyName: string,
    policies: compliancePolicy2[],
    disabled?: boolean
  ) => {
    let text = (
      <EditableText.EditableText
        value={"Name: " + policyName}
        editValue={this.state.editingName}
        editMode={editMode}
        onChange={this.updatePolicyPreview}
        disabled={disabled}
        editOptions={policies.map(policy => policy.name)}
        onFilterChange={this.onFilterPolicy}
        placeholder={"Enter a policy name."}
        noMatchesFound={
          this.state.queryingPolicies ? (
            <AkSpinner size="small" isCompleting={false} />
          ) : (
            undefined
          )
        }
      />
    );
    return editMode ? (
      <EditPolicyNameContainer>{text}</EditPolicyNameContainer>
    ) : (
      <h3>{text}</h3>
    );
  };

  // Render the policy table
  renderPolicyTable = (
    editMode: boolean,
    policy?: compliancePolicy2 | null
  ) => {
    if (policy) {
      const table = (
        <Table.DynamicTable
          head={{
            cells: [
              { key: "name", content: "Question", width: 45 },
              { key: "description", content: "Answer", width: 20 },
              { key: "lastModified", content: "Last Answered", width: 20 }
            ]
          }}
          rows={policy.questions.map(
            (question: compliancePolicy2_questions) => {
              const date = question.answer
                ? moment(question.answer!.changeMetadata.modifiedOn!).fromNow()
                : "N/A";
              return {
                cells: [
                  { key: question.question, content: question.question },
                  {
                    key: question.id,
                    content: this.parseAnswer(question.schema, question.answer)
                  },
                  { key: date, content: date }
                ]
              };
            }
          )}
        />
      );

      const tableWithHeader = [
        <h4 className="preview-table-name">{policy.name}</h4>,
        table
      ];

      return editMode ? (
        <div>
          <span>
            <PolicyPreviewHeader className="policy-preview-header">
              {policyPreview}
            </PolicyPreviewHeader>
          </span>
          <FadedPreviewTableWrapper
            faded={policy.name != this.state.editingName}
            className="preview-table-div"
          >
            {tableWithHeader}
          </FadedPreviewTableWrapper>
        </div>
      ) : (
        table
      );
    }
    return null;
  };

  // Figure out how the answer should be displayed depending on the question's schema
  parseAnswer = (
    schema: string,
    answer: compliancePolicy2_questions_answer | null
  ): string => {
    if (answer === null) {
      return "N/A";
    }

    const questionSchema = JSON.parse(schema);
    const type = questionSchema["type"];
    switch (type) {
      case "boolean": {
        return answer.answer === "true" ? "Yes" : "No";
      }
      case "string": {
        return answer.answer;
      }
      default: {
        return answer.answer;
      }
    }
  };
}

export class ServicePolicy extends React.Component<any> {
  component?: AnyComponent;

  componentWillMount() {
    this.component = compose(
      graphql(servicePolicyQuery, {
        options: () => ({
          errorPolicy: "all"
        })
      }),
      graphql(updateServicePolicyQuery, {
        name: "updateServicePolicy"
      }),
      withApollo,
      withLoadingSpinner,
      withShowFlag,
      withGraphqlErrorFlags,
      withServiceNotFoundHandler,
      withMountTracking("ServicePolicy")
    )(ServicePolicyView);
  }

  render() {
    return this.component ? <this.component {...this.props} /> : undefined;
  }
}
