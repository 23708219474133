import { getGlobals } from "../../../private/globals";
import { logger } from "../../..";

// It should be more than enough, the widget can use smaller limit, but it can't use the larger one.
const LIMIT = 30;

export type IconType = "service";

export interface HistoryLink {
  // Links with the same urls would be seen as identical
  url: string;
  title: string;
  icon?: IconType;
}

export interface History {
  isReady: boolean;
  error: null | string;
  links: HistoryLink[];
}

// Storage
export class HistoryStorage {
  protected history: History = { isReady: false, error: null, links: [] };
  protected buffer: HistoryLink[] = [];
  protected listeners: (() => void)[] = [];

  constructor(readonly limit: number) {
    const asyncLoad = async () => {
      try {
        const links = await load();
        this.set({ isReady: true, links });
      } catch (e) {
        logger.error(e);
        this.set({ isReady: true, error: e.message || "unknown error" });
      }
      this.handleBuffer();
    };
    asyncLoad();
  }

  onNavigationEvent(link: HistoryLink) {
    this.buffer.push(link);
    // Event can't be processed right now, because the initial state may not be yet fetched from the server.
    // Buffering events untill the state will be loaded.
    this.handleBuffer();
  }

  getHistory(limit: number) {
    if (limit > this.limit) {
      logger.warn("history limit is too low");
    }
    return {
      isReady: this.history.isReady,
      error: this.history.error,
      links: this.history.links.slice(0, limit)
    };
  }

  subscribe(listener: () => void): () => void {
    this.listeners.push(listener);
    return () => {
      this.listeners = this.listeners.filter(l => l !== listener);
    };
  }

  protected handleBuffer() {
    if (!this.history.isReady) {
      return;
    }

    for (const link of this.buffer) {
      let { links } = this.history;
      if (links.length > 0 && links[0].url === link.url) {
        break;
      }

      links = links.filter(({ url }) => url !== link.url);
      links.unshift(link);
      links = links.slice(0, this.limit);

      this.set({ links });
      store(this.history.links, link, this.limit);
    }
    this.buffer = [];
  }

  protected set(history: Partial<History>) {
    this.history = { ...this.history, ...history, error: null };
    this.listeners.forEach(listener => listener());
  }
}

// Storing the state
export async function load(): Promise<HistoryLink[]> {
  const data = localStorage.getItem("history-widget");
  return data ? JSON.parse(data).links : [];
}

export function store(
  links: HistoryLink[],
  _link: HistoryLink,
  _limit: number
) {
  localStorage.setItem("history-widget", JSON.stringify({ links }));
}

export function getHistoryStorage(): HistoryStorage {
  let storage = getGlobals()["widget-history"];
  if (!storage) {
    storage = new HistoryStorage(LIMIT);
    getGlobals()["widget-history"] = storage;
  }
  return storage;
}
