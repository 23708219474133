import * as React from "react";
import {
  ChildProps,
  compose,
  withApollo,
  graphql
} from "@atlassiansox/microscopekit";
import { logger } from "../../..";
import { getErrorMessage } from "../../../helpers";
import { servicePath, userUrl, categoryPath } from "../../../routes";
import * as Palette from "../../../../palette";
import { ServiceForSearch, TagForSearch } from "../models/interfaces";
import searchQueryV2 from "./search-v2.query.graphql";
import { advancedSearchPath } from "../routes";
import styled from "styled-components";
import { SearchHelp } from "./search-help";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { searchV2 } from "./__generated__/searchV2";
import { Category } from "../../../../typings";

const AkSpinner = require("@atlaskit/spinner").default;
const AkAddonIcon = require("@atlaskit/icon/glyph/addon").default;
const QuestionCircleIcon = require("@atlaskit/icon/glyph/question-circle")
  .default;
const AkPersonIcon = require("@atlaskit/icon/glyph/person").default;

const ErrorMessage = styled.pre`
  margin-top: 2rem;
  padding: 5px 15px;
  background-color: #ff8f73;
  border-radius: 3px;
  border: solid 1px #bf2600;
  font-family: Monaco, "Courier New", Courier, monospace;
  white-space: pre-wrap;
  word-break: break-all;
`;

const HelpLink = styled.a`
  position: absolute;
  top: 8px;
  right: 6px;
  color: #aaa;
  :hover {
    color: #0052cc;
  }
`;

const SearchInputWrapper = styled.div`
  position: relative;
`;
const SearchResultName = styled.span`
  background-color: #0052cc;
  color: #fff;
  border-radius: 3px;
  padding: 1px 5px;
`;

const PaginationDiv = styled.div`
  border-top: 2px solid #dfe1e6;
  > * {
    display: inline-block;
    margin-top: 10px;
    margin-left: 10px;
  }
`;
const SearchResultsTable = styled(Palette.Table)`
  margin-top: 2rem;
  > thead th {
    text-align: center;
  }
`;

const WaitingShadow = styled.div`
  ${(props: { isWaiting: boolean }) =>
    props.isWaiting ? "opacity: 0.3;" : ""};
`;

const SearchInputSpinner = styled.span`
  top: 10px;
  right: 10px;
  position: absolute;
`;

type SearchProps = RouteComponentProps<any> &
  ChildProps<
    {
      query: string;
      page: number;
      type: "services" | "tags" | "categories";
      testing?: boolean;
    },
    searchV2
  >;

export interface SearchState {
  input: string;
  help: boolean;
  found: {
    query: string;
    page: number;
    services: any[]; // edges
    tags: any[];
    categories: any[];
    hasMore: boolean;
    error: null | string;
  };
}

const perPage = 25;
export class SearchView extends React.Component<SearchProps, SearchState> {
  constructor(props: SearchProps) {
    super(props);
    this.state = {
      input: props.query,
      help: false,
      found: {
        query: "",
        page: 1,
        services: [],
        tags: [],
        categories: [],
        hasMore: false,
        error: null
      }
    };
  }

  onChange = (e: any) => {
    const input = e.target.value;
    this.setState({ input });
  };

  onSubmit = (e: any) => {
    e.preventDefault();
    this.props.history.push(
      advancedSearchPath(this.props.type, this.state.input, 1)
    );
  };

  goToPage = (page: number) => {
    track("SearchPage.Result: GoToPage", {
      category: "info",
      element: "search",
      page
    });
    this.navigateToAndScrollToTop(
      advancedSearchPath(this.props.type, this.props.query, page)
    );
  };

  componentWillReceiveProps(nextProps: SearchProps) {
    if (
      nextProps.query !== this.props.query ||
      nextProps.page !== this.props.page
    ) {
      const { query, page } = nextProps;
      this.setState({
        input: query
      });
      this.doSearch(query, page);
    }
  }

  componentDidMount() {
    const { query, page } = this.props;
    this.doSearch(query, page);

    // Cursor get positioned at the begining of the input, moving it to the end.
    // For some reason it crashes the test, disabling.
    if (!this.props.testing) {
      process.nextTick(() =>
        moveCursorToEnd(
          window.document.querySelectorAll(".search-input input")[0]
        )
      );
    }
  }

  isFoundUpdated() {
    return (
      this.props.query === this.state.found.query &&
      this.props.page === this.state.found.page
    );
  }

  doSearch = async (query: string, page: number) => {
    const onError = (e: any) => {
      if (query === this.props.query && page === this.props.page) {
        logger.error(e);
        track("SearchPage.Result: Found", {
          category: "error",
          element: "search",
          queryLength: query.length,
          page
        });
        const error = getErrorMessage(e);
        this.setState({
          found: {
            services: [],
            tags: [],
            categories: [],
            query,
            page,
            hasMore: false,
            error
          }
        });
      }
    };

    // Querying
    if (query === "") {
      this.setState({
        found: {
          services: [],
          tags: [],
          categories: [],
          query,
          page,
          hasMore: false,
          error: null
        }
      });
    } else {
      const result = await this.props.data!.refetch({
        query: query.replace(/(\/)/g, "\\/"),
        filter: this.props.type,
        first: perPage + 1,
        cursor: Buffer.from(((page - 1) * perPage).toString()).toString(
          "base64"
        )
      });
      if (query === this.props.query && page === this.props.page) {
        if (result && !result.errors) {
          let results = result.data || this.props.data;
          const data = results.search;
          let hasMore = data.pageInfo.hasNextPage;
          const entries = data.edges || [];
          track("SearchPage.Result: Found", {
            category: "info",
            element: "search",
            queryLength: query.length,
            count: entries.length,
            page,
            hasMore: entries.length > perPage
          });

          let newState: any = {
            found: {
              services: [],
              tags: [],
              query,
              page,
              hasMore,
              error: null
            }
          };
          newState.found[this.props.type] = entries;
          this.setState(newState);
        } else if (result && result.errors) {
          onError(result.errors);
        }
      }
    }
  };

  onPreviousPage = (e: any) => {
    e.preventDefault();
    this.goToPage(this.props.page - 1);
  };

  onNextPage = (e: any) => {
    e.preventDefault();
    this.goToPage(this.props.page + 1);
  };

  render() {
    const isEmpty =
      (this.props.type === "tags"
        ? this.state.found.tags.length
        : this.props.type === "services"
        ? this.state.found.services.length
        : this.state.found.categories.length) === 0;
    const helpLink = (
      <HelpLink href="#" onClick={this.showHelp}>
        <QuestionCircleIcon label="Search help" />
      </HelpLink>
    );

    return (
      <div>
        <SearchInputWrapper className="search-input-wrapper">
          <Palette.Form onSubmit={this.onSubmit}>
            <Palette.TextField
              autoFocus={true}
              required
              value={this.state.input}
              className="search-input"
              onChange={this.onChange}
              placeholder="Type something..."
            />
          </Palette.Form>
          {this.isFoundUpdated() ? (
            helpLink
          ) : (
            <SearchInputSpinner>
              <AkSpinner />
            </SearchInputSpinner>
          )}
        </SearchInputWrapper>

        {!this.state.found.error &&
        this.props.query !== "" &&
        isEmpty &&
        this.isFoundUpdated() ? (
          <Palette.GrayText bold>
            <h4>Found nothing, try another query...</h4>
          </Palette.GrayText>
        ) : null}

        {!this.state.found.error && this.props.type === "tags"
          ? this.renderTags()
          : null}
        {!this.state.found.error && this.props.type === "categories"
          ? this.renderCategories()
          : null}
        {!this.state.found.error && this.props.type === "services"
          ? this.renderServices()
          : null}

        {this.state.found.error ? (
          <ErrorMessage>
            Something went wrong, we are looking into it.
          </ErrorMessage>
        ) : null}

        {this.renderPagination()}

        {this.state.help && <SearchHelp close={this.hideHelp} />}
      </div>
    );
  }

  renderPagination() {
    const {
      found: { page, hasMore }
    } = this.state;

    const isWaiting = !this.isFoundUpdated();
    return page > 1 || hasMore ? (
      <PaginationDiv className="search-pagination">
        {page > 1 ? (
          isWaiting ? (
            <Palette.GrayText>Prev</Palette.GrayText>
          ) : (
            <a href="#" onClick={this.onPreviousPage}>
              Prev
            </a>
          )
        ) : null}
        <span>{page}</span>
        {hasMore ? (
          isWaiting ? (
            <Palette.GrayText>Next</Palette.GrayText>
          ) : (
            <a href="#" onClick={this.onNextPage}>
              Next
            </a>
          )
        ) : null}
      </PaginationDiv>
    ) : null;
  }

  renderServices() {
    const table = (
      <WaitingShadow isWaiting={!this.isFoundUpdated()}>
        <SearchResultsTable
          className="search-results"
          head={["", "", "Owner", "Team", "Platform"]}
          format={["small", "big", "small", "small", "small"]}
          cells={this.state.found.services
            .filter(service => service.node)
            .map((service, index) =>
              this.renderServiceRow(service.node, index)
            )}
        />
      </WaitingShadow>
    );
    return this.state.found.services.length > 0 ? table : null;
  }

  renderTags() {
    const table = (
      <WaitingShadow isWaiting={!this.isFoundUpdated()}>
        <SearchResultsTable
          className="search-results"
          cells={this.state.found.tags
            .filter(tag => tag.node)
            .map((tag, index) => this.renderTagRow(tag.node, index))}
        />
      </WaitingShadow>
    );
    return this.state.found.tags.length > 0 ? table : null;
  }

  renderCategories() {
    const table = (
      <WaitingShadow isWaiting={!this.isFoundUpdated()}>
        <SearchResultsTable
          className="search-results"
          cells={this.state.found.categories
            .filter(category => category.node)
            .map((category, index) =>
              this.renderCategoryRow(category.node, index)
            )}
        />
      </WaitingShadow>
    );
    return this.state.found.categories.length > 0 ? table : null;
  }

  renderCategoryRow({ id, name, displayName }: Category, index: number) {
    const nameEl = (
      <span key={id}>
        <Palette.Link
          to={categoryPath(encodeURIComponent(id))}
          onClick={(e: any) => {
            e.preventDefault();
            this.navigateToAndScrollToTop(categoryPath(encodeURIComponent(id)));
            const repicrocalRank =
              1 / (perPage * (this.props.page - 1) + index + 1);
            track("SearchPage.Result: Click", {
              category: "info",
              element: "SearchCategory",
              repicrocalRank
            });
          }}
        >
          <SearchResultName className="search-result-name">
            {displayName || name}
          </SearchResultName>
        </Palette.Link>
      </span>
    );

    return [nameEl];
  }

  renderTagRow({ name }: TagForSearch, index: number) {
    const url = advancedSearchPath("services", `tags:${name}`, 1);
    const nameEl = (
      <span>
        <Palette.Link
          to={url}
          className="search-tag-name"
          onClick={(e: any) => {
            e.preventDefault();
            this.navigateToAndScrollToTop(url);
            const repicrocalRank =
              1 / (perPage * (this.props.page - 1) + index + 1);
            track("SearchPage.Result: Click", {
              category: "info",
              element: "SearchTag",
              repicrocalRank
            });
          }}
        >
          <SearchResultName className="search-result-name">
            {name}
          </SearchResultName>
        </Palette.Link>
      </span>
    );

    return [nameEl];
  }

  renderServiceRow({ id, metadata }: ServiceForSearch, index: number) {
    const {
      tags,
      contactInfo: { team },
      platform,
      owner
    } = metadata
      ? metadata
      : {
          tags: [],
          contactInfo: { team: "" },
          platform: "",
          owner: { id: "" }
        };

    const nameEl = (
      <span key={id}>
        <Palette.Link
          to={servicePath(encodeURIComponent(id))}
          onClick={(e: any) => {
            e.preventDefault();
            this.navigateToAndScrollToTop(servicePath(encodeURIComponent(id)));
            const repicrocalRank =
              1 / (perPage * (this.props.page - 1) + index + 1);
            track("SearchPage.Result: Click", {
              category: "info",
              element: "SearchService",
              repicrocalRank
            });
          }}
        >
          <Palette.Icon>
            <AkAddonIcon label="Service" />
          </Palette.Icon>
          <span className="search-result-name">{id}</span>
        </Palette.Link>
      </span>
    );

    const ownerEl = (
      <Palette.Link to={userUrl(owner.id)} target="_blank">
        <Palette.Icon>
          <AkPersonIcon label="User" />
        </Palette.Icon>
        {owner.id}
      </Palette.Link>
    );

    return [nameEl, ownerEl, team, platform];
  }

  navigateToAndScrollToTop(url: string) {
    this.props.history.push(url);
    window.scroll(0, 0);
  }

  showHelp = (e: any) => {
    e.preventDefault();
    this.setState({ help: true });
  };

  hideHelp = () => this.setState({ help: false });
}

export const SearchV2 = compose(
  graphql(searchQueryV2, {
    options: (props: SearchProps) => ({
      errorPolicy: "all",
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      context: {
        debounceKey: "SearchV2"
      },
      skip: props.query === "",
      variables: {
        query: "",
        first: 0
      }
    })
  }),
  withApollo,
  withRouter
)(SearchView);

// Copied from https://davidwalsh.name/caret-end
function moveCursorToEnd(el: any) {
  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  } else if (typeof el.createTextRange != "undefined") {
    el.focus();
    let range = el.createTextRange();
    range.collapse(false);
    range.select();
  }
}
