export function homePath() {
  return "/";
}
export function servicePath(id: string) {
  return `/services/${id}`;
}
export function categoryPath(id: string) {
  return `/categories/${id}`;
}
export function reportsPath() {
  return "/reports";
}
export function reportPath(name: string) {
  return `/reports/${name}`;
}

export function userUrl(username: string) {
  return `https://directory.prod.atl-paas.net/employees/${username}`;
}

export function buildUrl(url: string, params: { [key: string]: string }) {
  let query = [];
  for (let key in params) {
    query.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]));
  }
  return url + (query.length > 0 ? "?" + query.join("&") : "");
}
