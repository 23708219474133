import * as React from "react";
import { PageLayout } from "./layout/page-layout";
import { AnyComponent } from "../core/helpers";

export const withServiceNotFoundHandler = (Component: AnyComponent): any =>
  class WithServiceNotFoundHandler extends React.Component<any, any> {
    render() {
      if (this.props.data.service) {
        return <Component {...this.props} />;
      } else {
        return <PageLayout title="Service Not Found" />;
      }
    }
  };
