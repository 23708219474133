import { InMemoryCache } from "apollo-cache-inmemory";

export const themeResolvers = {
  Mutation: {
    updateTheme: (
      _: any,
      { input }: any,
      { cache }: { cache: InMemoryCache }
    ) => {
      localStorage.setItem("theme", JSON.stringify(input));
      const data = {
        theme: {
          ...input,
          __typename: "Theme"
        }
      };
      cache.writeData({ data });
      return null;
    }
  }
};
