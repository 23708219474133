import { State, buildInitialState } from "./private-state";
import { Listener } from "./lib/connect";

export interface PluginSystemConfig {
  groupWeights: { [key: string]: number | undefined };
  widgets: { [key: string]: any };
}

export function initialPluginSystemConfig(
  props: Partial<PluginSystemConfig>
): PluginSystemConfig {
  const init: PluginSystemConfig = {
    groupWeights: {},
    widgets: {}
  };
  return { ...init, ...props };
}

// Global object, private state.
export interface Globals {
  readonly state: State;
  readonly stateListeners: Listener[];

  pluginSystem: PluginSystemConfig;

  // Used by plugins, like widget history
  [key: string]: any;
}

export function setGlobals(globals: Globals) {
  (window as any).appGlobals = globals;
}

export function getGlobals() {
  if (!("appGlobals" in window)) {
    setGlobals({
      state: buildInitialState(null),
      stateListeners: [],
      pluginSystem: initialPluginSystemConfig({})
    });
  }

  return (window as any).appGlobals as Globals;
}
