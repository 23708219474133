import * as React from "react";
import styled from "styled-components";
const { colors, gridSize } = require("@atlaskit/theme");

interface PropertyRowProps {
  title: string;
}
export class PropertyRow extends React.Component<PropertyRowProps> {
  render() {
    const { title, children } = this.props;
    return (
      <PropertyRowContainer>
        <PropertyLabel>{title + ":"}</PropertyLabel>
        <PropertyValue>{children}</PropertyValue>
      </PropertyRowContainer>
    );
  }
}

export const PropertiesContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${gridSize() * 3}px;
  padding-top: ${gridSize() * 1.5}px;

  @media (min-width: 780px) {
    padding-top: ${gridSize() * 3}px;
  }
`;

const PropertyRowContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  padding: 0.4em 0;

  @media (min-width: 780px) {
    flex-direction: row;
  }
`;

const PropertyLabel = styled.div`
  color: ${colors.subtleText};
  flex-basis: 25%;
`;

const PropertyValue = styled.div`
  flex: 1 0 auto;
`;
