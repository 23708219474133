import * as React from "react";
import { PluginConfig } from "@atlassiansox/microscopekit";

import ServiceCentralPlugin from "@atlassiansox/service-central-microscope-plugin";
import ReleaseTrackingPlugin from "@atlassiansox/release-tracking-microscope-plugin";
import DataClassificationPlugin from "@atlassiansox/data-classification-microscope-plugin";
import MicrosPlugin from "@atlassiansox/micros-plugin-microscope-plugin";
import ResiliencePlugin from "@atlassiansox/resilience-microscope-plugin";

export interface PluginProps {
  plugins: PluginConfig[];
  devPlugin?: PluginConfig;
}

export const plugins: React.ComponentType[] = [
  ServiceCentralPlugin,
  ReleaseTrackingPlugin,
  DataClassificationPlugin,
  MicrosPlugin,
  ResiliencePlugin
];
