import * as React from "react";
import Popup from "@atlaskit/popup";
import { Help } from "@atlaskit/atlassian-navigation";
import { config } from "../../config";
import { ButtonItem } from "@atlaskit/menu";

export class HelpDropdown extends React.Component<{}, { isOpen: boolean }> {
  state = {
    isOpen: false
  };

  render() {
    const { isOpen } = this.state;
    return (
      <>
        <Popup
          content={() => (
            <>
              <ButtonItem>App Version: {config.clientGitHash}</ButtonItem>
            </>
          )}
          isOpen={isOpen}
          placement="bottom-start"
          onClose={() => this.setState({ isOpen: false })}
          trigger={triggerProps => (
            <Help
              isSelected={isOpen}
              onClick={() => this.setState({ isOpen: !isOpen })}
              tooltip="Help"
              {...triggerProps}
            />
          )}
        />
      </>
    );
  }
}
