import * as React from "react";
import microscopeIcon from "./microscope-icon.svg";
const SVG = require("react-inlinesvg").default;
const Icon = require("@atlaskit/icon").default;

interface Props {
  label: string;
  primaryColor?: string;
  secondaryColor?: string;
  size?: string;
}

const svg = <SVG src={microscopeIcon} />;

export class MicroscopeIcon extends React.Component<Props> {
  render() {
    return <Icon size="medium" glyph={() => svg} {...this.props} />;
  }
}
