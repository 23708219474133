export const noName = "Policy needs a name";
export const invalidName =
  "Policy names can only have spaces, letters, numbers, hyphens, underscores, and periods.";
export const maxNameLength = 72;
export const nameTooLong =
  "Policy names can only have max of " + maxNameLength + " characters.";
export const trailingWhitespace =
  "Policy names cannot have trailing whitespace.";

// Returns any potential errors in string form
export const checkValidName = (name: string) => {
  let regexp = /^[a-zA-Z0-9-_. ]+$/;
  let allErr = "";

  const appendErr = (newErr: string) => {
    if (allErr !== "") {
      allErr += " ";
      allErr += newErr;
    } else {
      allErr += newErr;
    }
  };

  if (!name) {
    allErr += noName;
  } else {
    if (name.trim() !== name) {
      appendErr(trailingWhitespace);
    }
    if (name.search(regexp) === -1) {
      appendErr(invalidName);
    }
    if (name.length > maxNameLength) {
      appendErr(nameTooLong);
    }
  }

  return allErr === "" ? undefined : allErr;
};
