import * as _ from "lodash";
import * as React from "react";
import * as Palette from "../../../palette";
import { PageLayout } from "../../../palette/layout/page-layout";
import { logger } from "../../../core";
import {
  isSufficientPermission,
  returnErrString,
  getErrorMessage,
  isForbiddenError
} from "../../../core/helpers";
import { noMetadataMessage } from "./helpers";
import { withLoadingSpinner } from "../../../palette/with-loading-spinner";
import { withServiceNotFoundHandler } from "../../../palette/with-service-not-found-handler";
import serviceDependenciesQuery from "../models/service-dependencies.query.graphql";
import createServiceDependencyMutation from "../models/create-service-dependency.mutation.graphql";
import deleteServiceDependencyMutation from "../models/delete-service-dependency.mutation.graphql";
import { withGraphqlErrorFlags } from "../../../palette/with-graphql-error-flags";
import {
  FlagProps,
  withShowFlag,
  compose,
  ChildProps,
  graphql,
  MutationFunc
} from "@atlassiansox/microscopekit";
import { servicePath } from "../../../core/routes";
import { ServiceDependencyModal } from "./service-dependencies-modal";
import styled from "styled-components";
import { serviceDependencies } from "../models/__generated__/serviceDependencies";
import { SSAMAnnouncement } from "./ssam-announcement";
import { Permission } from "../../../typings";
import {
  deleteServiceDependency,
  deleteServiceDependencyVariables
} from "../models/__generated__/deleteServiceDependency";
import {
  createServiceDependency,
  createServiceDependencyVariables
} from "../models/__generated__/createServiceDependency";
import { Tc2Error } from "../../../core/components/tc2";

const AkAddonIcon = require("@atlaskit/icon/glyph/addon").default;
const serviceIcon = <AkAddonIcon label="Service" />;
const { ButtonGroup } = require("@atlaskit/button");
const AkSpinner = require("@atlaskit/spinner").default;

export class FormError extends Error {
  errors: Error[];
  constructor(errors: Error[]) {
    super();
    this.errors = errors;
  }
}

const ServiceIconSpan = styled.span`
  position: relative;
  top: 2px;
  margin-right: 0.5rem;
`;
const ServiceNameSpan = styled.span`
  position: relative;
  top: -3px;
`;
ServiceNameSpan.displayName = "ServiceNameSpan";
const DeleteButtonSpan = styled.span`
  float: right;
`;

type ServiceDependenciesProps = ChildProps<
  {
    createServiceDependency: MutationFunc<
      createServiceDependency,
      createServiceDependencyVariables
    >;
    deleteServiceDependency: MutationFunc<
      deleteServiceDependency,
      deleteServiceDependencyVariables
    >;
  } & FlagProps,
  serviceDependencies
>;

interface State {
  addInProgress: boolean;
  deleteInProgress: { [key: string]: boolean };
  addError?: string;
  showAddModal?: boolean;
  showConfirmationFor: string | null;
}

export class ServiceDependenciesPageView extends React.Component<
  ServiceDependenciesProps,
  State
> {
  constructor(props: ServiceDependenciesProps) {
    super(props);
    this.state = {
      addInProgress: false,
      deleteInProgress: {},
      showConfirmationFor: null
    };
  }

  componentDidMount() {
    document.title = this.props.data!.service!.id + " | Dependencies";
  }

  componentWillUnmount() {
    document.title = "Microscope";
  }

  toggleModal = () => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  render() {
    const service = this.props.data!.service!;
    const { metadata, dependencies } = service;
    if (!metadata) {
      return noMetadataMessage("Dependencies");
    }
    const hasPerms = isSufficientPermission(
      service.currentUserPermission,
      Permission.Write
    );
    const noSSAMSet = !metadata.ssamContainer && hasPerms;

    let actions: JSX.Element = <span />;

    if (!noSSAMSet && hasPerms) {
      actions = (
        <ButtonGroup>
          <Palette.Button
            key="add-dependency"
            onClick={() => {
              this.setState({ showAddModal: true });
            }}
          >
            Add Dependency
          </Palette.Button>
        </ButtonGroup>
      );
    }

    const dependsOn = dependencies.upstream
      .map(({ upstream }) => upstream.id)
      .sort();
    const dependentServices = dependencies.downstream
      .map(({ downstream }) => downstream.id)
      .sort();

    return (
      <PageLayout title="Dependencies" actions={actions}>
        {noSSAMSet && (
          <SSAMAnnouncement
            service={{
              id: service.id,
              currentUserPermission: service.currentUserPermission
            }}
          />
        )}
        <span key="add modal">
          {this.state.showAddModal && (
            <ServiceDependencyModal
              closeModal={this.toggleModal}
              addDependencies={this.addDependency}
            />
          )}
        </span>

        <Palette.Table
          title={metadata.serviceName + " depends on these services"}
          format={["big", "small"]}
          cells={dependsOn.map(id => [
            <Palette.Link to={servicePath(id)}>
              <ServiceIconSpan>{serviceIcon}</ServiceIconSpan>
              <ServiceNameSpan className="service-dependency-name">
                {id}
              </ServiceNameSpan>
            </Palette.Link>,
            !noSSAMSet && hasPerms ? (
              <DeleteButtonSpan key={id}>
                <Palette.Button
                  className="delete-dependency"
                  onClick={this.onDelete(id)}
                  waiting={this.state.deleteInProgress[id]}
                  iconBefore={
                    this.state.deleteInProgress[id] ? (
                      <AkSpinner size="small" isCompleting={false} />
                    ) : (
                      undefined
                    )
                  }
                >
                  Delete
                </Palette.Button>
              </DeleteButtonSpan>
            ) : (
              <span />
            )
          ])}
          emptyMessage="There are no dependencies"
        />

        <br />
        <Palette.Table
          title={"These services depend on " + metadata.serviceName}
          format={["big"]}
          cells={dependentServices.map(id => [
            <Palette.Link to={servicePath(id)}>
              <ServiceIconSpan>{serviceIcon}</ServiceIconSpan>
              <ServiceNameSpan className="dependent-service-name">
                {id}
              </ServiceNameSpan>
            </Palette.Link>
          ])}
          emptyMessage="There are no dependent services"
        />

        {this.renderConfirmation()}
      </PageLayout>
    );
  }

  protected onDelete(dependencyId: string) {
    return () => {
      const { id } = this.props.data!.service!;
      track(
        "service-central.ServiceDependenciesPageView: delete service dependency",
        {
          category: "info",
          element: "ServiceDependenciesPageView",
          serviceId: id
        }
      );
      this.setState({ showConfirmationFor: dependencyId });
    };
  }

  protected async deleteDependency(dependencyServiceId: string) {
    const { id } = this.props.data!.service!;

    try {
      this.setState(current => {
        let deleteInProgress = { ...current.deleteInProgress };
        deleteInProgress[dependencyServiceId] = true;
        return { deleteInProgress };
      });

      await this.props.deleteServiceDependency({
        variables: { input: { downstream: id, upstream: dependencyServiceId } }
      });

      track(
        "service-central.ServiceDependenciesPageView: delete service dependency successfully finished",
        {
          category: "info",
          element: "ServiceDependenciesPageView",
          serviceId: id
        }
      );
      this.props.showFlag({
        type: "success",
        title: "Deleted Dependency",
        message: `Dependency "${dependencyServiceId}" deleted`
      });
    } catch (e) {
      track(
        "service-central.ServiceDependenciesPageView: delete service dependency failed",
        {
          category: "error",
          element: "ServiceDependenciesPageView",
          serviceId: id
        }
      );
      logger.error(e);
      const errMessage = getErrorMessage(e);
      const errComponent = isForbiddenError(errMessage) ? (
        <Tc2Error serviceId={id} />
      ) : (
        <p>{errMessage}</p>
      );
      this.props.showFlag({
        type: "error",
        title: "Failed to delete dependency",
        message: errComponent,
        disableAutoDismiss: true
      });
    }

    this.setState(current => {
      let deleteInProgress = { ...current.deleteInProgress };
      delete deleteInProgress[dependencyServiceId];
      return { deleteInProgress };
    });
  }

  renderConfirmation() {
    const { id } = this.props.data!.service!;
    const dependencyId = this.state.showConfirmationFor;
    if (dependencyId) {
      const hideConfirmation = () => {
        track(
          "service-central.ServiceDependenciesPageView: delete service dependency cancelled",
          {
            category: "info",
            element: "ServiceDependenciesPageView",
            serviceId: id
          }
        );
        this.setState({ showConfirmationFor: null });
      };

      const proceed = () => {
        this.deleteDependency(dependencyId);
        this.setState({ showConfirmationFor: null });
      };

      return (
        <Palette.Modal
          heading="Confirmation"
          actions={[
            { text: "Delete", onClick: proceed },
            { text: "Cancel", onClick: hideConfirmation }
          ]}
          onClose={hideConfirmation}
        >
          Delete dependency "{dependencyId}
          "?
        </Palette.Modal>
      );
    } else {
      return null;
    }
  }

  addDependency = async (serviceIdTo: string) => {
    const { id } = this.props.data!.service!;
    if (_.isEmpty(serviceIdTo)) {
      throw new FormError([new Error("Service Name cannot be empty")]);
    }
    track(
      "service-central.ServiceDependenciesPageView: add service dependency",
      { category: "info", element: "ServiceDependenciesPageView" }
    );
    try {
      this.setState({ addInProgress: true });
      await this.props.createServiceDependency({
        variables: { input: { downstream: id, upstream: serviceIdTo } }
      });
      this.setState({ addInProgress: false, addError: undefined });
      track(
        "service-central.ServiceDependenciesPageView: add service dependency successfully finished",
        { category: "info", element: "ServiceDependenciesPageView" }
      );
      this.props.showFlag({
        type: "success",
        title: "Added Dependency",
        message: `Added dependency "${serviceIdTo}"`
      });
    } catch ({ graphQLErrors, message }) {
      track(
        "service-central.ServiceDependenciesPageView: add service dependency failed",
        { category: "error", element: "ServiceDependenciesPageView" }
      );
      this.setState({
        addInProgress: false,
        addError: returnErrString(graphQLErrors, message)
      });
      const errMessage = getErrorMessage({ graphQLErrors, message });
      const errComponent = isForbiddenError(errMessage) ? (
        <Tc2Error serviceId={id} />
      ) : (
        <p>{errMessage}</p>
      );
      this.props.showFlag({
        type: "error",
        title: "Failed to add dependency",
        message: errComponent,
        disableAutoDismiss: true
      });
      throw new FormError(graphQLErrors);
    }
  };
}

export const ServiceDependenciesPage = compose(
  graphql(createServiceDependencyMutation, {
    name: "createServiceDependency"
  }),
  graphql(deleteServiceDependencyMutation, {
    name: "deleteServiceDependency"
  }),
  graphql(serviceDependenciesQuery, {
    options: () => ({
      errorPolicy: "all"
    })
  }),
  withLoadingSpinner,
  withShowFlag,
  withGraphqlErrorFlags,
  withServiceNotFoundHandler
)(ServiceDependenciesPageView);
