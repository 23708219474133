import * as React from "react";
import { AnyComponent } from "../helpers";

// Return type is any because typescript worries that the return type wont function the same way as the passed in component
export const withMountTracking = (componentId: string) => (
  Component: AnyComponent
): any =>
  class MountTracker extends React.Component {
    static displayName = Component.name;
    componentWillMount() {
      track(componentId + ": Mounted", { category: "info" });
    }

    componentWillUnmount() {
      track(componentId + ": Unmounted", { category: "info" });
    }

    render() {
      return <Component {...this.props} />;
    }
  };
