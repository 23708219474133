import * as React from "react";

type Props = { serviceId: string };

export class Tc2Error extends React.Component<Props> {
  render() {
    return (
      <p>
        You must be in the "Micros Just-In-Time" access level in{" "}
        <a
          href={`https://ssam.us-east-1.prod.apd-paas.net/containers/micros-sv--${this.props.serviceId}/#jit`}
          target="_blank"
          rel="noopener noreferrer"
        >
          your service's SSAM container
        </a>{" "}
        to perform this operation, see{" "}
        <a
          href={"https://go.atlassian.com/micros-tc2-compliance"}
          target="_blank"
          rel="noopener noreferrer"
        >
          go/micros-tc2-compliance
        </a>{" "}
        for details.
        <br />
        <br />
        <i>403: Forbidden</i>
      </p>
    );
  }
}
