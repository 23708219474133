import * as React from "react";
import {
  withLoadingSpinner,
  MarginLeftSpan,
  Icon,
  Link
} from "../../../palette";
import {
  teamServices,
  teamServices_user_services_edges,
  teamServices_user_services_edges_node
} from "../models/__generated__/teamServices";
import teamServicesQuery from "../models/team-services.query.graphql";
import { getCurrentUser } from "../../../core";
import { isNullOrUndefined } from "util";
import styled from "styled-components";
import {
  withWidgetControls,
  graphql,
  ChildProps,
  compose
} from "@atlassiansox/microscopekit";

const { gridSize } = require("@atlaskit/theme");
const AkTooltip = require("@atlaskit/tooltip").default;
const AkAddonIcon = require("@atlaskit/icon/glyph/addon").default;

const TeamServicesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
TeamServicesContainer.displayName = "TeamServicesContainer";

const ServiceNameDiv = styled.div`
  min-width: 60%;
  max-width: 60%;
`;

const StyledLink = styled(Link)`
  margin-left: ${gridSize()}px;
  height: ${gridSize() * 4}px;
  line-height: ${gridSize() * 4}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;
StyledLink.displayName = "StyledLink";

type ServiceEdge = teamServices_user_services_edges | null;
type ServiceNode = teamServices_user_services_edges_node | null;

export class TeamServicesWidgetView extends React.Component<
  ChildProps<{}, teamServices>
> {
  render() {
    const teamServices = this.props.data!.user!.services;
    if (
      isNullOrUndefined(teamServices) ||
      isNullOrUndefined(teamServices.edges)
    ) {
      return <div>Failed to fetch services.</div>;
    }

    const edges = teamServices.edges.filter(
      (edge: ServiceEdge) => !!(edge && edge.node)
    );
    const services = edges.map((edge: ServiceEdge) => edge!.node);
    return (
      <div>
        {services.length === 0 ? (
          <div>No services found.</div>
        ) : (
          services.map((service: ServiceNode) => (
            <TeamServicesContainer key={`TeamServicesContainer-${service?.id}`}>
              <ServiceNameDiv>
                <AkTooltip content={service!.id}>
                  <StyledLink
                    to={`/services/${encodeURIComponent(service!.id)}`}
                  >
                    <MarginLeftSpan margin="3px">
                      <Icon>
                        <AkAddonIcon label="Service" />
                      </Icon>
                    </MarginLeftSpan>
                    <span className="search-result-name">{service!.id}</span>
                  </StyledLink>
                </AkTooltip>
              </ServiceNameDiv>
            </TeamServicesContainer>
          ))
        )}
      </div>
    );
  }
}

export const TeamServicesWidget = compose(
  graphql(teamServicesQuery, {
    options: () => ({
      errorPolicy: "all",
      variables: {
        id: getCurrentUser().username,
        first: 100
      }
    })
  }),
  withLoadingSpinner,
  withWidgetControls("Team Services")
)(TeamServicesWidgetView);
