const storedDashboardConfig = localStorage.getItem("dashboardConfig");

export const dashboardDefaults = {
  dashboardConfig: storedDashboardConfig
    ? JSON.parse(storedDashboardConfig)
    : {
        __typename: "DashboardConfig",
        columnOne: { __typename: "DashboardColumn", widgets: [] },
        columnTwo: { __typename: "DashboardColumn", widgets: [] },
        columnThree: { __typename: "DashboardColumn", widgets: [] }
      }
};
