
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }

      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "Admin2",
        "possibleTypes": [
          {
            "name": "Group"
          },
          {
            "name": "User"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MicrosEnvironments",
        "possibleTypes": [
          {
            "name": "MicrosStaticEnvironments"
          },
          {
            "name": "MicrosServiceEnvironments"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "MicrosServiceDeployment",
        "possibleTypes": [
          {
            "name": "MicrosServiceDeploymentRollout"
          },
          {
            "name": "MicrosServiceDeploymentNoRollout"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MicrosRoutingPolicyDetail",
        "possibleTypes": [
          {
            "name": "MicrosFailoverRoutingPolicy"
          },
          {
            "name": "MicrosWeightedRoutingPolicy"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MicrosServiceType",
        "possibleTypes": [
          {
            "name": "MicrosEc2"
          },
          {
            "name": "MicrosKubeCompute"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchResult",
        "possibleTypes": [
          {
            "name": "Service"
          },
          {
            "name": "Tag"
          },
          {
            "name": "Category"
          },
          {
            "name": "CompliancePolicy2"
          }
        ]
      }
    ]
  }
};

      export default result;
    