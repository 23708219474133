import "./core";
import ReactDOM from "react-dom";
import * as React from "react";
import config from "./core/private/config";
import { getState } from "./core/private/private-state";
import { setGlobals, getGlobals } from "./core/private/globals";
import * as auth from "./core/private/lib/auth";
import { setCurrentUser } from "./core/private/private-actions";
import "@atlaskit/css-reset";
import {
  DrawerContextProvider,
  PluginContext,
  Plugin,
  AnalyticsContextProvider,
  UserContextProvider,
  ThemeContextProvider,
  FlagProvider,
  ApolloProvider,
  graphql,
  compose,
  ChildProps
} from "@atlassiansox/microscopekit";
import { ApolloProvider as ApolloProviderHooks } from "@apollo/react-hooks"; // tslint:disable-line
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AppRoutes } from "./app-routes";
import { RouteChangeListener } from "./core/private/components/navigation/route-change-listener";
import ApolloClient from "apollo-client";
import { plugins } from "./app-plugins";
import themeQuery from "./theme.query.graphql";
import { theme } from "./__generated__/theme";
import {
  patchMicroscopeLinksForCompass,
  setupChromelessMode
} from "./core/helpers";

const AkLayerManager = require("@atlaskit/layer-manager").default;

class Root extends React.Component {
  render() {
    return <Plugin id="root" />;
  }
}

export async function startMicroscope({
  DevPlugin,
  apolloClient
}: {
  DevPlugin?: React.ComponentType;
  apolloClient: ApolloClient<any>;
}) {
  // Don't start the app on this path so we can do setup for synthetic checks
  if (window.location.pathname === "/setCookie") {
    return;
  }

  let currentUser = getState().currentUser;

  // Blocking the rendering until we fetch user
  if (!currentUser) {
    try {
      currentUser = await auth.getUser();
    } catch (e) {
      auth.login();
      return;
    }
  }

  setGlobals({ ...getGlobals() });
  setCurrentUser(currentUser);
  setupChromelessMode();

  class AppView extends React.Component<ChildProps<{}, theme>> {
    componentDidMount() {
      patchMicroscopeLinksForCompass();
    }

    render() {
      const themeMode = this.props?.data?.theme?.mode ?? "light";
      return (
        <ThemeContextProvider mode={themeMode}>
          <FlagProvider>
            <DrawerContextProvider>
              <AkLayerManager>
                <Router>
                  <Switch>
                    <Route
                      render={() => (
                        <RouteChangeListener>
                          <PluginContext.Consumer>
                            {({ pluginConfigs, devPluginConfig }) => (
                              <AppRoutes
                                plugins={pluginConfigs}
                                devPlugin={devPluginConfig}
                              />
                            )}
                          </PluginContext.Consumer>
                        </RouteChangeListener>
                      )}
                    />
                  </Switch>
                </Router>
              </AkLayerManager>
            </DrawerContextProvider>
          </FlagProvider>
        </ThemeContextProvider>
      );
    }
  }

  const App = compose(graphql(themeQuery))(AppView);

  const [FirstComponent, ...restComponents] = [Root, ...plugins, App];

  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <ApolloProviderHooks client={apolloClient}>
        <AnalyticsContextProvider>
          <UserContextProvider value={{ user: currentUser }}>
            <PluginContext.Provider
              value={{
                pluginComponents: restComponents,
                DevPluginComponent: DevPlugin,
                pluginConfigs: []
              }}
            >
              <FirstComponent />
            </PluginContext.Provider>
          </UserContextProvider>
        </AnalyticsContextProvider>
      </ApolloProviderHooks>
    </ApolloProvider>,
    document.getElementById("app")
  );
}
