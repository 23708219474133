import * as React from "react";

export type Listener = () => void;

export function buildConnect<State, Actions>(
  getState: () => State,
  actions: Actions,
  subscribe: (listener: Listener) => () => void
) {
  return function connect<StateProps, ActionProps, OwnProps>(
    mapStateProps: StateProps | ((state: State) => StateProps),
    mapActionProps:
      | ActionProps
      | ((actions: Actions, state: State) => ActionProps),
    Element:
      | React.ComponentClass<StateProps & ActionProps & OwnProps>
      | React.StatelessComponent<StateProps & ActionProps & OwnProps>
  ): React.ComponentClass<OwnProps> {
    class ConnectedWrapper extends React.Component<OwnProps, StateProps> {
      private unsubscribe?: () => void;
      private currentStateSize = 0;
      private actionProps: ActionProps;

      constructor(props: OwnProps) {
        super(props);
        this.state =
          mapStateProps instanceof Function
            ? mapStateProps(getState())
            : mapStateProps;
        this.actionProps =
          mapActionProps instanceof Function
            ? mapActionProps(actions, getState())
            : mapActionProps;
        this.currentStateSize = Object.keys(this.state).length;
      }

      componentDidMount() {
        this.unsubscribe = subscribe(() => {
          const newProps =
            mapStateProps instanceof Function
              ? mapStateProps(getState())
              : mapStateProps;
          const currentState = this.state;

          // Checking for changes
          let changed = false,
            newSize = 0;
          for (const k in newProps) {
            if (newProps[k] !== currentState[k]) {
              changed = true;
            }
            newSize += 1;
          }
          changed = changed || newSize !== this.currentStateSize;
          this.currentStateSize = newSize;

          if (changed) {
            this.setState(newProps);
          }
        });
      }

      componentWillUnmount() {
        if (this.unsubscribe) {
          this.unsubscribe();
        }
      }

      render() {
        return (
          <Element {...this.props} {...this.actionProps} {...this.state} />
        );
      }
    }
    return ConnectedWrapper;
  };
}
