import * as React from "react";
import { graphql, ChildProps, compose } from "@atlassiansox/microscopekit";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { WithLoadingSpinner } from "../../../../palette/with-loading-spinner";
import { PageLayout } from "../../../../palette/layout/page-layout";
import { Column } from "./column";
import { Button } from "../../../../palette";
import { cloneDeep, remove, isEqual } from "lodash";
import styled from "styled-components";

import dashboardConfigQuery from "./dashboard-config.query.graphql";
import dashboardConfigMutation from "./dashboard-config.mutation.graphql";
import {
  dashboardConfig_dashboardConfig,
  dashboardConfig
} from "./__generated__/dashboardConfig";

const { ButtonGroup } = require("@atlaskit/button");

const FlexRow = styled.div`
  display: flex;
  flex: 1 1 0%;
  flex-direction: row;
`;
const MarginLeftPage = styled(PageLayout)`
  margin-left: auto;
`;
MarginLeftPage.displayName = "Page";

class HomeLoadingSpinner extends WithLoadingSpinner<dashboardConfig> {}

interface State {
  editMode: boolean;
}

interface Mutate {
  mutate(args: any): void;
}

type Props = ChildProps<{ widgets: any }, dashboardConfig>;

export class HomeView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { editMode: false };
  }

  componentWillReceiveProps(newProps: Props) {
    if (!newProps.data || !newProps.data.dashboardConfig) {
      return;
    }

    this.reconcileConfiguration(newProps.data.dashboardConfig);
  }

  saveConfiguration(newConfig: dashboardConfig_dashboardConfig) {
    if (!this.props.mutate) {
      return;
    }
    track("Home: Saved Configuration", { category: "info" });
    this.props.mutate({
      variables: {
        dashboardConfig: newConfig
      }
    });
  }

  reconcileConfiguration = (
    dashboardConfig: dashboardConfig_dashboardConfig
  ) => {
    const missingWidgets = new Set(Object.keys(this.props.widgets));
    const newConfig: dashboardConfig_dashboardConfig = cloneDeep(
      dashboardConfig
    );
    const { columnOne, columnTwo, columnThree } = newConfig;
    [columnOne, columnTwo, columnThree].forEach(column => {
      column.widgets.forEach(({ id }) => {
        missingWidgets.delete(id);
        if (!this.props.widgets[id]) {
          remove(column.widgets, { id });
        }
      });
    });
    missingWidgets.forEach(id => {
      newConfig.columnOne.widgets.push({
        id,
        enabled: true,
        __typename: "Widget"
      });
    });
    if (!isEqual(newConfig, dashboardConfig)) {
      this.saveConfiguration(newConfig);
    }
  };

  onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      !this.props.data ||
      !this.props.data.dashboardConfig
    ) {
      return;
    }
    const newConfig: dashboardConfig_dashboardConfig = cloneDeep(
      this.props.data.dashboardConfig
    );
    const { columnOne, columnTwo, columnThree } = newConfig;
    const element = [columnOne, columnTwo, columnThree][
      parseInt(result.source.droppableId)
    ].widgets.splice(result.source.index, 1)[0];
    [columnOne, columnTwo, columnThree][
      parseInt(result.destination.droppableId)
    ].widgets.splice(result.destination.index, 0, element);
    this.saveConfiguration(newConfig);
  };

  setWidgetEnabled = (column: number, widget: number, enabled: boolean) => {
    if (!this.props.data || !this.props.data.dashboardConfig) {
      return;
    }
    track(`Home: Set Widget Enabled to ${enabled}`, { category: "info" });
    const newConfig: dashboardConfig_dashboardConfig = cloneDeep(
      this.props.data.dashboardConfig
    );
    const { columnOne, columnTwo, columnThree } = newConfig;
    [columnOne, columnTwo, columnThree][column].widgets[
      widget
    ].enabled = enabled;
    this.saveConfiguration(newConfig);
  };

  renderDashboard = (data: dashboardConfig) => {
    const { columnOne, columnTwo, columnThree } = data.dashboardConfig;
    const widgets = [columnOne, columnTwo, columnThree].map(
      (column, cIndex) => (
        <Column id={`${cIndex}`} key={cIndex}>
          {column.widgets.map((widget, wIndex) => {
            const WidgetComponent = this.props.widgets[widget.id];
            if (WidgetComponent) {
              return (
                <WidgetComponent
                  key={widget.id}
                  widgetProps={{
                    id: widget.id,
                    editMode: this.state.editMode,
                    index: wIndex,
                    enabled: widget.enabled,
                    setEnabled: (enabled: boolean) =>
                      this.setWidgetEnabled(cIndex, wIndex, enabled)
                  }}
                />
              );
            } else {
              return null;
            }
          })}
        </Column>
      )
    );
    return widgets;
  };

  render() {
    const doneButton = (
      <Button
        appearance="primary"
        key="Done"
        onClick={() => this.setState({ editMode: false })}
      >
        Done
      </Button>
    );
    const editButton = (
      <Button key="Edit" onClick={() => this.setState({ editMode: true })}>
        Customize
      </Button>
    );
    const actions = (
      <ButtonGroup>{this.state.editMode ? doneButton : editButton}</ButtonGroup>
    );
    return (
      <MarginLeftPage title="Home" actions={actions}>
        <HomeLoadingSpinner data={this.props.data}>
          {(data: dashboardConfig) => (
            <DragDropContext onDragEnd={this.onDragEnd}>
              <FlexRow>{this.renderDashboard(data)}</FlexRow>
            </DragDropContext>
          )}
        </HomeLoadingSpinner>
      </MarginLeftPage>
    );
  }
}

export const Home = compose(
  graphql(dashboardConfigQuery),
  graphql(dashboardConfigMutation)
)(HomeView);
