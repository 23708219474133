import * as React from "react";
import { ButtonModal } from "@atlassiansox/microscopekit";
import styled from "styled-components";
import { colors, themed } from "@atlaskit/theme";

const AkToolTip = require("@atlaskit/tooltip").default;
const AkGlobalItem = require("@atlaskit/navigation").AkGlobalItem;
const QuestionCircleIcon = require("@atlaskit/icon/glyph/question-circle")
  .default;
const InlineMessage = require("@atlaskit/inline-message").default;

const fullDocsLink =
  "https://www.elastic.co/guide/en/elasticsearch/reference/6.3/query-dsl-query-string-query.html#query-string-syntax";

const TextfieldHelpMarkup = styled.div`
  line-height: 1.9rem;

  code {
    padding: 3px 9px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: ${themed({ light: colors.N30, dark: colors.N600 })};
    font-family: Monaco, "Courier New", Courier, monospace;
    border-radius: 3px;
    white-space: nowrap;
  }
`;

const ESHelpMarkup = styled.div`
  code {
    background-color: ${themed({ light: colors.N30, dark: colors.N600 })};
  }
`;

const QuestionIconStyled = styled.a`
  color: #aaa;
  :hover {
    color: #0052cc;
  }
`;

export class TextFieldSearchHelp extends React.Component {
  render() {
    return (
      <ButtonModal
        buttonText=""
        component={QuestionCircleGlobalIcon}
        heading="Search Help"
        actions={{
          close: {
            text: "Close",
            onClose: () => {}
          }
        }}
      >
        <TextfieldHelpMarkup>
          <p>
            All searches are <b>case-sensitive</b>
          </p>
          <p>
            Tag search <code>tags:mytag</code>
          </p>
          <p>
            Attribute search <code>owner:jraynor</code>
          </p>
          <p>
            Fuzzy search <code>owner:*john*</code>
          </p>
          <p>
            Combine queries <code>team:identity AND platform:micros</code> use{" "}
            <code>AND, OR, NOT and ()</code>
          </p>
          <p>
            Checkout the{" "}
            <a href={fullDocsLink} target="_blank" rel="noopener noreferrer">
              full doc
            </a>{" "}
            for list of all available elasticsearch query options.
          </p>
        </TextfieldHelpMarkup>
      </ButtonModal>
    );
  }
}

class QuestionCircleGlobalIcon extends React.Component {
  render() {
    return (
      <AkGlobalItem {...this.props}>
        <AkToolTip content="Search Help" position="bottom">
          <QuestionIconStyled>
            <QuestionCircleIcon label="question-circle-icon" />
          </QuestionIconStyled>
        </AkToolTip>
      </AkGlobalItem>
    );
  }
}

export class ElasticsearchHelp extends React.Component {
  render() {
    return (
      <ESHelpMarkup>
        <InlineMessage type="info">
          <h4>Wildcard Queries</h4>
          <p>
            Wildcard searches can be run on individual terms, using{" "}
            <code>?</code> to replace a single character, and <code>*</code> to
            replace zero or more characters
          </p>
          <p>
            Ex: <code>qu?ck bro*</code>
          </p>
          <p>
            Note: Mixing <code>fuzzy</code> and <code>wildcard</code> operators
            is not supported
          </p>
          <p>
            Checkout the{" "}
            <a href={fullDocsLink} target="_blank" rel="noopener noreferrer">
              full doc
            </a>{" "}
            for list of all available elasticsearch query options
          </p>
        </InlineMessage>
      </ESHelpMarkup>
    );
  }
}
