import { InMemoryCache } from "apollo-cache-inmemory";
import nestedNavigationQuery from "../../../nested-navigation-state.query.graphql";

export const navigationResolvers = {
  Mutation: {
    updateOpenDrawer: (
      _: any,
      { openDrawer }: { openDrawer: string },
      { cache }: { cache: InMemoryCache }
    ) => {
      const data = {
        navigationState: {
          __typename: "LocalState",
          openDrawer
        }
      };
      cache.writeData({ data });
      return null;
    },

    pushNavigationLevel: (
      _: any,
      { navigationLevel }: any,
      { cache }: { cache: InMemoryCache }
    ) => {
      const previous: any = cache.readQuery({
        query: nestedNavigationQuery
      });
      navigationLevel.__typename = "NavigationLevel";
      for (const link of navigationLevel.links) {
        link.__typename = "NavigationLink";
      }
      previous.navigationState.navigationLevels = previous.navigationState.navigationLevels.concat(
        [navigationLevel]
      );
      cache.writeData({ data: previous });
      return null;
    },
    popNavigationLevel: (
      _: any,
      __: any,
      { cache }: { cache: InMemoryCache }
    ) => {
      const previous: any = cache.readQuery({
        query: nestedNavigationQuery
      });
      previous.navigationState.navigationLevels = previous.navigationState.navigationLevels.slice(
        0,
        previous.navigationState.navigationLevels.length - 1
      );
      cache.writeData({ data: previous });
      return null;
    },
    clearNavigationLevels: (
      _: any,
      __: any,
      { cache }: { cache: InMemoryCache }
    ) => {
      cache.writeData({
        data: {
          navigationState: {
            __typename: "LocalState",
            navigationLevels: []
          }
        }
      });
      return null;
    }
  }
};
