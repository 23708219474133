import { servicePath } from "../../routes";
import { subscribe, getState } from "../../private/private-state";
import { getHistoryStorage } from "./models/history-storage";
export { HistoryWidget } from "./components/history-widget";

subscribe(() => {
  const activePage = getState().activePage;
  if (activePage && activePage.type === "service") {
    const { id } = activePage.service;
    getHistoryStorage().onNavigationEvent({
      title: id,
      url: servicePath(id),
      icon: "service"
    });
  }
});
