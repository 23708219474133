import * as React from "react";
const AkDynamicTable = require("@atlaskit/dynamic-table").default;

export interface RowCell {
  key?: string | number;
  content?: JSX.Element | string;
}

export interface TableHeadCell {
  key?: string | number;
  content: JSX.Element | string;
  isSortable?: boolean;
  width?: number;
  shouldTruncate?: boolean;
}

export interface TableRow {
  cells: RowCell[];
}

export interface TableHeadRow {
  cells: TableHeadCell[];
}

export interface DynamicTable {
  caption?: JSX.Element | string;
  rows?: TableRow[];
  isLoading?: boolean;
  isFixedSize?: boolean;
  rowsPerPage?: number;
  onSetPage?(page: number): void;
  onSort?(event: any): void;
  head?: TableHeadRow;
  defaultSortKey?: string;
  defaultSortOrder?: "ASC" | "DESC";
}
export const DynamicTable: React.StatelessComponent<DynamicTable> = ({
  caption,
  rows,
  isLoading,
  isFixedSize,
  rowsPerPage,
  onSetPage,
  onSort,
  head,
  defaultSortKey,
  defaultSortOrder
}) => (
  <AkDynamicTable
    defaultPage={1}
    caption={caption}
    rows={rows}
    isLoading={isLoading}
    isFixedSize={isFixedSize}
    rowsPerPage={rowsPerPage}
    onSetPage={onSetPage ? onSetPage : () => {}}
    onSort={onSort ? onSort : () => {}}
    head={head}
    defaultSortKey={defaultSortKey}
    defaultSortOrder={defaultSortOrder}
  />
);
