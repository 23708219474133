import * as React from "react";
import * as Palette from "../../../../palette";
import {
  IconType,
  History,
  getHistoryStorage
} from "../models/history-storage";
import { withWidgetControls, compose } from "@atlassiansox/microscopekit";

const AkAddonIcon = require("@atlaskit/icon/glyph/addon").default;

export interface HistoryProps {
  id: number;
}

const LIMIT = 5;
export class HistoryWidgetView extends React.Component<HistoryProps, History> {
  unsubscribe?: () => void;

  constructor(props: HistoryProps) {
    super(props);
    this.state = getHistoryStorage().getHistory(LIMIT);
  }

  componentDidMount() {
    this.unsubscribe = getHistoryStorage().subscribe(() => {
      this.setState(getHistoryStorage().getHistory(LIMIT));
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  render() {
    const { links } = this.state;
    if (!this.state.isReady) {
      return <Palette.Spinner />;
    } else if (this.state.error) {
      return <Palette.Code color="#BF2600">{this.state.error}</Palette.Code>;
    } else if (this.state.links.length === 0) {
      return <Palette.GrayText bold>History is empty</Palette.GrayText>;
    } else {
      return (
        <div>
          {links.map(({ title, url, icon }) => (
            <p key={url}>
              <Palette.Link to={url}>
                <Palette.MarginLeftSpan margin={"3px"}>
                  {getIcon(icon)}
                </Palette.MarginLeftSpan>
                <span className="search-result-name">{title}</span>
              </Palette.Link>
            </p>
          ))}
        </div>
      );
    }
  }
}

function getIcon(icon?: IconType) {
  switch (icon) {
    case undefined:
      return;
    case "service":
      return (
        <Palette.Icon>
          <AkAddonIcon label="Service" />
        </Palette.Icon>
      );
  }
}

export const HistoryWidget = compose(withWidgetControls("Recently viewed"))(
  HistoryWidgetView
);
