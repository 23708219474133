import * as React from "react";
import styled from "styled-components";
import { AnyComponent } from "../../core/helpers";

const Page = require("@atlaskit/page").default;
const PageHeader = require("@atlaskit/page-header").default;
const { Grid, GridColumn } = require("@atlaskit/page");

interface PageLayoutProps {
  title: string;
  actions?: AnyComponent | JSX.Element;
  side?: JSX.Element;
  breadcrumbs?: JSX.Element;
}

const Content = styled.div`
  margin: 0 20px 20px 20px;
`;

const FULL_WIDTH = 12;
const MAIN_CONTENT_WIDTH = 8;
const SIDEBAR_WIDTH = 4;

export class PageLayout extends React.Component<PageLayoutProps> {
  render() {
    const { title, side, actions, children, breadcrumbs } = this.props;

    return (
      <Page>
        <Content>
          <Grid>
            <GridColumn medium={FULL_WIDTH}>
              <PageHeader breadcrumbs={breadcrumbs} actions={actions}>
                {title}
              </PageHeader>
            </GridColumn>
          </Grid>
          <Grid>
            <GridColumn medium={side ? MAIN_CONTENT_WIDTH : FULL_WIDTH}>
              {children}
            </GridColumn>
            {side && <GridColumn medium={SIDEBAR_WIDTH}>{side}</GridColumn>}
          </Grid>
        </Content>
      </Page>
    );
  }
}
