import * as React from "react";
import createCompliancePolicyMutation from "../models/create-compliance-policy.mutation.graphql";
import { CompliancePolicyForm } from "./compliance-policy-form";
import { withMountTracking } from "../../../core/components/mount-tracker";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client/ApolloClient";
import { FetchResult } from "apollo-link";
import { createCompliancePolicy } from "../models/__generated__/createCompliancePolicy";
import complianceQuestions from "../models/compliance-question.query.graphql";
import { complianceQuestions as ComplianceQuestions } from "../models/__generated__/complianceQuestions";
import { compliancePolicy2 } from "../models/__generated__/compliancePolicy2";
import {
  withLoadingSpinner,
  FlagProps,
  withShowFlag,
  ChildProps,
  compose,
  graphql,
  withApollo
} from "@atlassiansox/microscopekit";
import { Omit } from "@atlaskit/type-helpers";
const AkSpinner = require("@atlaskit/spinner").default;

type CreateCompliancePolicyProps = ChildProps<
  FlagProps & {
    client: ApolloClient<InMemoryCache>;
  } & {
    closeModal: () => void;
    service: string; // Which services a newly created policy should be applied to
    updateServicePolicy: (
      serviceId: string,
      newPolicy: compliancePolicy2
    ) => Promise<any>;
  },
  ComplianceQuestions
>;

const title = "Create Compliance Policy";

export class CreateCompliancePolicyModalView extends React.Component<
  CreateCompliancePolicyProps,
  {}
> {
  // Called when the user confirms making the new policy
  createPolicy = async (
    _owner: string,
    name: string,
    input: { answer: string; questionID: string }[]
  ) => {
    const answers = input.map(a => ({
      questionID: a.questionID,
      answer: a.answer
    }));

    const promise = new Promise<compliancePolicy2>(async (resolve, reject) => {
      this.props.client
        .mutate({
          mutation: createCompliancePolicyMutation,
          variables: {
            input: {
              name,
              answers
            }
          }
        })
        .then(async (result: FetchResult<createCompliancePolicy>) => {
          const newPolicy = result.data!.createCompliancePolicy2;
          track("Compliance.CreateCompliancePolicyModal: PolicyCreated", {
            category: "info"
          });
          if (this.props.service) {
            // Make a call to associate the newly made policy with the selected services
            await this.props.updateServicePolicy(this.props.service, newPolicy);
          }
          resolve(newPolicy);
        })
        .catch(errors => {
          track("Compliance.CreateCompliancePolicyModal: CreateFailed", {
            category: "error"
          });
          // Display error messages in the form fields
          reject(errors);
        });
    });
    return promise;
  };

  render() {
    const complianceQuestions = this.props.data!.complianceQuestions;

    return (
      complianceQuestions && (
        <CompliancePolicyForm
          title={title}
          closeModal={this.props.closeModal}
          services={[this.props.service]}
          callback={this.createPolicy}
          questions={complianceQuestions}
        />
      )
    );
  }
}

// export const CreateCompliancePolicyModal = compose(
export const CreateCompliancePolicyModal: React.ComponentType<Omit<
  CreateCompliancePolicyProps,
  "showFlag" | "client"
>> = compose(
  graphql(complianceQuestions),
  withLoadingSpinner,
  withApollo,
  withMountTracking("CreateCompliancePolicyModal"),
  withShowFlag
)(CreateCompliancePolicyModalView);
CreateCompliancePolicyModal.displayName = "CreateCompliancePolicyModal";
