import * as React from "react";
import { compose } from "@atlassiansox/microscopekit";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
const { AkNavigationItem } = require("@atlaskit/navigation");

interface RouterLinkComponentProps {
  href: string;
  children?: any;
  onClick?: any;
  className?: string;
}

class RouterLinkComponent extends React.Component<RouterLinkComponentProps> {
  render() {
    const { href, children, onClick, className } = this.props;

    return (
      <Link className={className} onClick={onClick} to={href}>
        {children}
      </Link>
    );
  }
}

interface Props {
  href?: string;
  text?: string;
  isCompact?: boolean;
  isSelected?: boolean;
  icon?: any;
  onClick?: any;
}

// Comparing only path, without parameters
const samePaths = (a: string, b: string) =>
  a.replace(/\?.+/, "") === b.replace(/\?.+/, "");

export class NavigationItemView extends React.Component<
  Props & RouteComponentProps<any>
> {
  onClick = (...args: any[]) => {
    if (this.props.text) {
      track("NavigationItem: Click", {
        category: "info",
        label: this.props.text
      });
    }
    if (this.props.onClick) {
      this.props.onClick(...args);
    }
  };

  render() {
    return (
      <AkNavigationItem
        isSelected={
          this.props.href
            ? samePaths(this.props.href, this.props.location.pathname)
            : false
        }
        isCompact={true}
        linkComponent={this.props.href ? RouterLinkComponent : undefined}
        {...this.props}
        onClick={this.onClick}
      />
    );
  }
}

export const NavigationItem: React.ComponentType<Props> = compose(withRouter)(
  NavigationItemView
);
