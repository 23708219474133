import { ApolloClient } from "apollo-client";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher
} from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, from } from "apollo-link";
import DebounceLink from "apollo-link-debounce";
import { createLocalLink } from "../../schema";
import introspectionQueryResultData from "./fragmentTypes";

const DEBOUNCE_TIMEOUT = 1000;
export function buildApolloClient(uri: string) {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const cache = new InMemoryCache({ fragmentMatcher });
  const links = [
    createLocalLink(cache),
    new DebounceLink(DEBOUNCE_TIMEOUT),
    new HttpLink({
      uri,
      credentials: "include"
    })
  ];

  return new ApolloClient({
    link: from(links as ApolloLink[]),
    cache
  });
}
