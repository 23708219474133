import { InMemoryCache } from "apollo-cache-inmemory";

export const dashboardResolvers = {
  Mutation: {
    updateDashboardConfig: (
      _: any,
      { dashboardConfig }: any,
      { cache }: { cache: InMemoryCache }
    ) => {
      const { columnOne, columnTwo, columnThree } = dashboardConfig;
      [columnOne, columnTwo, columnThree].forEach(column => {
        column.widgets.forEach((widget: any) => {
          widget.__typename = "Widget";
        });
      });
      const data = {
        dashboardConfig
      };
      localStorage.setItem(
        "dashboardConfig",
        JSON.stringify(data.dashboardConfig)
      );
      cache.writeData({ data });
      return null;
    }
  }
};
