import * as React from "react";
import { ChildProps } from "@atlassiansox/microscopekit";
import { Spinner } from ".";
import { AnyComponent } from "../core/helpers";

export class WithLoadingSpinner<T> extends React.Component<
  ChildProps<{}, T>,
  never
> {
  render() {
    if (this.props.data && !this.props.data.loading) {
      const renderFunction: any = this.props.children;
      return renderFunction(this.props.data);
    } else {
      return <Spinner />;
    }
  }
}

export const withLoadingSpinner = (Component: AnyComponent): any =>
  class WithLoadingSpinner extends React.Component<any, any> {
    render() {
      if (this.props.data && !this.props.data.loading) {
        return <Component {...this.props} />;
      } else {
        return <Spinner />;
      }
    }
  };
