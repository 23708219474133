import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { goToAdvancedSearchPath } from "../routes";
import styled from "styled-components";
import AdvancedSearchQuery from "../components/advanced-search.query.graphql";
import { advancedSearch } from "../components/__generated__/advancedSearch";
import {
  PageLayout,
  Form,
  TextField,
  Spinner,
  EditableDynamicTable,
  EditableTableItem,
  Button,
  ChildProps,
  compose,
  withApollo,
  graphql
} from "@atlassiansox/microscopekit";
import { SearchResultsDisplay } from "../components/search-results-display";
import {
  TextFieldSearchHelp,
  ElasticsearchHelp
} from "../components/advanced-search-help";
import { isNullOrUndefined } from "util";
import {
  editableSearchFieldsWithValidation,
  defaultData,
  createNewField
} from "../components/es-fields";
import { reservedChars } from "../components/es-field-data";

const { parse } = require("query-string");

const BottomScrollListener = require("react-bottom-scroll-listener").default;
const Toggle = require("@atlaskit/toggle").default;
const ToolTip = require("@atlaskit/tooltip").default;

const ToggleStyled = styled.div`
  position: absolute;
  top: -55px;
  right: 6px;
  color: #aaa;
  :hover {
    color: #0052cc;
  }
`;

const SearchInputSpinner = styled.span`
  position: absolute;
  top: 30%;
  left: 50%;
  margin-top: 100px;
`;

const HelpLink = styled.a`
  position: absolute;
  top: 5px;
  right: 26px;
  }
`;

const ESHelpStyled = styled.a`
  position: absolute;
  top: -52px;
  left: 220px;
`;

const ButtonStyled = styled.a`
  position: relative;
  top: -32px;
  left: 765px;
`;

const OpKey = "operator";
const FieldTypeKey = "fieldType";
const FieldValKey = "fieldValue";
const ModEF = "modifierExactFuzzy";
const ModNotKey = "modifierNot";

type SearchProps = RouteComponentProps<any> & ChildProps<{}, advancedSearch>;

export interface SearchState {
  isChecked: boolean; // ref: toggle
}

const perPage = 25;
export class AdvancedSearchPage extends React.Component<
  SearchProps,
  SearchState
> {
  constructor(props: SearchProps) {
    super(props);
    this.state = {
      isChecked: false
    };
  }

  // Get next page of search results
  doSearch = async () => {
    try {
      if (!this.props.data!.search) {
        return null;
      } else {
        const previousSearch = this.props.data!.search;
        const previousEdges = previousSearch.edges;
        if (previousSearch.pageInfo.hasNextPage) {
          const result = await this.props.data!.fetchMore({
            variables: {
              first: perPage,
              after: Buffer.from(previousEdges!.length.toString()).toString(
                "base64"
              )
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult!.search) {
                return previousResult;
              }
              const { edges, pageInfo } = fetchMoreResult!.search;
              if (edges) {
                track("AdvancedSearchPage.Result: Found", {
                  category: "info",
                  element: "advanced-search",
                  queryLength: parse(this.props.location.search).query.length,
                  count: edges.length,
                  page: edges.length / perPage,
                  hasMore: edges.length > perPage
                });
              }
              return edges && edges.length
                ? {
                    search: {
                      __typename: previousResult.search!.__typename,
                      edges: [...previousResult.search!.edges, ...edges],
                      pageInfo
                    }
                  }
                : previousResult;
            }
          });
          return result;
        }
      }
    } catch (e) {
      track("AdvancedSearchPage.Result: Found", {
        category: "error",
        element: "advanced-search",
        queryLength: parse(this.props.location.search).query.length,
        pages: this.props.data!.search!.edges!.length / perPage
      });
      return e;
    }
  };

  onSubmit = (data: any) =>
    Promise.resolve().then(() => {
      const query = this.state.isChecked
        ? this.constructQuery(data)
        : data["search-input"];
      this.props.history.push(goToAdvancedSearchPath(query));
    });

  onToggle = () => {
    this.setState({
      isChecked: !this.state.isChecked
    });
  };

  /**
   * Builds elastic search query for fields given data from each cell
   */
  constructQuery = (data: any) => {
    let elasticsearchQuery = "";
    const fields = data["elastic search"];

    for (let i = 0; i < fields.length; i++) {
      const notModifier = fields[i][ModNotKey] ? "!" : "";

      let fieldVal = /\?|\*/.test(fields[i][FieldValKey])
        ? fields[i][FieldValKey]
        : JSON.stringify(fields[i][FieldValKey]);
      fieldVal = "(" + fieldVal + fields[i][ModEF].value + ")";
      fieldVal = "(" + notModifier + fieldVal + ")";

      const operatorVal =
        i === 0 || !fields[i][OpKey].value ? "" : fields[i][OpKey].value + " ";

      // handles fields common across all data types (i.e. name, id)
      const closingParenthesis =
        fields[i][FieldTypeKey].value[0] === "(" ? ")" : "";

      elasticsearchQuery +=
        operatorVal +
        fields[i][FieldTypeKey].value +
        fieldVal +
        closingParenthesis +
        " ";
    }
    return elasticsearchQuery;
  };

  /**
   * Checks that each cell is not empty and does not contain any reserved characters
   */
  hasSyntaxError = (items: EditableTableItem[]) => {
    for (let i = 0; i < items.length; i++) {
      if (
        items[i][FieldTypeKey].length === 0 ||
        items[i][FieldValKey].length === 0 ||
        items[i][ModEF].length === 0 ||
        (i !== 0 && items[i][OpKey].length === 0) ||
        new RegExp(reservedChars.join("|")).test(items[i][FieldValKey])
      ) {
        return true;
      }
    }
    return false;
  };

  render() {
    return (
      <PageLayout title="Advanced Search">
        <ToggleStyled>
          <ToolTip
            content={
              this.state.isChecked
                ? "Use textifield search"
                : "Use elastic search UI"
            }
          >
            <Toggle
              size="large"
              onChange={this.onToggle}
              isDisabled={this.props.data!.loading}
            />
          </ToolTip>
        </ToggleStyled>

        <Form onSubmit={this.onSubmit}>
          {this.state.isChecked ? (
            <>
              <EditableDynamicTable
                name="elastic search"
                fields={editableSearchFieldsWithValidation}
                defaultValue={defaultData}
                addButtonText="Add Field"
                createNewItem={createNewField}
                allowDelete={() => true}
                validateValue={v =>
                  v.length === 0 || this.hasSyntaxError(v)
                    ? "Invalid Query"
                    : undefined
                }
                defaultEditMode
              />
              <ButtonStyled>
                <Button type="submit" appearance="primary">
                  Search
                </Button>
              </ButtonStyled>
            </>
          ) : (
            <TextField
              name="search-input"
              defaultValue={parse(this.props.location.search).query || ""}
              placeholder="Type something..."
            />
          )}
        </Form>

        {this.props.data!.loading ? (
          <SearchInputSpinner>
            <Spinner size="xlarge" />
          </SearchInputSpinner>
        ) : this.state.isChecked ? (
          <ESHelpStyled>
            <ElasticsearchHelp />
          </ESHelpStyled>
        ) : (
          <HelpLink>
            <TextFieldSearchHelp />
          </HelpLink>
        )}

        {!this.props.data!.loading &&
        !this.props.data!.error &&
        parse(this.props.location.search).query !== "" &&
        this.props.data!.search &&
        this.props.data!.search!.edges!.length === 0 ? (
          <h4>Found nothing, try another query...</h4>
        ) : null}

        {!this.props.data!.search! ? null : (
          <SearchResultsDisplay
            results={this.props
              .data!.search!.edges!.filter(edge => !(edge === null))
              .filter(edge => !(edge!.node === null))
              .filter(
                edge => !(edge!.node!.__typename === "CompliancePolicy2")
              )}
            isLoading={this.props.data!.loading}
          />
        )}

        <BottomScrollListener onBottom={this.doSearch} />
      </PageLayout>
    );
  }
}

export const AdvancedSearch = compose(
  graphql(AdvancedSearchQuery, {
    options: (props: SearchProps) => ({
      errorPolicy: "all",
      notifyOnNetworkStatusChange: true,
      skip:
        isNullOrUndefined(parse(props.location.search).query) ||
        parse(props.location.search).query.length === 0,
      variables: {
        query: parse(props.location.search).query.replace(/(\/)/g, "\\/"),
        first: 25
      }
    })
  }),
  withRouter,
  withApollo
)(AdvancedSearchPage);
