import React from "react";
import {
  EditableTableField,
  EditableTableItem
} from "@atlassiansox/microscopekit";
import { colors } from "@atlaskit/theme";
import {
  operatorOptions,
  modifierOptions,
  groupedOptions,
  reservedChars
} from "./es-field-data";

/**
 * Using Atlaskit components instead of Mkit since
 * Mkit version already nests Field, leading to runtime errors
 */
import { Checkbox } from "@atlaskit/checkbox";
const Textfield = require("@atlaskit/textfield").default;
const Select = require("@atlaskit/select").default;

export const editableSearchFields = [
  {
    columnWidth: 15,
    key: "operator",
    headContent: "Operator",
    editableContent: (
      <Select
        className="operator"
        options={operatorOptions}
        placeholder="Select Operator..."
      />
    ),
    isEditable: (_: EditableTableItem) => true,
    getValue: (e: any) => e,
    shouldCellRender: (_: EditableTableItem, index: number) => index !== 0
  },
  {
    columnWidth: 20,
    key: "fieldType",
    headContent: "Field Type",
    editableContent: (
      <Select
        className="fieldType"
        options={groupedOptions}
        placeholder="Select Field..."
        maxMenuHeight={200}
      />
    ),
    isEditable: (_: EditableTableItem) => true,
    getValue: (e: any) => e
  },
  {
    columnWidth: 30,
    key: "fieldValue",
    headContent: "Field Value",
    editableContent: <Textfield placeholder="Type Field Value..." />,
    isEditable: (_: EditableTableItem) => true
  },
  {
    columnWidth: 15,
    key: "modifierExactFuzzy",
    headContent: "Modifier",
    editableContent: (
      <Select
        className="modifierExactFuzzy"
        options={modifierOptions}
        placeholder="Select Modifier..."
      />
    ),
    getValue: (e: any) => e,
    isEditable: (_: EditableTableItem) => true
  },
  {
    columnWidth: 10,
    key: "modifierNot",
    headContent: "Modifier",
    editableContent: (
      /**
       * Overrides prop is a workaround to change color of text label
       * until Atlaskit team can fix bug with theme token.
       * Does not seem to be compatible with atlaskit theme conditional coloring.
       */
      <Checkbox
        name="modifierNot"
        label="NOT"
        overrides={{
          LabelText: {
            cssFn: defaultStyles => ({
              ...defaultStyles,
              color: colors.N90
            })
          }
        }}
      />
    ),
    getValue: (e: any) => e.target.checked,
    isEditable: (_: EditableTableItem) => true
  }
] as EditableTableField[];

export const editableSearchFieldsWithValidation = editableSearchFields.map(
  (field: EditableTableField) => ({
    ...field,
    validate: (item: EditableTableItem) =>
      item[field.key].length === 0
        ? `${field.headContent} required`
        : field.headContent === "Field Value" &&
          new RegExp(reservedChars.join("|")).test(item[field.key])
        ? "Reserved character not allowed"
        : undefined
  })
);

export const createNewField = (): EditableTableItem => {
  const id = Date.now();
  return {
    id,
    key: id.toString(),
    operator: "",
    fieldType: "",
    fieldValue: "",
    modifierExactFuzzy: "",
    modifierNot: false
  };
};

export const defaultData = [
  {
    id: Date.now(),
    key: Date.now().toString(),
    operator: "",
    fieldType: "",
    fieldValue: "",
    modifierExactFuzzy: "",
    modifierNot: false
  }
];
