import * as React from "react";
import * as Palette from "../../../palette";
import { FlagProps, withShowFlag, compose } from "@atlassiansox/microscopekit";
import { withMountTracking } from "../../../core/components/mount-tracker";
import styled from "styled-components";

const AkSpinner = require("@atlaskit/spinner").default;

type ServiceDependenciesModalProps = FlagProps & {
  closeModal: () => void;
  addDependencies: (dependency: string) => Promise<any>;
};

interface State {
  makingQuery: boolean;
  dep: string;
  errors: string[];
}

const RowDiv = styled.div`
  display: table;
  width: 100%;
`;

const ChildDiv = styled.div`
  display: table-cell;
  padding: 0.5em;
`;

const header = "Add Dependency";
export class ServiceDependencyModalView extends React.Component<
  ServiceDependenciesModalProps,
  State
> {
  constructor(props: ServiceDependenciesModalProps) {
    super(props);

    this.state = {
      makingQuery: false,
      dep: "",
      errors: []
    };
  }

  addDependencies = async () => {
    this.setState({ makingQuery: true });
    try {
      await this.props.addDependencies(this.state.dep);
      this.props.closeModal();
    } catch (formErr) {
      this.setState({
        makingQuery: false,
        errors: formErr.errors.map((e: Error) => e.message)
      });
    }
  };

  updateDependency = (serviceId: string) => {
    this.setState({ dep: serviceId, errors: [] });
  };

  render() {
    return (
      <Palette.Modal
        heading={header}
        actions={[
          {
            text: "Add",
            onClick: this.addDependencies,
            isDisabled: this.state.makingQuery,
            iconBefore: this.state.makingQuery ? (
              <AkSpinner size="small" isCompleting={false} />
            ) : (
              undefined
            )
          },
          {
            text: "Cancel",
            onClick: this.props.closeModal,
            isDisabled: this.state.makingQuery
          }
        ]}
        onClose={this.props.closeModal}
      >
        {
          <RowDiv>
            <ChildDiv>
              <Palette.TextField
                value={this.state.dep}
                required
                placeholder="Service Name"
                isDisabled={this.state.makingQuery}
                onChange={event => {
                  this.updateDependency(event.target.value);
                }}
                errors={this.state.errors}
              />
            </ChildDiv>
          </RowDiv>
        }
      </Palette.Modal>
    );
  }
}

export const ServiceDependencyModal = compose(
  withMountTracking("ServiceDependencyModal"),
  withShowFlag
)(ServiceDependencyModalView);
