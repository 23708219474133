/* tslint:disable */

/* Date in ISO format */
export type ISODate = any;

/* A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the&#x60;date-time&#x60; format outlined in section 5.6 of the RFC 3339 profile of the ISO8601 standard for representation of dates and times using the Gregorian calendar. */
export type DateTime = any;

export type CustomMap = any;

export type Any = any;

export type Time = any;

/* A date string, such as 2007-12-03, compliant with the &#x60;full-date&#x60; formatoutlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard forrepresentation of dates and times using the Gregorian calendar. */
export type Date = any;

export interface MicrosServiceDeployment {
  aliases?: MicrosAlias[] | null;
  creationTime?: DateTime | null;
  id: string;
  logs?: MicrosDeploymentLogs | null;
  serviceDescriptor?: string | null;
  serviceType?: MicrosServiceType | null;
  splunkURL?: string | null;
  status?: MicrosDeploymentStatus | null;
}

/* All data types found below should have its corresponding query objects annotated with @GraphqlQueryObject */
export interface Query {
  _?: boolean | null;
  artifact?: Artifacts | null;
  autocompleteServices: Service[];
  category?: Category | null;
  compliancePolicy?: CompliancePolicy2 | null;
  complianceQuestions: ComplianceQuestion2[];
  computeAZListForService: AutoDiscoveryFetchComputeAZListResponse;
  containersForService: CustomMap;
  cronosConfigForService?: ContinuousChaosConfig[] | null;
  cronosExecutionsForService?: ContinuousChaosExecution[] | null;
  faultExecutionsByExerciseID: Pagination;
  getCazdrPreferredExecutionWindow?: PreferredExecutionWindowOption | null;
  instantRunTemplates?: InstantRunDynamicTemplate[] | null;
  isValidMvpService: boolean;
  jiraIssueDetails: CustomMap;
  loggingDataV2?: LoggingDataV2 | null;
  microsEnvironments?: MicrosEnvironmentDetail[] | null;
  microsGroupsForService?: string[] | null;
  opsgenieAlertRequest: OpsgenieAlertRequest;
  opsgenieTeams: Opsgenie[];
  preferredExecutionWindowOptions?: PreferredExecutionWindowOption[] | null;
  rdsListForAService?: string[] | null;
  resourceListForFaultAndService: CustomMap;
  schedules?: ScheduleOperationResponse[] | null;
  schedulesByService?: ScheduleOperationResponse[] | null;
  schedulesByStatus?: ScheduleOperationResponse[] | null;
  schedulesByTemplateID?: ScheduleOperationResponse[] | null;
  search: SearchConnection;
  searchServices: ServiceConnection;
  service?: Service | null;
  serviceNotifications?: ServiceNotification[] | null;
  serviceNotificationsForByService: ServiceNotification;
  supportedFaults: ResilienceFaults[];
  user?: User | null;
  warGame: WarGame;
  warGameDashboard: string;
  warGameDynamicTemplates?: WargameTemplate[] | null;
  warGameExercise: WarGameExercise;
  warGameExerciseDetails: WarGameExercise;
  warGameExerciseForService: Pagination;
  warGameExerciseStatusMapping: CustomMap;
  warGameExercisesForTemplateID?: WarGameExercise[] | null;
  warGameStatus: WarGameStatus;
  warGameTemplate: WargameTemplate;
  warGameTemplateAllVersionsByID: WargameTemplate[];
  warGameTemplateForService?: WargameTemplate[] | null;
  warGameTemplateJson: CustomMap;
  warGameTemplates: WargameTemplate[];
  warGameTemplatesByCategory?: WargameTemplate[] | null;
  warGameTemplatesForExerciseID?: WargameTemplate[] | null;
  warGameTemplatesV2: WargameTemplate[];
  warGames?: WarGame[] | null;
  warGamesByExerciseID: Pagination;
  warGamesByTicketID?: WarGame[] | null;
  warGamesForService?: WarGame[] | null;
  warGamesForServiceByStatus?: WarGame[] | null;
  warGamesInProgressForService?: WarGame[] | null;
  warGamesV2: WargamePagination;
}

export interface Artifacts {
  created: ISODate;
  createdBy: string;
  currentReleases: ArtifactReleases;
  name: string;
  reference: string;
  type: string;
  version: string;
}

export interface ArtifactReleases {
  releases: ReleaseGroup[];
  totalResults: number;
}

export interface ReleaseGroup {
  createdBy: string;
  environment: string;
  release: Release;
  service: string;
  timestamp: ISODate;
}

export interface Release {
  artifacts: Artifacts[];
  created: ISODate;
  createdBy: string;
  description: string;
  service: string;
  uuid: string;
  version: string;
}

export interface Service {
  attributes: ServiceAttributes;
  compliancePolicy?: CompliancePolicy2 | null;
  currentUserPermission: Permission;
  dependencies: ServiceDependencies;
  documentation: ServiceDocumentation[];
  id: string;
  metadata: ServiceMetadata;
  microsV2?: MicrosServiceV2 | null;
  releaseHistory: ReleaseHistory;
  tags: Tag[];
}

export interface ServiceAttributes {
  archetypeAttribute?: ArchetypeAttribute | null;
  archetypeServiceAttribute?: ArchetypeServiceAttribute | null;
  azdrAttribute?: AzdrAttribute | null;
  compassUuidAttribute?: CompassUuidAttribute | null;
  dataClassificationsAttribute?: DataClassificationsAttribute | null;
  deployTokensAttribute?: DeployTokensAttribute | null;
  dnsNamesAttribute?: DnsNamesAttribute | null;
  jiraProjectsAttribute?: JiraProjectsAttribute | null;
  lockAttribute?: LockAttribute | null;
  lockScopeAttribute?: LockScopeAttribute | null;
  microsNetworkSegmentAttribute?: MicrosNetworkSegmentAttribute | null;
  microscopePanelsAttribute?: MicroscopePanelsAttribute | null;
  mimOpsgenieAttribute?: MimOpsgenieAttribute | null;
  opsgenieAttribute?: OpsgenieAttribute | null;
  panelInfoAttribute?: PanelInfoAttribute | null;
  serviceArchetypeAttribute?: ServiceArchetypeAttribute | null;
  serviceArchetypeShardAttribute?: ServiceArchetypeShardAttribute | null;
  spinnakerAttribute?: SpinnakerAttribute | null;
  swaggerMappingsAttribute?: SwaggerMappingsAttribute | null;
  testOgSchemaV1Attribute?: TestOgSchemaV1Attribute | null;
}

export interface ArchetypeAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: Archetype | null;
}

export interface ChangeMetadata {
  createdBy?: User | null;
  createdOn?: string | null;
  modifiedBy?: User | null;
  modifiedOn?: string | null;
}

export interface User {
  displayName?: string | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  location?: string | null;
  services?: ServiceConnection | null;
  username: string;
}

export interface ServiceConnection {
  edges?: ServiceEdge[] | null;
  node?: Service[] | null;
  pageInfo: PageInfo;
  totalCount?: number | null;
}

export interface ServiceEdge {
  cursor: string;
  node?: Service | null;
}

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface Archetype {
  environmentTypes: ArchetypeEnvironmentTypes /* Environment type information for this archetype */;
  lockState?: ArchetypeLockState | null /* The state of all queued and active locks for this archetype */;
  releaseBuilds?:
    | string[]
    | null /* A list of builds allowed to use Wrangler&#x27;s release endpoints with this archetype */;
  services: string[] /* A list of micros services associated this archetype */;
}

/* Environment type information for this archetype */
export interface ArchetypeEnvironmentTypes {
  development?: ArchetypeEnvironmentTypesDevelopment | null /* Information associated with this archetype in development environments */;
  production?: ArchetypeEnvironmentTypesProduction | null /* Information associated with this archetype in production environments */;
  staging?: ArchetypeEnvironmentTypesStaging | null /* Information associated with this archetype in staging environments */;
}

/* Information associated with this archetype in development environments */
export interface ArchetypeEnvironmentTypesDevelopment {
  lockId?:
    | string
    | null /* Spinnaker execution that has locked this archetype for updates in development environments */;
}

/* Information associated with this archetype in production environments */
export interface ArchetypeEnvironmentTypesProduction {
  lockId?:
    | string
    | null /* Spinnaker execution that has locked this archetype for updates in production environments */;
}

/* Information associated with this archetype in staging environments */
export interface ArchetypeEnvironmentTypesStaging {
  lockId?:
    | string
    | null /* Spinnaker execution that has locked this archetype for updates in staging environments */;
}

/* The state of all queued and active locks for this archetype */
export interface ArchetypeLockState {
  activeLocks: ArchetypeLockStateActiveLocks[] /* The list of locks that have been acquired and are currently active */;
  lockRequestQueue: Lock[] /* The current queue of lock requests for this archetype, waiting to acquire a lock */;
}

export interface ArchetypeLockStateActiveLocks {
  lock: Lock;
  lockScope: LockScope;
}

/* A lock for a set of Sliver deployment targets */
export interface Lock {
  adVersion: string /* The archetype descriptor version this pipeline is deploying */;
  archetypeId: string /* The archetype Id */;
  executionId: string /* The Spinnaker Execution that obtained this lock */;
  targets: LockTargets[] /* The list of deployment targets that the lock is acquired for */;
}

export interface LockTargets {
  environment: string /* The deployment environment */;
  service: string /* ID of the microservice */;
}

export interface ArchetypeServiceAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ArchetypeService | null;
}

export interface ArchetypeService {
  archetype?: string | null /* The archetype this service belongs to */;
  environmentState?: ArchetypeServiceEnvironmentState | null /* The state of this service in each environment */;
  status?: string | null /* The status of the service */;
}

/* The state of this service in each environment */
export interface ArchetypeServiceEnvironmentState {
  _empty?: string | null;
}

export interface AzdrAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: Azdr | null;
}

export interface Azdr {
  drChampion1?: User | null;
  drChampion2?: User | null;
}

export interface CompassUuidAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: string | null;
}

export interface DataClassificationsAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: DataClassification[] | null;
}

export interface DataClassification {
  dataType: string /* Data classification label */;
  explanation?:
    | string
    | null /* Brief description of how this label is used by the service */;
}

export interface DeployTokensAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: DeployToken[] | null;
}

export interface DeployToken {
  headers?: DeployTokenHeaders[] | null;
  name?: string | null;
}

export interface DeployTokenHeaders {
  header?: string | null;
  value?: string | null;
}

export interface DnsNamesAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: DnsName[] | null;
}

export interface DnsName {
  source?: string | null /* Origin of the record */;
  type?: string | null /* Type of the record */;
  value?: string | null /* DNS name */;
}

export interface JiraProjectsAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: JiraProjects[] | null;
}

export interface JiraProjects {
  description?: string | null /* Description of the Jira project */;
  name?: string | null /* Name of the Jira project */;
  url?: string | null /* URL to the Jira project */;
}

export interface LockAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: Lock | null;
}

export interface LockScopeAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: LockScope | null;
}

export interface MicrosNetworkSegmentAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: MicrosNetworkSegment | null;
}

export interface MicrosNetworkSegment {
  availableSegments?:
    | string[]
    | null /* The List of Available Network Segments to the service */;
  networkSegment?:
    | string
    | null /* The Network Segment the service belongs to */;
}

export interface MicroscopePanelsAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: MicroscopePanel[] | null;
}

export interface MicroscopePanel {
  id: string /* UUID of the panel installed on the service */;
  installedAt: string /* The time when the panel was installed */;
  installedBy: string /* The person who installed the panel */;
  name: string /* Name of the panel installed on the service */;
}

export interface MimOpsgenieAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: MimOpsgenie | null;
}

export interface MimOpsgenie {
  id?: string | null /* The Opsgenie MIM team id associated with the service */;
}

export interface OpsgenieAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: Opsgenie | null;
}

export interface Opsgenie {
  id?: string | null;
  team?: string | null /* The OpsGenie team associated with the service */;
  teamId?: string | null /* The OpsGenie team ID associated with the service */;
  type?: OpsgenieType | null;
}

export interface PanelInfoAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: PanelInfo | null;
}

export interface PanelInfo {
  services?:
    | string[]
    | null /* List of services this panel should be restricted to */;
}

export interface ServiceArchetypeAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ServiceArchetype | null;
}

export interface ServiceArchetype {
  capabilities?: ServiceArchetypeCapabilities | null /* The capabilities specific to this service archetype */;
  lockIds?: ServiceArchetypeLockIds | null /* Spinnaker execution that has locked this archetype for updates in certainenvironmentTypes; must be an environmentType:lockId map */;
  shards: string[] /* A list of shard services associated this service archetype */;
}

/* The capabilities specific to this service archetype */
export interface ServiceArchetypeCapabilities {
  _empty?: string | null;
}

/* Spinnaker execution that has locked this archetype for updates in certainenvironmentTypes; must be an environmentType:lockId map */
export interface ServiceArchetypeLockIds {
  _empty?: string | null;
}

export interface ServiceArchetypeShardAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ServiceArchetypeShard | null;
}

export interface ServiceArchetypeShard {
  allocationOptions?:
    | ServiceArchetypeShardAllocationOptions[]
    | null /* The list of allocation options for the shard */;
  archetype: string /* The service archetype this shard belongs to */;
  capabilities?: ServiceArchetypeShardCapabilities | null /* The capabilities specific to this service archetype shard in certain environments; must be an environment:capability map */;
  environments?:
    | string[]
    | null /* The list of Micros environments the shard should be deployed to */;
  status: string /* Status of the shard */;
}

/* The allocation */
export interface ServiceArchetypeShardAllocationOptions {
  capabilities: ServiceArchetypeShardAllocationOptionsCapabilities /* The capabilities specific to this service archetype shard */;
  environments: string[] /* The list of Micros environments this allocation applies to */;
  hostname: string /* hostname of this particular allocation */;
  name: string /* name of this particular allocation (should be service name + environment by default) */;
  strategy: string /* routing strategy applied in this allocation */;
}

/* The capabilities specific to this service archetype shard */
export interface ServiceArchetypeShardAllocationOptionsCapabilities {
  _empty?: string | null;
}

/* The capabilities specific to this service archetype shard in certain environments; must be an environment:capability map */
export interface ServiceArchetypeShardCapabilities {
  _empty?: string | null;
}

export interface SpinnakerAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: Spinnaker | null;
}

export interface Spinnaker {
  parent?: string | null /* Name of the SC entry which manages this service */;
  services?:
    | string[]
    | null /* A list of sub-services associated with this Spinnaker application */;
  spinnakerEnvironments?: SpinnakerSpinnakerEnvironments | null /* Spinnaker environments that the service has been added to */;
}

/* Spinnaker environments that the service has been added to */
export interface SpinnakerSpinnakerEnvironments {
  spinnakerDev?: SpinnakerSpinnakerEnvironmentsSpinnakerDev | null;
  spinnakerProd?: SpinnakerSpinnakerEnvironmentsSpinnakerProd | null;
  spinnakerRoot?: SpinnakerSpinnakerEnvironmentsSpinnakerRoot | null;
  spinnakerStg?: SpinnakerSpinnakerEnvironmentsSpinnakerStg | null;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerDev {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Dev */;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerProd {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Prod */;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerRoot {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Root */;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerStg {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Stg */;
}

export interface SwaggerMappingsAttribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: SwaggerMapping[] | null;
}

export interface SwaggerMapping {
  reason?:
    | string
    | null /* Describe the swagger url applicable when multiple swaggers are present */;
  swaggerUrl: string /* Swagger URL */;
}

export interface TestOgSchemaV1Attribute {
  changeMetadata?: ChangeMetadata | null;
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: TestOgSchemaV1 | null;
}

export interface TestOgSchemaV1 {
  loadBalancer?: TestOgSchemaV1LoadBalancer | null;
  team?: string | null /* The OpsGenie team associated with the service */;
}

export interface TestOgSchemaV1LoadBalancer {
  targetGroupProtocol?:
    | string
    | null /* The protocol used by the target group */;
}

export interface CompliancePolicy2 {
  changeMetadata: ChangeMetadata;
  currentUserPermission: Permission;
  id: string;
  name: string;
  owner2: User;
  questions: ComplianceQuestion2[];
  status: string;
}

export interface ComplianceQuestion2 {
  answer?: ComplianceAnswer2 | null;
  changeMetadata: ChangeMetadata;
  id: string;
  question: string;
  schema: string;
}

export interface ComplianceAnswer2 {
  answer: string;
  changeMetadata: ChangeMetadata;
  id: string;
}

export interface ServiceDependencies {
  downstream: ServiceDependency[];
  upstream: ServiceDependency[];
}

export interface ServiceDependency {
  changeMetadata: ChangeMetadata;
  downstream: Service;
  id: string;
  upstream: Service;
}

export interface ServiceDocumentation {
  changeMetadata: ChangeMetadata;
  id: string;
  name: string;
  url: string;
}

export interface ServiceMetadata {
  admins: Admin2[];
  businessUnit?: string | null;
  changeMetadata: ChangeMetadata;
  contactInfo?: ServiceContact | null;
  description?: string | null;
  location?: string | null;
  loggingID?: string | null;
  owner: User;
  platform?: string | null;
  publicFacing?: boolean | null;
  serviceName: string;
  serviceUUID: string;
  servingURL?: string | null;
  sla: SLA;
  ssamContainer: SSAMContainer;
  stateless?: boolean | null;
  tags: string[];
  thirdPartyProvided?: boolean | null;
}

export interface Group {
  displayName?: string | null;
  groupName?: string | null;
  id: string;
}

export interface ServiceContact {
  hipchatRoomName?: string | null;
  pagerdutyServiceID?: string | null;
  team?: string | null;
}

export interface SLA {
  availabilityObjective: number;
  recoveryPointObjective: number;
  recoveryTimeObjective: number;
  reliabilityObjective: number;
  serviceTier: number;
  zeroDowntimeUpgrades?: boolean | null;
}

export interface SSAMContainer {
  accessLevels: SSAMAccessLevel[];
  delegates?: User[] | null;
  displayName: string;
  id: string;
  owner: User;
  shortName: string;
  url?: string | null;
}

export interface SSAMAccessLevel {
  members: SSAMAccessMembers;
  name: string;
  shortName: string;
}

export interface SSAMAccessMembers {
  groups?: string[] | null;
  users?: User[] | null;
}

export interface MicrosServiceV2 {
  shard?: MicrosServiceShard | null;
  shards?: MicrosServiceShard[] | null;
}

export interface MicrosServiceShard {
  environments?: MicrosEnvironments | null;
  id: string;
  laasId?: string | null;
  label?: string | null;
}

export interface MicrosStaticEnvironments {
  items: MicrosStaticAppEnv[];
}

export interface MicrosStaticAppEnv {
  id: string;
  name: string;
}

export interface MicrosServiceEnvironments {
  items: MicrosServiceEnv[];
}

export interface MicrosServiceEnv {
  deployments?: MicrosServiceDeploymentState | null;
  id: string;
  name: string;
  stashKeys?: MicrosStashKey[] | null;
}

export interface MicrosServiceDeploymentState {
  accountId?: string | null;
  canary?: MicrosServiceDeploymentRollout | null;
  other?: MicrosServiceDeploymentNoRollout[] | null;
  persistent?: MicrosServiceDeploymentNoRollout[] | null;
  previous?: MicrosServiceDeploymentNoRollout | null;
  stable?: MicrosServiceDeploymentRollout | null;
}

export interface MicrosServiceDeploymentRollout
  extends MicrosServiceDeployment {
  aliases?: MicrosAlias[] | null;
  creationTime?: DateTime | null;
  id: string;
  logs?: MicrosDeploymentLogs | null;
  rolloutStatus?: RolloutStatus | null;
  serviceDescriptor?: string | null;
  serviceType?: MicrosServiceType | null;
  splunkURL?: string | null;
  status?: MicrosDeploymentStatus | null;
}

export interface MicrosAlias {
  aliasUrl: string;
  detail?: MicrosRoutingPolicyDetail | null;
  originalUrl: string;
  type?: MicrosRoutingPolicy | null;
}

export interface MicrosFailoverRoutingPolicy {
  primary: MicrosEnvironmentDetail;
  secondary: MicrosEnvironmentDetail;
}

export interface MicrosEnvironmentDetail {
  id: string;
  region?: string | null;
  type?: MicrosEnvironmentType | null;
}

export interface MicrosWeightedRoutingPolicy {
  weights: MicrosWeightedPolicyDetails[];
}

export interface MicrosWeightedPolicyDetails {
  env: MicrosEnvironmentDetail;
  suffix?: string | null;
  weight: number;
}

export interface MicrosDeploymentLogs {
  cloudFormation?: MicrosCloudFormationEvent[] | null;
  id: string;
}

export interface MicrosCloudFormationEvent {
  id: string;
  logicalId: string;
  reason?: string | null;
  status?: MicrosCloudFormationStatus | null;
  timestamp?: DateTime | null;
}

export interface MicrosEc2 {
  id: string;
  scalingGroups?: MicrosEc2ScalingGroup[] | null;
}

export interface MicrosEc2ScalingGroup {
  createdOn?: DateTime | null;
  defaultCooldown?: number | null;
  desiredCapacity?: number | null;
  groupARN?: string | null;
  groupName?: string | null;
  instances?: MicrosEc2Instance[] | null;
  maxSize?: number | null;
  minSize?: number | null;
}

export interface MicrosEc2Instance {
  availabilityZone?: string | null;
  healthStatus?: MicrosEc2InstanceHealthStatus | null;
  id: string;
  lifecycleState?: MicrosEc2InstanceLifecycleStatus | null;
}

export interface MicrosKubeCompute {
  id: string;
}

export interface RolloutStatus {
  id: string;
  trafficPercentage: number;
}

export interface MicrosServiceDeploymentNoRollout
  extends MicrosServiceDeployment {
  aliases?: MicrosAlias[] | null;
  creationTime?: DateTime | null;
  id: string;
  logs?: MicrosDeploymentLogs | null;
  serviceDescriptor?: string | null;
  serviceType?: MicrosServiceType | null;
  splunkURL?: string | null;
  status?: MicrosDeploymentStatus | null;
}

export interface MicrosStashKey {
  key: string;
}

export interface ReleaseHistory {
  environments: string[];
  releaseGroups: ReleaseGroup[];
  releaseGroupsCount: number;
}

export interface Tag {
  id: string;
  name: string;
  services: Service[];
}

export interface Category {
  changeMetadata: ChangeMetadata;
  children: Category[];
  displayName?: string | null;
  id: string;
  name: string;
  parent?: Category | null;
  services?: CategoryConnection | null;
}

export interface CategoryConnection {
  edges?: CategoryEdge[] | null;
  nodes?: Service[] | null;
  pageInfo: PageInfo;
}

export interface CategoryEdge {
  cursor: string;
  node?: Service | null;
}

export interface AutoDiscoveryFetchComputeAZListResponse {
  persistentStack?: boolean | null;
  possibleZones?: string[] | null;
}

export interface ContinuousChaosConfig {
  accountID: string;
  createdAt: string;
  deploymentID: string;
  environment: string;
  faultType: string;
  frequency?: ContinuousChaosConfigFrequency | null;
  id: string;
  isFailaConfig: boolean;
  lastModifiedBy: string;
  nextTrigger?: string | null;
  osbInstanceID: string;
  parameters?: CustomMap | null;
  probability: number;
  schedule: string;
  scheduleType?: ContinuousChaosScheduleType | null;
  service: string;
  serviceTier?: string | null;
  status: ContinuousChaosConfigStatus;
  updatedAt: string;
}

export interface ContinuousChaosExecution {
  createdAt: string;
  environment: string;
  id: string;
  service: string;
  updatedAt: string;
  wargamesID: string;
}

export interface Pagination {
  limit?: number | null;
  next?: string | null;
  offset?: number | null;
  pageCount?: number | null;
  prev?: string | null;
  rows?: Any | null;
  self?: string | null;
  sortColumn?: string | null;
  sortOrder?: string | null;
  totalCount?: number | null;
}

export interface PreferredExecutionWindowOption {
  description: string;
  endTimeUTC: Time;
  id: ExecWindow;
  name: string;
  startTimeUTC: Time;
}

export interface InstantRunDynamicTemplate {
  Stats?: Any | null;
  createdAt: string;
  description: string;
  id: string;
  name: string;
  publishStatus: WargameTemplatePublishStatus;
  recommendedMainParameterValue: string;
  runtimeFlags?: Any | null;
  updatedAt: string;
}

export interface LoggingDataV2 {
  acls?: ACL[] | null;
  admins: string;
  capacity_comment: string;
  capacity_gigabytes: number;
  last_modified_timestamp: Time;
  last_modified_user?: string | null;
  logging_id: string;
  name: string;
  organization: string;
  owner: string;
  source_id: string;
}

export interface ACL {
  acl_id: number;
  creation_timestamp: Time;
  deleted_timestamp?: Time | null;
  environments: string;
  last_modified_timestamp: Time;
  last_modified_user: string;
  staffid_group: string;
}

export interface OpsgenieAlertRequest {
  alertId?: string | null;
  id: string;
  success?: boolean | null;
}

export interface ScheduleOperationResponse {
  createdAt: string;
  cronEntryId: string;
  cronExpression: string;
  id: string;
  scheduleTime: string;
  scheduleType: ScheduleType;
  status: ScheduleStatus;
  updatedAt: string;
  userId: string;
  wargameIds?: string[] | null;
  wargameTemplateId: string;
}

export interface SearchConnection {
  edges?: SearchEdge[] | null;
  node?: SearchResult[] | null;
  pageInfo: PageInfo;
  totalCount?: number | null;
}

export interface SearchEdge {
  cursor: string;
  node?: SearchResult | null;
}

export interface ServiceNotification {
  channel: string;
  createdAt: string;
  cronosExecutions?: CronosExecutionsType[] | null;
  cronosReminders?: CustomMap | null;
  id: string;
  service: string;
  subscriptions?: string[] | null;
  updatedAt: string;
}

export interface WarGame {
  createdAt: string;
  environment?: string | null;
  id: string;
  premiseExecutions?: PremiseExecution[] | null;
  service: string;
  status: string;
  taskGroupExecutions?: TaskGroupExecution[] | null;
  teamId: string;
  trigger: string;
  updatedAt: string;
  userId: string;
  wargameExercisesID: string;
  wargameTemplateId: string;
  wargameTemplateName: string;
  wargameTemplateVersion: string;
  wargameTicketId?: string | null;
}

export interface PremiseExecution {
  createdAt: string;
  id: string;
  parameters?: CustomMap | null;
  premiseDefinitionsId: string;
  response: CustomMap;
  status: string;
  statusMessage: string;
  updatedAt: string;
}

export interface TaskGroupExecution {
  TaskGroupId: string;
  WarGamesId: string;
  faultExecutions?: FaultExecution[] | null;
  id: string;
  steadyStateExecutions?: SteadyStateExecution[] | null;
}

export interface FaultExecution {
  createdAt: string;
  faultDefinitionsId: string;
  id: string;
  parameters?: CustomMap | null;
  status: string;
  statusMessage: string;
  updatedAt: string;
}

export interface SteadyStateExecution {
  createdAt: string;
  id: string;
  parameters?: CustomMap | null;
  status: string;
  statusMessage: string;
  steadyStateDefinitionId: string;
  updatedAt: string;
}

export interface WargameTemplate {
  category: WargameCategory;
  createdAt: string;
  description: string;
  environment: string;
  id: number;
  name: string;
  premise?: Premise[] | null;
  publishStatus: WargameTemplatePublishStatus;
  runtimeFlags?: CustomMap | null;
  serviceName: string;
  taskGroup: TaskGroup[];
  teamId: string;
  updatedAt: string;
  userId: string;
  version: string;
  wargameParent: string;
}

export interface Premise {
  configData?: CustomMap | null;
  description: string;
  inputData: CustomMap;
  inputType?: PremiseType | null;
  name: string;
  provider: PremiseProvider;
  tags?: CustomMap | null;
}

export interface TaskGroup {
  delayBeforeExecution?: number | null;
  executionMode: TaskExecutionMode;
  faults: Fault[];
  name: string;
  steadyStates: SteadyState[];
}

export interface Fault {
  delayBeforeExecution?: number | null;
  faultCategory: FaultCategory;
  faultExecution: ResilienceFaults;
  parameters: CustomMap;
}

export interface SteadyState {
  parameters: CustomMap;
  probeAction: ProbeAction;
  probeCategory: ProbeCategory;
}

export interface WarGameExercise {
  confluencePage: string;
  createdAt: string;
  createdBy: string;
  endDate?: string | null;
  id: string;
  jiraStatus: string;
  jiraTicket: string;
  name: string;
  service: string;
  slackChannel: string;
  status: string;
  updatedAt: string;
  warGameTemplates?: WargameTemplate[] | null;
  wargames?: WarGame[] | null;
  zoomRoom: string;
}

export interface WarGameStatus {
  status: string;
}

export interface WargamePagination {
  limit?: number | null;
  next?: string | null;
  offset?: number | null;
  pageCount?: number | null;
  prev?: string | null;
  self?: string | null;
  sortColumn?: string | null;
  sortOrder?: string | null;
  totalCount?: number | null;
  wargameRows?: WarGame[] | null;
}

export interface Mutation {
  UpdateWarGameExercise: WarGameExercise;
  UpdateWarGameExerciseStatus?: WarGameExercise | null;
  _?: boolean | null;
  addServiceTag?: ServiceTagResult | null;
  cancelSchedule: ScheduleOperationResponse;
  configureNotification: ServiceNotification;
  createCompliancePolicy2: CompliancePolicy2;
  createCronosConfig: ContinuousChaosConfig;
  createExerciseTemplateMapping: ExerciseToTemplateMapping;
  createLoggingService?: LoggingDataV2 | null;
  createOpsgenieAlert: OpsgenieAlertRequest;
  createSSAMContainer: SSAMContainer;
  createServiceDependency: ServiceDependency;
  createServiceDocumentation: Service;
  createServiceV2: Service;
  createWarGameExercise: WarGameExercise;
  createWarGameTemplate: WargameTemplate;
  deferCronosConfig: ContinuousChaosConfig;
  deleteCronosConfigForService?: ContinuousChaosConfig[] | null;
  deleteLoggingData?: LoggingDataV2 | null;
  deleteService: string;
  deleteServiceDependency: ServiceDependency;
  deleteServiceDocumentation: Service;
  deleteWarGameTemplate: WargameTemplate;
  disableCronosConfig: ContinuousChaosConfig;
  disableExerciseTemplateMapping: ExerciseToTemplateMapping;
  editCronosConfig: ContinuousChaosConfig;
  editWargameTemplate: WargameTemplate;
  enableCronosConfig: ContinuousChaosConfig;
  forceStopWarGame: CustomMap;
  instantRunWarGame: CustomMap;
  modifyCazdrPreferredExecutionWindow?: CazdrPreferredExecutionWindow[] | null;
  pauseSchedule: ScheduleOperationResponse;
  publishWarGameTemplate: WargameTemplate;
  refreshCazdrSchedule?: ContinuousChaosConfig[] | null;
  removeServiceNotification: ServiceNotification;
  removeServiceTag: ServiceTagResult;
  resumeSchedule: ScheduleOperationResponse;
  startWarGameV2: CustomMap;
  updateCompliancePolicy2: CompliancePolicy2;
  updateLoggingData?: LoggingDataV2 | null;
  updateService: Service;
  updateServiceNotification: ServiceNotification;
}

export interface ServiceTagResult {
  service: Service;
  tag: Tag;
}

export interface ExerciseToTemplateMapping {
  createdAt: string;
  isDeleted?: boolean | null;
  updatedAt: string;
  wargameExercisesID: string;
  wargameTemplatesID: string;
}

export interface CazdrPreferredExecutionWindow {
  createdAt: string;
  executionWindow: ExecWindow;
  id: string;
  service: string;
  updatedAt: string;
}

export interface _Service {
  sdl: string;
}

export interface WarGameStartResponse {
  WarGameId: string;
  Workflow: WorkflowOutput;
}

export interface WorkflowOutput {
  id: string;
  runId: string;
}

export interface AutocompleteServicesQueryInput {
  query: string;
}

export interface UserServicesInput {
  isTeamMember?: boolean | null;
}

export interface MicrosShardInput {
  label: string;
}

export interface MicrosEnvironmentsInput {
  environments: MicrosEnvironmentFilter[];
}

export interface OpsgenieTeamsInput {
  query: string;
}

export interface SearchQueryInput {
  query: string;
  filter?: Filter | null;
}

export interface UpdateWarGameExerciseInput {
  endDate?: string | null;
  name?: string | null;
}

export interface ServiceTagInput {
  service: string;
  tag: string;
}

export interface ServiceNotificationInput {
  service: string;
  subscriptions?: string[] | null;
  channel: string;
  cronosExecutions?: CronosExecutionsType[] | null;
  cronosReminders?: CustomMap | null;
}

export interface CompliancePolicy2Input {
  name: string;
  answers: CompliancePolicy2AnswerInput[];
}

export interface CompliancePolicy2AnswerInput {
  questionID: string;
  answer: string;
}

export interface ContinuousChaosConfigCreateInput {
  environment: string;
  service: string;
  faultType: string;
  parameters?: CustomMap | null;
  schedule: string;
  probability: number;
}

export interface LoggingDataV2Input {
  name: string;
  organization: string;
  owner: string;
  source_id: string;
  acls: ACLInput[];
  admins: string;
  capacity_comment: string;
  capacity_gigabytes: number;
}

export interface ACLInput {
  acl_id?: number | null;
  staffid_group: string;
  environments: string;
}

export interface OpsgenieAlertInput {
  teamId: string;
  message: string;
  description?: string | null;
  tags: string[];
  priority: OpsgenieAlertPriority;
}

export interface SSAMContainerInput {
  displayName: string;
  owner?: string | null;
  delegates?: string[] | null;
  url?: string | null;
  shortName: string;
  members: SSAMAccessMembersInput;
}

export interface SSAMAccessMembersInput {
  users?: string[] | null;
  groups?: string[] | null;
}

export interface CreateServiceDependencyInput {
  upstream: string;
  downstream: string;
}

export interface CreateServiceDocumentationInput {
  service: string;
  name: string;
  url: string;
}

export interface CreateServiceInput {
  name: string;
  owner: string;
  serviceTier?: number | null;
  platform?: string | null;
  description?: string | null;
  servingURL?: string | null;
  businessUnit?: string | null;
  location?: string | null;
  team?: string | null;
  compliancePolicyId?: string | null;
  newSSAMContainer?: SSAMContainerInput | null /* Create a new SSAM Container */;
  ssamContainerName?: string | null /* Use an existing SSAM Container */;
  loggingID?: string | null;
  publicFacing?: boolean | null;
  stateless?: boolean | null;
  thirdPartyProvided?: boolean | null;
  zeroDowntimeUpgrades?: boolean | null;
}

export interface WarGameExerciseInput {
  service: string;
  createdBy: string;
  name: string;
  status?: string | null;
  endDate?: string | null;
}

export interface WargameTemplateInput {
  name: string;
  description: string;
  environment: string;
  version?: string | null;
  wargameParent: string;
  service: string;
  premise?: PremiseInput[] | null;
  taskGroup: TaskGroupInput[];
  publishStatus: WargameTemplatePublishStatus;
  runtimeFlags?: CustomMap | null;
}

export interface PremiseInput {
  name: string;
  description: string;
  provider: PremiseProvider;
  inputType: PremiseType;
  inputData: CustomMap;
  configData?: CustomMap | null;
  tags?: CustomMap | null;
}

export interface TaskGroupInput {
  name: string;
  executionMode: TaskExecutionMode;
  steadyStates?: SteadyStateInput[] | null;
  faults: FaultInput[];
  delayBeforeExecution?: number | null;
}

export interface SteadyStateInput {
  probeCategory: ProbeCategory;
  probeAction: ProbeAction;
  parameters: CustomMap;
}

export interface FaultInput {
  faultCategory: FaultCategory;
  faultAction: ResilienceFaults;
  faultParameters: CustomMap;
  delayBeforeExecution?: number | null;
}

export interface DeleteServiceDependencyInput {
  upstream: string;
  downstream: string;
}

export interface DeleteServiceDocumentationInput {
  service: string;
  document: string;
}

export interface ContinuousChaosConfigEditInput {
  parameters?: CustomMap | null;
  schedule?: string | null;
  probability?: number | null;
  frequency?: ContinuousChaosConfigFrequency | null;
  scheduleType?: ContinuousChaosScheduleType | null;
}

export interface InstantRunWarGameInput {
  environment: string;
  metaData?: InstantRunFaultParams | null;
}

export interface InstantRunFaultParams {
  percentage?: number | null;
  timeoutSeconds?: number | null;
  blastRadius?: number | null;
  container?: string | null;
  delay?: number | null;
  microsGroup?: string[] | null;
  targetService?: string | null;
  targetDNS?: string | null;
  resourceName?: string | null;
}

export interface CazdrPreferredExecutionWindowUpsertInput {
  services?: string[] | null;
  execution_window: ExecWindow;
}

export interface CazdrRefreshScheduleInput {
  services?: string[] | null;
  environment: string;
}

export interface WarGameInput {
  warGameTemplateId: number;
  scheduleInfo?: ScheduleInput | null;
  wargameExerciseId?: string | null;
  warGameTicketId?: string | null;
}

export interface ScheduleInput {
  cron?: string | null;
  scheduleType: ScheduleType;
  scheduleTime?: string | null;
}

export interface UpdateCompliancePolicy2Input {
  id: string;
  owner?: string | null;
  name?: string | null;
  status?: string | null;
  answers?: CompliancePolicy2AnswerInput[] | null;
}

export interface UpdateServiceInput {
  id: string;
  metadata?: ServiceMetadataInput | null;
  tags?: string[] | null;
  attributes?: ServiceAttributesInput | null;
}

export interface ServiceMetadataInput {
  serviceTier?: number | null;
  platform?: string | null;
  description?: string | null;
  servingURL?: string | null;
  businessUnit?: string | null;
  location?: string | null;
  team?: string | null;
  tags?: string[] | null;
  compliancePolicyId?: string | null;
  newSSAMContainer?: SSAMContainerInput | null /* Create a new SSAM Container */;
  ssamContainerName?: string | null /* Use an existing SSAM Container */;
  loggingID?: string | null;
  publicFacing?: boolean | null;
  stateless?: boolean | null;
  thirdPartyProvided?: boolean | null;
  zeroDowntimeUpgrades?: boolean | null;
  hipchatRoomName?: string | null;
}

export interface ServiceAttributesInput {
  jiraProjectsAttribute?: JiraProjectsAttributeInput | null;
  opsgenieAttribute?: OpsgenieAttributeInput | null;
  dnsNamesAttribute?: DnsNamesAttributeInput | null;
  microscopePanelsAttribute?: MicroscopePanelsAttributeInput | null;
  deployTokensAttribute?: DeployTokensAttributeInput | null;
  azdrAttribute?: AzdrAttributeInput | null;
  mimOpsgenieAttribute?: MimOpsgenieAttributeInput | null;
  dataClassificationsAttribute?: DataClassificationsAttributeInput | null;
  panelInfoAttribute?: PanelInfoAttributeInput | null;
  swaggerMappingsAttribute?: SwaggerMappingsAttributeInput | null;
  microsNetworkSegmentAttribute?: MicrosNetworkSegmentAttributeInput | null;
  spinnakerAttribute?: SpinnakerAttributeInput | null;
  serviceArchetypeShardAttribute?: ServiceArchetypeShardAttributeInput | null;
  serviceArchetypeAttribute?: ServiceArchetypeAttributeInput | null;
  archetypeServiceAttribute?: ArchetypeServiceAttributeInput | null;
  lockAttribute?: LockAttributeInput | null;
  lockScopeAttribute?: LockScopeAttributeInput | null;
  archetypeAttribute?: ArchetypeAttributeInput | null;
  testOgSchemaV1Attribute?: TestOgSchemaV1AttributeInput | null;
  compassUuidAttribute?: CompassUuidAttributeInput | null;
}

export interface JiraProjectsAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: JiraProjectsInput[] | null;
}

export interface JiraProjectsInput {
  description?: string | null /* Description of the Jira project */;
  name?: string | null /* Name of the Jira project */;
  url?: string | null /* URL to the Jira project */;
}

export interface OpsgenieAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: OpsgenieInput | null;
}

export interface OpsgenieInput {
  team?: string | null /* The OpsGenie team associated with the service */;
  teamId?: string | null /* The OpsGenie team ID associated with the service */;
}

export interface DnsNamesAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: DnsNameInput[] | null;
}

export interface DnsNameInput {
  source?: string | null /* Origin of the record */;
  type?: string | null /* Type of the record */;
  value?: string | null /* DNS name */;
}

export interface MicroscopePanelsAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: MicroscopePanelInput[] | null;
}

export interface MicroscopePanelInput {
  id: string /* UUID of the panel installed on the service */;
  installedAt: string /* The time when the panel was installed */;
  installedBy: string /* The person who installed the panel */;
  name: string /* Name of the panel installed on the service */;
}

export interface DeployTokensAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: DeployTokenInput[] | null;
}

export interface DeployTokenInput {
  headers?: DeployTokenHeadersInput[] | null;
  name?: string | null;
}

export interface DeployTokenHeadersInput {
  header?: string | null;
  value?: string | null;
}

export interface AzdrAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: AzdrInput | null;
}

export interface AzdrInput {
  drChampion1?: UserInput | null;
  drChampion2?: UserInput | null;
}

export interface UserInput {
  id: string;
}

export interface MimOpsgenieAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: MimOpsgenieInput | null;
}

export interface MimOpsgenieInput {
  id?: string | null /* The Opsgenie MIM team id associated with the service */;
}

export interface DataClassificationsAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: DataClassificationInput[] | null;
}

export interface DataClassificationInput {
  dataType: string /* Data classification label */;
  explanation?:
    | string
    | null /* Brief description of how this label is used by the service */;
}

export interface PanelInfoAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: PanelInfoInput | null;
}

export interface PanelInfoInput {
  services?:
    | string[]
    | null /* List of services this panel should be restricted to */;
}

export interface SwaggerMappingsAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: SwaggerMappingInput[] | null;
}

export interface SwaggerMappingInput {
  reason?:
    | string
    | null /* Describe the swagger url applicable when multiple swaggers are present */;
  swaggerUrl: string /* Swagger URL */;
}

export interface MicrosNetworkSegmentAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: MicrosNetworkSegmentInput | null;
}

export interface MicrosNetworkSegmentInput {
  availableSegments?:
    | string[]
    | null /* The List of Available Network Segments to the service */;
  networkSegment?:
    | string
    | null /* The Network Segment the service belongs to */;
}

export interface SpinnakerAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: SpinnakerInput | null;
}

export interface SpinnakerInput {
  parent?: string | null /* Name of the SC entry which manages this service */;
  services?:
    | string[]
    | null /* A list of sub-services associated with this Spinnaker application */;
  spinnakerEnvironments?: SpinnakerSpinnakerEnvironmentsInput | null /* Spinnaker environments that the service has been added to */;
}

export interface SpinnakerSpinnakerEnvironmentsInput {
  spinnakerDev?: SpinnakerSpinnakerEnvironmentsSpinnakerDevInput | null;
  spinnakerProd?: SpinnakerSpinnakerEnvironmentsSpinnakerProdInput | null;
  spinnakerRoot?: SpinnakerSpinnakerEnvironmentsSpinnakerRootInput | null;
  spinnakerStg?: SpinnakerSpinnakerEnvironmentsSpinnakerStgInput | null;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerDevInput {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Dev */;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerProdInput {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Prod */;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerRootInput {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Root */;
}

export interface SpinnakerSpinnakerEnvironmentsSpinnakerStgInput {
  namespace: string /* The Config Registry namespace used by the service for Spinnaker Stg */;
}

export interface ServiceArchetypeShardAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ServiceArchetypeShardInput | null;
}

export interface ServiceArchetypeShardInput {
  allocationOptions?:
    | ServiceArchetypeShardAllocationOptionsInput[]
    | null /* The list of allocation options for the shard */;
  archetype: string /* The service archetype this shard belongs to */;
  capabilities?: ServiceArchetypeShardCapabilitiesInput | null /* The capabilities specific to this service archetype shard in certain environments; must be an environment:capability map */;
  environments?:
    | string[]
    | null /* The list of Micros environments the shard should be deployed to */;
  status: string /* Status of the shard */;
}

export interface ServiceArchetypeShardAllocationOptionsInput {
  capabilities: ServiceArchetypeShardAllocationOptionsCapabilitiesInput /* The capabilities specific to this service archetype shard */;
  environments: string[] /* The list of Micros environments this allocation applies to */;
  hostname: string /* hostname of this particular allocation */;
  name: string /* name of this particular allocation (should be service name + environment by default) */;
  strategy: string /* routing strategy applied in this allocation */;
}

export interface ServiceArchetypeShardAllocationOptionsCapabilitiesInput {
  _empty?: string | null;
}

export interface ServiceArchetypeShardCapabilitiesInput {
  _empty?: string | null;
}

export interface ServiceArchetypeAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ServiceArchetypeInput | null;
}

export interface ServiceArchetypeInput {
  capabilities?: ServiceArchetypeCapabilitiesInput | null /* The capabilities specific to this service archetype */;
  lockIds?: ServiceArchetypeLockIdsInput | null /* Spinnaker execution that has locked this archetype for updates in certainenvironmentTypes; must be an environmentType:lockId map */;
  shards: string[] /* A list of shard services associated this service archetype */;
}

export interface ServiceArchetypeCapabilitiesInput {
  _empty?: string | null;
}

export interface ServiceArchetypeLockIdsInput {
  _empty?: string | null;
}

export interface ArchetypeServiceAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ArchetypeServiceInput | null;
}

export interface ArchetypeServiceInput {
  archetype?: string | null /* The archetype this service belongs to */;
  environmentState?: ArchetypeServiceEnvironmentStateInput | null /* The state of this service in each environment */;
  status?: string | null /* The status of the service */;
}

export interface ArchetypeServiceEnvironmentStateInput {
  _empty?: string | null;
}

export interface LockAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: LockInput | null;
}

export interface LockInput {
  adVersion: string /* The archetype descriptor version this pipeline is deploying */;
  archetypeId: string /* The archetype Id */;
  executionId: string /* The Spinnaker Execution that obtained this lock */;
  targets: LockTargetsInput[] /* The list of deployment targets that the lock is acquired for */;
}

export interface LockTargetsInput {
  environment: string /* The deployment environment */;
  service: string /* ID of the microservice */;
}

export interface LockScopeAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: LockScope | null;
}

export interface ArchetypeAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: ArchetypeInput | null;
}

export interface ArchetypeInput {
  environmentTypes: ArchetypeEnvironmentTypesInput /* Environment type information for this archetype */;
  lockState?: ArchetypeLockStateInput | null /* The state of all queued and active locks for this archetype */;
  releaseBuilds?:
    | string[]
    | null /* A list of builds allowed to use Wrangler&#x27;s release endpoints with this archetype */;
  services: string[] /* A list of micros services associated this archetype */;
}

export interface ArchetypeEnvironmentTypesInput {
  development?: ArchetypeEnvironmentTypesDevelopmentInput | null /* Information associated with this archetype in development environments */;
  production?: ArchetypeEnvironmentTypesProductionInput | null /* Information associated with this archetype in production environments */;
  staging?: ArchetypeEnvironmentTypesStagingInput | null /* Information associated with this archetype in staging environments */;
}

export interface ArchetypeEnvironmentTypesDevelopmentInput {
  lockId?:
    | string
    | null /* Spinnaker execution that has locked this archetype for updates in development environments */;
}

export interface ArchetypeEnvironmentTypesProductionInput {
  lockId?:
    | string
    | null /* Spinnaker execution that has locked this archetype for updates in production environments */;
}

export interface ArchetypeEnvironmentTypesStagingInput {
  lockId?:
    | string
    | null /* Spinnaker execution that has locked this archetype for updates in staging environments */;
}

export interface ArchetypeLockStateInput {
  activeLocks: ArchetypeLockStateActiveLocksInput[] /* The list of locks that have been acquired and are currently active */;
  lockRequestQueue: LockInput[] /* The current queue of lock requests for this archetype, waiting to acquire a lock */;
}

export interface ArchetypeLockStateActiveLocksInput {
  lock: LockInput;
  lockScope: LockScope;
}

export interface TestOgSchemaV1AttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: TestOgSchemaV1Input | null;
}

export interface TestOgSchemaV1Input {
  loadBalancer?: TestOgSchemaV1LoadBalancerInput | null;
  team?: string | null /* The OpsGenie team associated with the service */;
}

export interface TestOgSchemaV1LoadBalancerInput {
  targetGroupProtocol?:
    | string
    | null /* The protocol used by the target group */;
}

export interface CompassUuidAttributeInput {
  etag?: string | null /* ETag associated with the service attribute value */;
  value?: string | null;
}

export interface InstantRunMetaData {
  faultParams: InstantRunFaultParams;
}

export interface ArtifactQueryArgs {
  name: string;
  version: string;
}
export interface AutocompleteServicesQueryArgs {
  query: AutocompleteServicesQueryInput;
}
export interface CategoryQueryArgs {
  id: string;
}
export interface CompliancePolicyQueryArgs {
  id: string;
}
export interface ComputeAzListForServiceQueryArgs {
  environment: string;
  service: string;
  wargameID?: number | null;
}
export interface ContainersForServiceQueryArgs {
  environment: string;
  service: string;
  wargameID?: number | null;
}
export interface CronosConfigForServiceQueryArgs {
  service: string;
}
export interface CronosExecutionsForServiceQueryArgs {
  filterData?: CustomMap | null;
  limit?: number | null;
  page?: number | null;
  service: string;
  sortColumn?: string | null;
  sortOrder?: string | null;
}
export interface FaultExecutionsByExerciseIdQueryArgs {
  exerciseID: string;
  limit?: number | null;
  offset?: number | null;
  sortColumn?: string | null;
  sortOrder?: SortOrder | null;
}
export interface GetCazdrPreferredExecutionWindowQueryArgs {
  service: string;
}
export interface IsValidMvpServiceQueryArgs {
  service: string;
}
export interface JiraIssueDetailsQueryArgs {
  id: string;
}
export interface LoggingDataV2QueryArgs {
  loggingId: string;
}
export interface MicrosEnvironmentsQueryArgs {
  input?: MicrosEnvironmentsInput | null;
}
export interface MicrosGroupsForServiceQueryArgs {
  environment: string;
  service: string;
  wargameID?: number | null;
}
export interface OpsgenieAlertRequestQueryArgs {
  id: string;
}
export interface OpsgenieTeamsQueryArgs {
  input?: OpsgenieTeamsInput | null;
}
export interface RdsListForAServiceQueryArgs {
  environment: string;
  service: string;
  wargameID?: number | null;
}
export interface ResourceListForFaultAndServiceQueryArgs {
  environment: string;
  faultType: string;
  service: string;
  wargameID?: number | null;
}
export interface SchedulesByServiceQueryArgs {
  service?: string | null;
}
export interface SchedulesByStatusQueryArgs {
  status?: ScheduleStatus | null;
}
export interface SchedulesByTemplateIdQueryArgs {
  id?: string | null;
}
export interface SearchQueryArgs {
  after?: string | null;
  first?: number | null;
  query: SearchQueryInput;
}
export interface SearchServicesQueryArgs {
  after?: string | null;
  first?: number | null;
  query: SearchQueryInput;
}
export interface ServiceQueryArgs {
  id: string;
}
export interface ServiceNotificationsForByServiceQueryArgs {
  service: string;
}
export interface UserQueryArgs {
  id: string;
}
export interface WarGameQueryArgs {
  id: string;
}
export interface WarGameDashboardQueryArgs {
  id: string;
}
export interface WarGameExerciseQueryArgs {
  id: string;
}
export interface WarGameExerciseDetailsQueryArgs {
  id: string;
}
export interface WarGameExerciseForServiceQueryArgs {
  limit?: number | null;
  offset?: number | null;
  service: string;
  sortColumn?: string | null;
  sortOrder?: SortOrder | null;
}
export interface WarGameExercisesForTemplateIdQueryArgs {
  templateID: string;
}
export interface WarGameStatusQueryArgs {
  id: string;
}
export interface WarGameTemplateQueryArgs {
  id: string;
}
export interface WarGameTemplateAllVersionsByIdQueryArgs {
  id: string;
}
export interface WarGameTemplateForServiceQueryArgs {
  service: string;
}
export interface WarGameTemplateJsonQueryArgs {
  id: string;
}
export interface WarGameTemplatesByCategoryQueryArgs {
  category?: WargameCategory | null;
}
export interface WarGameTemplatesForExerciseIdQueryArgs {
  exerciseID: string;
}
export interface WarGameTemplatesV2QueryArgs {
  filter?: CustomMap | null;
  limit?: number | null;
  page?: number | null;
  sortColumn?: string | null;
  sortOrder?: SortOrder | null;
}
export interface WarGamesByExerciseIdQueryArgs {
  exerciseID: string;
  limit?: number | null;
  offset?: number | null;
  sortColumn?: string | null;
  sortOrder?: SortOrder | null;
}
export interface WarGamesByTicketIdQueryArgs {
  ticketID: string;
}
export interface WarGamesForServiceQueryArgs {
  service: string;
}
export interface WarGamesForServiceByStatusQueryArgs {
  service: string;
  status: CommonExecutionStatus;
}
export interface WarGamesInProgressForServiceQueryArgs {
  service: string;
}
export interface WarGamesV2QueryArgs {
  filter?: CustomMap | null;
  limit?: number | null;
  page?: number | null;
  sortColumn?: string | null;
  sortOrder?: SortOrder | null;
}
export interface CurrentReleasesArtifactsArgs {
  env?: string | null;
  page?: number | null;
  pageSize?: number | null;
}
export interface ReleaseHistoryServiceArgs {
  env?: string | null;
  page?: number | null;
  pageSize?: number | null;
}
export interface ServicesUserArgs {
  after?: string | null;
  first?: number | null;
  query?: UserServicesInput | null;
}
export interface ShardMicrosServiceV2Args {
  input?: MicrosShardInput | null;
}
export interface EnvironmentsMicrosServiceShardArgs {
  input?: MicrosEnvironmentsInput | null;
}
export interface ServicesCategoryArgs {
  after?: string | null;
  first?: number | null;
}
export interface UpdateWarGameExerciseMutationArgs {
  exerciseID: string;
  updateExerciseInput: UpdateWarGameExerciseInput;
}
export interface UpdateWarGameExerciseStatusMutationArgs {
  exerciseID: string;
  status: string;
}
export interface AddServiceTagMutationArgs {
  input: ServiceTagInput;
}
export interface CancelScheduleMutationArgs {
  id: string;
}
export interface ConfigureNotificationMutationArgs {
  in: ServiceNotificationInput;
}
export interface CreateCompliancePolicy2MutationArgs {
  input: CompliancePolicy2Input;
}
export interface CreateCronosConfigMutationArgs {
  data: ContinuousChaosConfigCreateInput;
}
export interface CreateExerciseTemplateMappingMutationArgs {
  exerciseID: string;
  templateID: string;
}
export interface CreateLoggingServiceMutationArgs {
  service: LoggingDataV2Input;
}
export interface CreateOpsgenieAlertMutationArgs {
  input: OpsgenieAlertInput;
}
export interface CreateSsamContainerMutationArgs {
  input: SSAMContainerInput;
}
export interface CreateServiceDependencyMutationArgs {
  input: CreateServiceDependencyInput;
}
export interface CreateServiceDocumentationMutationArgs {
  input: CreateServiceDocumentationInput;
}
export interface CreateServiceV2MutationArgs {
  input: CreateServiceInput;
}
export interface CreateWarGameExerciseMutationArgs {
  data: WarGameExerciseInput;
}
export interface CreateWarGameTemplateMutationArgs {
  data: WargameTemplateInput;
}
export interface DeferCronosConfigMutationArgs {
  configID: string;
}
export interface DeleteCronosConfigForServiceMutationArgs {
  env: string;
  service: string;
}
export interface DeleteLoggingDataMutationArgs {
  hardDelete?: boolean | null;
  id: string;
}
export interface DeleteServiceMutationArgs {
  id: string;
}
export interface DeleteServiceDependencyMutationArgs {
  input: DeleteServiceDependencyInput;
}
export interface DeleteServiceDocumentationMutationArgs {
  input: DeleteServiceDocumentationInput;
}
export interface DeleteWarGameTemplateMutationArgs {
  id: string;
}
export interface DisableCronosConfigMutationArgs {
  configID: string;
}
export interface DisableExerciseTemplateMappingMutationArgs {
  exerciseID: string;
  templateID: string;
}
export interface EditCronosConfigMutationArgs {
  configID: string;
  data: ContinuousChaosConfigEditInput;
}
export interface EditWargameTemplateMutationArgs {
  data: WargameTemplateInput;
}
export interface EnableCronosConfigMutationArgs {
  configID: string;
}
export interface ForceStopWarGameMutationArgs {
  id: string;
}
export interface InstantRunWarGameMutationArgs {
  input: InstantRunWarGameInput;
  service: string;
  templateID: string;
}
export interface ModifyCazdrPreferredExecutionWindowMutationArgs {
  data: CazdrPreferredExecutionWindowUpsertInput;
}
export interface PauseScheduleMutationArgs {
  id: string;
}
export interface PublishWarGameTemplateMutationArgs {
  id: string;
}
export interface RefreshCazdrScheduleMutationArgs {
  data: CazdrRefreshScheduleInput;
}
export interface RemoveServiceNotificationMutationArgs {
  id?: string | null;
}
export interface RemoveServiceTagMutationArgs {
  input: ServiceTagInput;
}
export interface ResumeScheduleMutationArgs {
  id: string;
}
export interface StartWarGameV2MutationArgs {
  input: WarGameInput;
}
export interface UpdateCompliancePolicy2MutationArgs {
  input: UpdateCompliancePolicy2Input;
}
export interface UpdateLoggingDataMutationArgs {
  id: string;
  service: LoggingDataV2Input;
}
export interface UpdateServiceMutationArgs {
  input: UpdateServiceInput;
}
export interface UpdateServiceNotificationMutationArgs {
  in: ServiceNotificationInput;
}
/* The scope of the lock that was acquired */
export enum LockScope {
  DEPLOYMENT_TARGETS = "DEPLOYMENT_TARGETS",
  TOPOLOGY = "TOPOLOGY"
}

export enum OpsgenieType {
  PAID = "PAID",
  UNPAID = "UNPAID"
}

export enum Permission {
  Admin = "Admin",
  Read = "Read",
  Write = "Write"
}

export enum MicrosEnvironmentFilter {
  ADEV = "ADEV",
  ADEV_WEST2 = "ADEV_WEST2",
  DDEV = "DDEV",
  DEV = "DEV",
  DEV_WEST2 = "DEV_WEST2",
  PDEV_APSE2 = "PDEV_APSE2",
  PDEV_WEST2 = "PDEV_WEST2",
  PROD = "PROD",
  PROD_APNE = "PROD_APNE",
  PROD_APNE2 = "PROD_APNE2",
  PROD_APSE = "PROD_APSE",
  PROD_APSE2 = "PROD_APSE2",
  PROD_APSOUTH = "PROD_APSOUTH",
  PROD_CACENTRAL = "PROD_CACENTRAL",
  PROD_EAST = "PROD_EAST",
  PROD_EUCENTRAL = "PROD_EUCENTRAL",
  PROD_EUCENTRAL2 = "PROD_EUCENTRAL2",
  PROD_EUWEST = "PROD_EUWEST",
  PROD_EUWEST2 = "PROD_EUWEST2",
  PROD_SAEAST = "PROD_SAEAST",
  PROD_WEST = "PROD_WEST",
  PROD_WEST2 = "PROD_WEST2",
  STAGING = "STAGING",
  STG_APSE = "STG_APSE",
  STG_APSE2 = "STG_APSE2",
  STG_CACENTRAL = "STG_CACENTRAL",
  STG_EAST = "STG_EAST",
  STG_EUCENTRAL = "STG_EUCENTRAL",
  STG_EUCENTRAL2 = "STG_EUCENTRAL2",
  STG_EUWEST = "STG_EUWEST",
  STG_WEST = "STG_WEST",
  STG_WEST2 = "STG_WEST2"
}

export enum MicrosEnvironmentType {
  DEV = "DEV",
  PROD = "PROD",
  STAGING = "STAGING"
}

export enum MicrosRoutingPolicy {
  CNAME = "CNAME",
  FAILOVER = "FAILOVER",
  GEOLOCATION = "GEOLOCATION",
  LATENCY = "LATENCY",
  SIMPLE = "SIMPLE",
  WEIGHTED = "WEIGHTED"
}

export enum MicrosCloudFormationStatus {
  CREATE_COMPLETE = "CREATE_COMPLETE",
  CREATE_FAILED = "CREATE_FAILED",
  CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
  DELETE_COMPLETE = "DELETE_COMPLETE",
  DELETE_FAILED = "DELETE_FAILED",
  DELETE_IN_PROGRESS = "DELETE_IN_PROGRESS",
  DELETE_SKIPPED = "DELETE_SKIPPED",
  UPDATE_COMPLETE = "UPDATE_COMPLETE",
  UPDATE_FAILED = "UPDATE_FAILED",
  UPDATE_IN_PROGRESS = "UPDATE_IN_PROGRESS"
}

export enum MicrosEc2InstanceHealthStatus {
  HEALTHY = "HEALTHY",
  UNHEALTHY = "UNHEALTHY"
}

export enum MicrosEc2InstanceLifecycleStatus {
  DETACHED = "DETACHED",
  DETACHING = "DETACHING",
  ENTERING_STANDBY = "ENTERING_STANDBY",
  IN_SERVICE = "IN_SERVICE",
  PENDING = "PENDING",
  PENDING_PROCEED = "PENDING_PROCEED",
  PENDING_WAIT = "PENDING_WAIT",
  QUARANTINED = "QUARANTINED",
  STANDBY = "STANDBY",
  TERMINATED = "TERMINATED",
  TERMINATING = "TERMINATING",
  TERMINATING_PROCEED = "TERMINATING_PROCEED",
  TERMINATING_WAIT = "TERMINATING_WAIT"
}

export enum MicrosDeploymentStatus {
  CREATE_COMPLETE = "CREATE_COMPLETE",
  CREATE_FAILED = "CREATE_FAILED",
  CREATE_IN_PROGRESS = "CREATE_IN_PROGRESS",
  DELETE_COMPLETE = "DELETE_COMPLETE",
  DELETE_FAILED = "DELETE_FAILED",
  DELETE_IN_PROGRESS = "DELETE_IN_PROGRESS",
  IMPORT_COMPLETE = "IMPORT_COMPLETE",
  IMPORT_IN_PROGRESS = "IMPORT_IN_PROGRESS",
  IMPORT_ROLLBACK_COMPLETE = "IMPORT_ROLLBACK_COMPLETE",
  IMPORT_ROLLBACK_FAILED = "IMPORT_ROLLBACK_FAILED",
  IMPORT_ROLLBACK_IN_PROGRESS = "IMPORT_ROLLBACK_IN_PROGRESS",
  REVIEW_IN_PROGRESS = "REVIEW_IN_PROGRESS",
  ROLLBACK_COMPLETE = "ROLLBACK_COMPLETE",
  ROLLBACK_FAILED = "ROLLBACK_FAILED",
  ROLLBACK_IN_PROGRESS = "ROLLBACK_IN_PROGRESS",
  UPDATE_COMPLETE = "UPDATE_COMPLETE",
  UPDATE_COMPLETE_CLEANUP_IN_PROGRESS = "UPDATE_COMPLETE_CLEANUP_IN_PROGRESS",
  UPDATE_FAILED = "UPDATE_FAILED",
  UPDATE_IN_PROGRESS = "UPDATE_IN_PROGRESS",
  UPDATE_ROLLBACK_COMPLETE = "UPDATE_ROLLBACK_COMPLETE",
  UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS = "UPDATE_ROLLBACK_COMPLETE_CLEANUP_IN_PROGRESS",
  UPDATE_ROLLBACK_FAILED = "UPDATE_ROLLBACK_FAILED",
  UPDATE_ROLLBACK_IN_PROGRESS = "UPDATE_ROLLBACK_IN_PROGRESS"
}

export enum ContinuousChaosConfigFrequency {
  monthly = "monthly",
  none = "none",
  quarterly = "quarterly",
  weekly = "weekly"
}

export enum ContinuousChaosScheduleType {
  none = "none"
}

export enum ContinuousChaosConfigStatus {
  deleted = "deleted",
  disabled = "disabled",
  enabled = "enabled"
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC"
}

export enum ExecWindow {
  default_fallback = "default_fallback",
  utc_00_00_to_04_00 = "utc_00_00_to_04_00",
  utc_04_00_to_08_00 = "utc_04_00_to_08_00",
  utc_08_00_to_12_00 = "utc_08_00_to_12_00",
  utc_12_00_to_16_00 = "utc_12_00_to_16_00",
  utc_16_00_to_20_00 = "utc_16_00_to_20_00",
  utc_20_00_to_00_00 = "utc_20_00_to_00_00"
}

export enum WargameTemplatePublishStatus {
  disabled = "disabled",
  draft = "draft",
  published = "published"
}

export enum ScheduleType {
  none = "none",
  once = "once",
  recurring = "recurring"
}

export enum ScheduleStatus {
  disabled = "disabled",
  finished = "finished",
  paused = "paused",
  scheduled = "scheduled",
  skipped = "skipped"
}

export enum Filter {
  categories = "categories",
  compliancePolicies = "compliancePolicies",
  services = "services",
  tags = "tags"
}

export enum CronosExecutionsType {
  az_failure = "az_failure",
  terminate_instances = "terminate_instances"
}

export enum ResilienceFaults {
  aws_resource_blackhole = "aws_resource_blackhole",
  az_failure = "az_failure",
  compute_az_failover = "compute_az_failover",
  connection_reset_by_peer = "connection_reset_by_peer",
  custom_response = "custom_response",
  custom_status_code = "custom_status_code",
  dns_blackhole = "dns_blackhole",
  empty_response = "empty_response",
  fixed_delay = "fixed_delay",
  generic_service_blackhole = "generic_service_blackhole",
  malformed_response_chunk = "malformed_response_chunk",
  network_delay = "network_delay",
  network_delay_v2 = "network_delay_v2",
  packet_corruption = "packet_corruption",
  packet_drop = "packet_drop",
  packet_duplication = "packet_duplication",
  random_data_then_close = "random_data_then_close",
  rds_az_failover = "rds_az_failover",
  reboot_rds = "reboot_rds",
  redis_az_failover = "redis_az_failover",
  stress_cpu = "stress_cpu",
  stress_disk = "stress_disk",
  stress_io = "stress_io",
  stress_memory = "stress_memory",
  terminate_instances = "terminate_instances"
}

export enum WargameCategory {
  cronos = "cronos",
  default = "default",
  dynamic = "dynamic",
  userdefined = "userdefined"
}

export enum PremiseType {
  perfhammer_apis = "perfhammer_apis",
  perfhammer_scripts = "perfhammer_scripts",
  perfhammer_sqs = "perfhammer_sqs"
}

export enum PremiseProvider {
  perfhammer = "perfhammer"
}

export enum TaskExecutionMode {
  parallel = "parallel",
  sequential = "sequential"
}

export enum FaultCategory {
  application_layer = "application_layer",
  aws = "aws",
  az_failover = "az_failover",
  blackhole = "blackhole",
  network = "network",
  stress = "stress"
}

export enum ProbeAction {
  alb_metrics = "alb_metrics",
  signalfx_query = "signalfx_query"
}

export enum ProbeCategory {
  cloudwatch = "cloudwatch",
  signalfx = "signalfx"
}

export enum CommonExecutionStatus {
  completed = "completed",
  error = "error",
  failed = "failed",
  in_progress = "in_progress",
  pending = "pending",
  user_abort = "user_abort"
}

export enum OpsgenieAlertPriority {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  P5 = "P5"
}

export enum SQSMessageType {
  cronos_disable = "cronos_disable",
  cronos_schedule = "cronos_schedule",
  wargame_schedule = "wargame_schedule"
}

export enum Subscriptions {
  schedule = "schedule",
  wargame = "wargame"
}

export type Admin2 = Group | User;

export type MicrosEnvironments =
  | MicrosStaticEnvironments
  | MicrosServiceEnvironments;

export type MicrosRoutingPolicyDetail =
  | MicrosFailoverRoutingPolicy
  | MicrosWeightedRoutingPolicy;

export type MicrosServiceType = MicrosEc2 | MicrosKubeCompute;

export type SearchResult = Service | Tag | Category | CompliancePolicy2;
