import * as React from "react";
import TeamManagementQuery from "../models/team-management.query.graphql";
import DocumentIcon from "@atlaskit/icon/glyph/document";
import BitbucketBuildsIcon from "@atlaskit/icon/glyph/bitbucket/builds";
import config from "../../../core/private/config";
import { teamManagement } from "../models/__generated__/teamManagement";
import PeopleGroupIcon from "@atlaskit/icon/glyph/people-group";
import {
  NavigationItem,
  ChildProps,
  compose,
  graphql
} from "@atlassiansox/microscopekit";

export class ServiceCentralNavigationLinksView extends React.Component<
  ChildProps<{ id: string }, teamManagement>
> {
  render() {
    return (
      <>
        <NavigationItem
          icon={<DocumentIcon label="Documentation" />}
          text="Documentation"
          href={`/services/${encodeURIComponent(this.props.id)}/documentation`}
        />
        <NavigationItem
          icon={<BitbucketBuildsIcon label="Dependencies" />}
          text="Dependencies"
          href={`/services/${encodeURIComponent(this.props.id)}/dependencies`}
        />
      </>
    );
  }
}

export const ServiceCentralNavigationLinks = compose(
  graphql(TeamManagementQuery, {
    options: () => ({
      errorPolicy: "all"
    })
  })
)(ServiceCentralNavigationLinksView);
