import * as React from "react";
import { Link, Header } from "..";
import { isNullOrUndefined } from "util";

const AkTextField = require("@atlaskit/field-text").default;
const AkFieldTextArea = require("@atlaskit/field-text-area").default;
const AkSingleSelect = require("@atlaskit/single-select").default;

export interface Props {
  // Viewing Properties
  value: string; // Value displayed when not editing
  isLink?: boolean;
  linkValue?: string;
  isExternal?: boolean;
  label?: string;

  // Editing Properties
  editMode?: boolean;
  editValue?: string; // Value to display while in edit mode
  onChange?: (newValue: string) => void; // Called when the text changes in a regular text field. If using a dropdown, called when an option is selected
  disabled?: boolean;
  required?: boolean;
  invalidMessage?: string;

  // Properties for if there's a dropdown
  // Options to select from while editing (creates a dropdown instead of a regular text field)
  editOptions?: string[];
  onOpenChange?: (isOpen: boolean) => void;
  onFilterChange?: (input: string) => void;
  placeholder?: string; // String to display in the input if nothing has been typed
  noMatchesFound?: string | JSX.Element;
}

// Inline text field
export class EditableText extends React.Component<Props> {
  renderViewMode = (
    value: string,
    linkValue?: string,
    isExternal?: boolean,
    label?: string
  ) => (
    <div>
      {label ? <Header>{label}</Header> : null}
      {linkValue ? (
        <Link
          to={linkValue ? linkValue : ""}
          target={isExternal ? "_blank" : ""}
        >
          {value}
        </Link>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );

  renderEditMode = (
    value?: string,
    editOptions?: string[],
    onChange?: (newValue: string) => void,
    disabled?: boolean,
    onOpenChange?: (isOpen: boolean) => void,
    onFilterChange?: (input: string) => void,
    placeholder?: string,
    required?: boolean,
    label?: string,
    invalidMessage?: string,
    noMatchesFound?: string | JSX.Element
  ) => {
    if (editOptions) {
      let selectedItem;
      let items = editOptions.map(option => {
        let item = { content: option, value: option };
        if (option === value) {
          selectedItem = item;
        }
        return item;
      });

      return (
        <AkSingleSelect
          hasAutocomplete={true}
          shouldFitContainer={true}
          items={[{ items }]}
          filterValue={value !== "" ? value : null}
          defaultSelected={selectedItem}
          selectedItem={selectedItem}
          isDisabled={disabled}
          onFilterChange={(event: any) => {
            if (onFilterChange) {
              onFilterChange(event);
            }
          }}
          onSelected={(event: any) => {
            if (onChange) {
              onChange(event.item.content);
            }
          }}
          onOpenChange={(event: any) => {
            if (onOpenChange) {
              onOpenChange(event.isOpen);
            }
          }}
          placeholder={placeholder}
          isRequired={required}
          label={label}
          isInvalid={!isNullOrUndefined(invalidMessage)}
          invalidMessage={invalidMessage}
          noMatchesFound={noMatchesFound}
        />
      );
    } else {
      return (
        <AkTextField
          value={value}
          onChange={(event: any) => {
            if (onChange) {
              onChange(event.target.value);
            }
          }}
          shouldFitContainer={true}
          isLabelHidden={label === undefined}
          disabled={disabled}
          required={required}
          label={label}
          isInvalid={!isNullOrUndefined(invalidMessage)}
          invalidMessage={invalidMessage}
        />
      );
    }
  };

  render() {
    let {
      value,
      linkValue,
      isExternal,
      editMode,
      editValue,
      editOptions,
      onChange,
      disabled,
      onOpenChange,
      onFilterChange,
      placeholder,
      required,
      label,
      invalidMessage,
      noMatchesFound
    } = this.props;

    return (
      <div>
        {editMode
          ? this.renderEditMode(
              editValue,
              editOptions,
              onChange,
              disabled,
              onOpenChange,
              onFilterChange,
              placeholder,
              required,
              label,
              invalidMessage,
              noMatchesFound
            )
          : this.renderViewMode(value, linkValue, isExternal, label)}
      </div>
    );
  }
}

// Text Area
export class TextArea extends React.Component<
  {
    label?: string;
    text: string;
    editMode?: boolean;
    onChange: (value: string) => void;
    disabled?: boolean;
    minimumRows?: number;
  },
  { text: string }
> {
  render() {
    return (
      <div>
        {this.props.editMode === false ? (
          <p>{this.props.text}</p>
        ) : (
          <AkFieldTextArea
            label={this.props.label}
            shouldFitContainer
            value={this.props.text}
            enableResize={true}
            onChange={(event: any) => {
              this.props.onChange(event.target.value);
            }}
            minimumRows={this.props.minimumRows}
            disabled={this.props.disabled}
          />
        )}
      </div>
    );
  }
}
