import * as React from "react";
import { CompliancePolicy2AnswerInput } from "../../../typings";
import updateCompliancePolicyMutation from "../models/update-compliance-policy.mutation.graphql";
import { CompliancePolicyForm } from "./compliance-policy-form";
import { withMountTracking } from "../../../core/components/mount-tracker";
import { FetchResult } from "apollo-link";
import ApolloClient from "apollo-client/ApolloClient";
import { InMemoryCache } from "apollo-cache-inmemory";
import { complianceQuestions as ComplianceQuestions } from "../models/__generated__/complianceQuestions";
import { compliancePolicy2 } from "../models/__generated__/compliancePolicy2";
import { updateCompliancePolicy } from "../models/__generated__/updateCompliancePolicy";
import {
  withLoadingSpinner,
  withShowFlag,
  FlagProps,
  compose,
  withApollo,
  graphql,
  ChildProps
} from "@atlassiansox/microscopekit";
import complianceQuestions from "../models/compliance-question.query.graphql";

type UpdateCompliancePolicyProps = ChildProps<
  FlagProps & {
    client: ApolloClient<InMemoryCache>;
  } & {
    policy: compliancePolicy2;
    closeModal: () => void;
    services?: string[]; // Which services this policy applies to
    updatePolicy: (newPolicy: compliancePolicy2) => void;
  },
  ComplianceQuestions
>;

const title = "Update Compliance Policy";
export class UpdateCompliancePolicyModalView extends React.Component<
  UpdateCompliancePolicyProps,
  {}
> {
  updatePolicy = async (
    owner2: string,
    name: string,
    input: CompliancePolicy2AnswerInput[]
  ) => {
    const answers = input.map((a, index) => ({
      questionID: this.props.policy.questions[index].id.split("-")[1],
      answer: a.answer
    }));
    const promise = new Promise<compliancePolicy2>(async (resolve, reject) => {
      this.props.client
        .mutate({
          mutation: updateCompliancePolicyMutation,
          variables: {
            input: {
              id: this.props.policy.id,
              owner: owner2,
              name,
              answers
            }
          }
        })
        .then(async (result: FetchResult<updateCompliancePolicy>) => {
          const policy = result.data!.updateCompliancePolicy2;
          track("Compliance.UpdateCompliancePolicyModal PolicyUpdated", {
            category: "info"
          });
          this.props.showFlag({
            title: "Policy Updated",
            message: "Policy has been updated",
            type: "success"
          });
          this.props.updatePolicy(policy);
          resolve(policy);
        })
        .catch(errors => {
          track("Compliance.UpdateCompliancePolicyModal: UpdateFailed", {
            category: "error"
          });
          // Display error messages in the form fields
          reject(errors);
        });
    });
    return promise;
  };

  render() {
    const complianceQuestions = this.props.data!.complianceQuestions;

    return (
      complianceQuestions && (
        <CompliancePolicyForm
          title={title}
          closeModal={this.props.closeModal}
          services={this.props.services}
          policy={this.props.policy}
          callback={this.updatePolicy}
          questions={complianceQuestions}
        />
      )
    );
  }
}

export const UpdateCompliancePolicyModal = compose(
  graphql(complianceQuestions),
  withLoadingSpinner,
  withApollo,
  withMountTracking("UpdateCompliancePolicyModal"),
  withShowFlag
)(UpdateCompliancePolicyModalView);
UpdateCompliancePolicyModal.displayName = "UpdateCompliancePolicyModal";
