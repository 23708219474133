import { config } from "../config";

export interface User {
  username: string;
  office: string;
  givenName: string;
  familyName: string;
  name: string;
  email: string;
}

export const testUser: User = {
  username: "jraynor",
  office: "Sydney",
  givenName: "Jim",
  familyName: "Raynor",
  name: "Jim Raynor",
  email: "jraynor@atlassian.com"
};

export async function getUser(): Promise<User> {
  // Stubbing user for tests.
  if (process.env.REACT_APP_USE_TEST_USER) {
    return testUser;
  }

  const response = await fetch(`${config.apiUrl}/api/v1/auth/validate`, {
    redirect: "manual", // we don't want to follow the redirect in AJAX
    credentials: "include" // needed to keep the cross-domain cookie on requests
  });

  const data = await response.json();
  return {
    username: data.subject,
    givenName: data.firstName,
    familyName: data.lastName,
    email: data.email,
    name: data.name,
    office: data.office
  };
}

export async function login(): Promise<void> {
  window.location.replace(
    `${config.apiUrl}/api/v1/auth/login?redirect=${encodeURI(
      window.location.href
    )}`
  );
}
