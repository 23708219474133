/* tslint:disable:no-console */
import Logger from "@atlassian/js-logger";
import { config } from "../config";

type LogHandler = (messages: any[], context: any) => void;

/*
 * Configure the logger.
 * This will create
 *  - a logger to the backend (if enabled)
 *  - a logger to the console (if enabled)
 * Then send logs to them accordingly
 */
export function configureLogger() {
  let handlers: LogHandler[] = [];

  if (config.logToConsole) {
    handlers.push(Logger.createDefaultHandler());
  }

  if (handlers.length === 0) {
    console.warn("warning: no log flags set, turning off logger...");
    Logger.setLevel(Logger.OFF);
    return Logger;
  }

  Logger.setHandler((messages: any[], context: any) => {
    for (let handler of handlers) {
      handler(messages, context);
    }
  });

  // Setting log level from flag
  let logLevel: any;
  switch (config.logLevel) {
    case "debug":
      logLevel = Logger.DEBUG;
      break;
    case "info":
      logLevel = Logger.INFO;
      break;
    case "warn":
      logLevel = Logger.WARN;
      break;
    case "error":
      logLevel = Logger.ERROR;
      break;
    default:
      throw new Error(`unknown log level '${config.logLevel}'`);
  }
  Logger.setLevel(logLevel);

  return Logger;
}

configureLogger();
export const logger = Logger;
