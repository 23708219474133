export type SearchType = "services" | "tags" | "categories";
export function advancedSearchPath(
  type: SearchType,
  query: string,
  page: number
) {
  return buildAdvancedSearchPath("search", type, query, page);
}

export function goToSearchPath(type: SearchType, query: string, page: number) {
  return buildAdvancedSearchPath("search", type, query, page);
}

function buildAdvancedSearchPath(
  prefix: string,
  type: SearchType,
  query: string,
  page: number
) {
  const pageParam = page > 1 ? `&page=${page}` : "";
  return `/${prefix}/${type}?query=${encodeURIComponent(query)}${pageParam}`;
}

export function goToAdvancedSearchPath(query: string) {
  return `/search?query=${encodeURIComponent(query)}`;
}
